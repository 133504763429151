@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.downBackdown{
    font-size: 25px;
    font-weight: 800;
}

.parentHero {
    font-family: "Poppins", sans-serif;
    height: 80vh;
    position: relative;
    overflow: hidden;
    margin-top: 2%;
    background-size: cover;
    background-position: center bottom;
    background-repeat: 'no-repeat';
    z-index: 2;
    background-color: rgb(255, 255, 255);
    /* transition: background-image 5s ease-in-out; */
    transition: background-image 5s ease-in-out, background-color 5s ease-in-out;
}

.parentHero svg {
    position: absolute;
    top: 80%;
    left: 1%;
    color: #071C62;

}


.simDiv {
    position: absolute;
    top: 80%;
    left: 90%;
    right: 0;
    width: 10%;
    height: 10%;
}

.simDiv img {
    width: 100%;
    height: 100%;
}

.downloadText,
.firstPara,
.secondpara,
.getStartedBtnDiv,
.parentQuicktop,
.topupTitle,
.numberInput,
.getStartedBtn {
    transition: color 3s ease-in-out;
    /* Smooth transition for text color change */
}

/* .parentHero:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 95%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    transform: scaleX(1.5);
    border-bottom: 10px solid whitesmoke;
    z-index: -1;

} */


/* .anotherDiv {
    background: url("../assets/p2.jpg") no-repeat center center;
   
    background-size: 100%;
    height: 70%;
 
    background-position: center;
} */


.downloadText {
    width: 45%;
    height: 68%;
    margin-left: 5%;
    margin-top: 5%;
    /* text-transform: capitalize; */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 15px;
    z-index: 1000;
    z-index: 10;
    /* background-color: antiquewhite; */

}

.firstPara {
    font-size: 4em;
    font-weight: 600;
    line-height: 1.2;
    width: auto;
    transition: color 3s ease-in-out;
}

.secondpara {
    /* background-color: blueviolet; */
    /* margin-top: 2%; */
    text-align: justify;
    font-weight: 600;
}

.getStartedBtnDiv {
    /* margin-top: 10.2%; */
    padding: 20px 0px 20px 0px;
    /* background-color: #0CA8E5; */
    background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    /* background: rgba(255, 255, 255, 0.2); */
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.37); */
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3); */
    width: 100%;
    height: auto;
    /* margin-left: 8%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center;
    color: #fff; */
}

.getStartedBtn {
    border: none;
    padding: 15px 30px 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #000000;
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.topupTitle {
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    background-color: #000000;
    width: 20%;
    font-size: 2em;
    font-weight: 500;
    padding: 5px 3px 5px 3px;
    color: white;
    text-align: center;
}

.adjustedErroDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 0;
}

/* .copera_pro {
    box-shadow: #C1F4FD(38, 57, 77) 0px 20px 30px -10px;
    background: linear-gradient(to right, #5756A2, #C1F4FD);
} */



.parentQuicktop {
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
    height: 100%;
    padding: 10px 3px 10px 3px;
}

.numberInput {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background-color: rebeccapurple; */
}

.numberInput label {
    color: white;
    font-size: 1.2em;
    font-weight: 600;
}

.numberInput input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
}

.bottomDiv {
    background-color: antiquewhite;
    width: 100%;
}

.reserveBtnDiv button {
    padding: 10px 15px 10px 15px;
    /* margin-top: 10%; */
    /* margin-left: 5%; */
    font-size: 1.2em;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgb(38, 57, 77) 0px 10px 30px -10px;
    background: linear-gradient(to right, #5756A2, #000);
    background-size: 200% 200%;
    color: white;
    border: none;
    animation: gradientAnimation 10s ease-in-out infinite;
    font-family: "Poppins", sans-serif;

    white-space: nowrap;

}

.becomeBtnDiv button {
    padding: 10px 15px 10px 15px;
    /* margin-top: 10%; */
    /* margin-left: 5%; */
    font-size: 1.2em;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgb(38, 57, 77) 0px 10px 30px -10px;
    background: linear-gradient(to right, #5756A2, #2BBCF1);
    background-size: 200% 200%;
    color: white;
    border: none;
    animation: gradientAnimation 10s ease-in-out infinite;
    font-family: "Poppins", sans-serif;

    white-space: nowrap;
}

.firstPara,
.secondpara {
    display: none;
}

.control_reserveBTNDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 5%;
    /* background-color: #071C62; */
    width: 100%;

}

.copera_pro button {

    padding: 10px 15px 10px 15px;
    /* margin-top: 10%; */
    /* margin-left: 5%; */
    font-size: 1.2em;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    background: linear-gradient(to right, #0F0F7B, #2BBCF1);
    background-size: 200% 200%;
    color: white;
    border: none;
    animation: gradientAnimation 10s ease-in-out infinite;
    font-family: "Poppins", sans-serif;

    white-space: nowrap;
}

/* .copera_pro {
    border-radius: 10px;
} */



@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@media screen and (max-width: 1500px) {
    .downloadText {
        /* background-color: #000; */
        width: 55%;


    }
}

@media screen and (max-width: 1461px) {
    .parentQuicktop {
        width: 90%;
    }
}

@media screen and (max-width: 1250px) {
    .parentQuicktop {
        width: 100%;
    }

    .downloadText {
        /* background-color: #000; */
        width: 60%;


    }

    .parentHero {
        background-position: center 10%;
        background-size: 90%;
    }
}



@media screen and (max-width: 850px) {


    .downloadText {
        /* background-color: antiquewhite; */
        margin-left: 5%;
        width: 70%;
    }

    .firstPara {
        font-size: 2.2em;
    }

    .reserveBtnDiv button {
        font-size: 1em;
        padding: 5px 10px 5px 10px;
    }

    .becomeBtnDiv button {
        font-size: 1em;
        padding: 5px 10px 5px 10px;
    }

    .copera_pro button {
        font-size: 1em;
        padding: 5px 10px 5px 10px;
    }

}

@media screen and (max-width: 768px) {
    .parentHero {
        /* background-size: 110%; */
        background-position: center;
    }

    .reserveBtnDiv button {
        font-size: 1em;
        padding: 5px 10px 5px 10px;
    }

    .becomeBtnDiv button {
        font-size: 1em;
        padding: 5px 10px 5px 10px;
    }

    .copera_pro button {
        font-size: 1em;
        padding: 5px 10px 5px 10px;
    }


    .firstPara,
    .secondpara {
        display: none;
    }

    .control_reserveBTNDiv {
       justify-content: center;
       /* margin-top: 108%; */
       gap: 3px;
    }

    /* .parentHero:after {
        content: '';
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        height: 95%;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0 0 50% 50%/0 0 100% 100%;
        transform: scaleX(1.5);
        border-bottom: 10px solid whitesmoke;
        z-index: -1;

    } */

    .downloadText {
        width: 100%;
        height: 97%;
        margin-left: 0%;
        justify-content: flex-end;
        text-align: center;
        align-items: center;
        /* background-color: antiquewhite; */
    }

    .firstPara {
        font-size: 2em;
        margin-top: 20%;
        width: 100%;
        padding: 7px 15px;
        font-weight: 700;
    }

    .getStartedBtnDiv {
        width: 100%;
        /* margin-left: 5%; */
        /* margin-top: -35%; */
        /* flex-direction: column; */
    }

    .parentQuicktop {
        /* background-color: antiquewhite; */
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 5px 12px 5px 12px;
        /* flex-wrap: wrap; */
        align-items: center;
        justify-content: center;
    }

    .topupTitle {
        width: 100%;
    }

    .numberInput {
        width: 100%;
    }

    .secondpara {
        padding: 7px 25px;
        font-size: 0.8em;
        text-align: center;
        /* background-color: #5756A2; */
        width: 100%;
    }

    .reserveBtnDiv {
        /* width: 100%;
        margin-top: 10%; */
    }

    .reserveBtnDiv button {
        /* margin-left: 0%;
        padding: 10px 10px 10px 10px;
        font-size: 1em; */
    }

    .parentHero:after {
        width: 100%;
        height: 100%;
    }

    .parentHero {
        font-family: "Poppins", sans-serif;
        height: 95vh;
    }

    .rechargeNowBtnDiv {
        /* background-color: #5756A2; */
        margin-top: 4%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .control_reserveBTNDiv{
        margin-top: auto ;
        
        }
   
}