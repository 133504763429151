.content-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

}

.header-title{
    color: #526d82;
}

.header-activity{
  display: flex;
  align-items: center;
  gap: 20px;  
}

.search-box{
    background: #dde6ed;
    padding: 8px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.search-box input{
    border: none;
    outline: none;
    background: transparent;
    padding: 5px;
    outline: none;
}

.search-box .icon{
    color: #969393;
    font-size: 22px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.notify{
    background-color: #dde6ed;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
}

.notify .icon{
    font-size: 20px;
    transition: 0.5s ease-in-out;
}

.icon:hover{
    transform: scale(1.3);
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .content-header{
        /* background-color: #b1bec9; */
    }
}

