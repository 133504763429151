@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentPartnerFunction {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partnerFunction {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 80%;
}

.partnerFunctionText {
    margin-top: 7%;
    width: 55%;
    /* background-color: antiquewhite; */
}

.partnerFunctionImg {
    width: 55%;
    /* background-color: antiquewhite; */
}

.partnerFunctionImg img {
    width: 100%;
}

.partnerFunctionText h3 {
    /* background-color: aliceblue; */
    padding: 2%;
    font-size: 3em;
    font-weight: 800;
}

.partnerFunctionInfo {
    padding: 3%;
    font-size: 1em;
    color: #7D7D7D;
    font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 800px) {
    .partnerFunction {
        width: 100%;
        flex-direction: column;
        padding: 3% 2% 3% 2%;
    }

    .partnerFunctionText {
        /* padding: 3%; */
        width: 100%;
        /* background-color: aqua; */
    }

    .partnerFunctionInfo {
        width: 100%;
        font-size: 0.9em;
        /* background-color: #171A1C; */

    }

    .partnerFunctionText h3 {
        padding: 1%;
        font-size: 2.2em;
    }

    .partnerFunctionImg {
        width: 100%;
    }

    .parentPartnerFunction {
        margin-bottom: 10%;
    }
}