.parentReport {
    flex: 1;
    /* background-color: aqua; */
}

.noteheader-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.5em;
    text-transform: capitalize;
    margin: 0;
}

.trackingList {
    height: 85%;
    margin-top: 2rem;
    background-color: #dde6ed;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 600px;
    overflow-y: scroll;
}

.user-name {
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.notedate {
    position: absolute;
    top: -7px;
    font-size: 0.9em;
    background-color: #dde6ed;
    padding: 2px 10px 2px 10px;
    border-radius: 5px;

}

.notetitlediv {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid gray;
}

.noteTitle {
    font-weight: bold;
    font-size: 1.1em;
    color: #39447a;
    text-transform: capitalize;
}



.defaultstatus {
    color: #fff;
    /* font-size: 20px; */
    background: #39447a;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000;
    padding: 10px;
}

.notestatus {
    color: #fff;
    /* font-size: 20px; */
    background: green;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000;
    padding: 10px;
}

.notestatusfail {
    color: #fff;
    /* font-size: 20px; */
    background: red;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000;
    padding: 10px;
}

.notemessagediv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
}

.notemessage {
    font-size: 0.97em;
    /* background-color: #dde6ed; */
    padding: 5px 30px 5px 30px;
    width: 90%;
}

.icon1 {
    color: red;
    cursor: pointer;
}

.notificationheaderpart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.noteTogglebtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    /* background-color: #dde6ed; */
}

.noteTogglebtn button{
    padding: 10px 15px 10px 15px;
    font-weight: 700;
    border-radius: 7px;
    border: 1px solid #0078D4;
    background-color: transparent;
    color: #000;
}

.noteTogglebtn button.activenotificate {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
  }

.noteTogglebtn button:hover{
    background-color: #0F0F7B;
    color: #fff;
    border: 1px solid #0F0F7B;
}

@media screen and (max-width: 800px) {
    .noteTogglebtn{
        width: 100%;
    }

    .noteTogglebtn button{
        padding: 10px 5px 10px 5px;
        font-weight: 400;
        border-radius: 7px;
        border: 1px solid #0078D4;
        background-color: transparent;
        color: #000;
    }
}