@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentUserDetails {
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    background: url("../../images//productBack.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 80vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.parentUserDetails::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 10%;
    background: linear-gradient(to right, #FFFFFF, #FFFFFF);
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;

}

.parentUserDetails:after {
    content: '';
    position: absolute;
    top: 95%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5%;
    background: url("../../images/getSecuredBck.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;

}

.parentUserContent {
    width: 90%;
    height: 65%;
    /* background-color: #858585; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.userName {
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    background: url("../../images//productBack.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    height: 80%;
    margin-top: 1.8%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 80px;
    color: white;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.testing {
    background: linear-gradient(to right, #FFFFFF, #FFFFFF);
    width: 80%;
    height: 25%;
    margin-top: -15%;
    z-index: 100;
    padding: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}

.testing::before {
    content: '';
    position: absolute;
    top: 30%;
    bottom: 0px;
    left: -2%;
    width: 30px;
    height: 30px;
    border-bottom-left-radius: 70px;
    background-color: rgb(255, 255, 255);
}

.testing::after {
    content: '';
    position: absolute;
    top: 30%;
    bottom: 0px;
    left: 100%;
    right: 0;
    width: 30px;
    height: 30px;
    border-bottom-right-radius: 70px;
    background-color: rgb(255, 255, 255);
}

.userProductDiv {
    /* background-color: rebeccapurple; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
    /* margin-bottom: 2%; */
    color: white;
}



.productServfilterDiv {
    /* background-color: #75CFE4; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 35%;
}

.productServfilterDiv label {
    /* background-color: #fff; */
    font-size: 1.2em;
    font-weight: 600;
    width: 100%;
    text-transform: capitalize;
}

.productServfilterDiv select {
    width: 75%;
    height: 35px;
    border-radius: 5px;
}

.userProductTitle {
    width: 60%;
    font-size: 2em;
    font-weight: 500;
    /* background-color: red; */
}

/* .userProductHr {
    border: 3px solid #ffffff;
    width: 10%;
    margin-left: 0%;
} */

.userDetailsContent {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    /* height: auto; */
    width: 80%;
    height: 100%;
    padding: 10px 0px 10px 0px;
    /* background-color: antiquewhite; */
    overflow-y: scroll;
    /* scrollbar-width: none;
    -ms-overflow-style: none; */
}

.userDetailsContent::-webkit-scrollbar {
    width: 12px;
    /* Width of the scrollbar */
}

.userDetailsContent::-webkit-scrollbar-track {
    background: #040B37;
    /* Background color of the scrollbar track */
}

.userDetailsContent::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;

    border-radius: 20px;
    /* Rounded corners for the scrollbar thumb */
    border: 3px solid #040B37;

}

.userDetailsContent::-webkit-scrollbar-thumb:hover {
    background-color: #858585;

}

/* .userDetailsContent::-webkit-scrollbar {
    display: none;
} */

.numberDiv {
    /* background-color: rebeccapurple; */
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.nameDiv {
    font-weight: 700;
}

.ninDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #FFFFFF;
    color: black;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    font-weight: 700;
}

.ninDiv .verifiedIcon {
    color: #75CFE4;
}

.userDetailsContent .mainContent {
    width: 180px;
    height: 220px;
    background-color: #FFFFFF;
    color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* padding-bottom: 20px; */
    /* gap: 15px; */
    color: #046388;
}

.imageDivforProd {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* background-color: bisque; */
}

.imageDivforProd img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tarrifAmount {
    font-size: 1.5em;
    font-weight: 600;
}

.mainContent .icon {
    font-size: 3em;
    color: #5856A3;
}

@media screen and (max-width: 1620px) {
    .testing {

        margin-top: -25%;

    }

    .testing::before {
        top: 60%;
    }

    .testing::after {
        top: 60%;
    }

    .numberDiv {
        width: 50%;
    }
}

@media screen and (max-width: 1500px) {
    .testing::before {
        top: 50%;
    }

    .testing::after {
        top: 50%;
    }
}

@media screen and (max-width: 1360px) {
    .testing::before {
        top: 40%;
    }

    .testing::after {
        top: 40%;
    }
}

@media screen and (max-width: 1200px) {
    .testing::before {
        top: 30%;
    }

    .testing::after {
        top: 30%;
    }
}



@media screen and (max-width: 992px) {
    .testing {

        margin-top: -15%;
    }

    .userName {

        margin-top: 4.5%;
    }
}

@media screen and (max-width: 768px) {
    .userName {
        gap: 30px;
        height: 50%;
        padding: 5px 15px;
        margin-top: 15%;
    }

    .numberDiv {
        /* background-color: #75CFE4; */
    }

    .ninDiv {
        font-size: 0.8em;
        width: 60%;
    }

    .parentUserContent {
        height: 65%;
        margin-bottom: 3%;
    }

    .parentUserDetails::before {
        height: 7%;
    }

    .userProductDiv {
        /* background-color: #5856A3; */
        margin-top: 0%;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .userProductHr {
        border: 1px solid #ffffff;
        width: 100%;
        margin-left: 0%;
    }

    .productServfilterDiv {
        width: 100%;
        flex-direction: row;
        /* background-color: #5b6ddd; */
        /* margin-left: 30%; */
    }

    .productServfilterDiv label {
        font-size: 1em;
        font-weight: 500;
    }

    .productServfilterDiv select {
        width: 100%;
    }

    .userProductTitle {
        /* margin-top: ; */
        /* background-color: #75CFE4; */
        padding-bottom: 0;

    }
}

@media screen and (max-width: 750px) {
    .testing {
        margin-top: 0%;
    }

    .userDetailsContent {
        /* background-color: rebeccapurple; */
        height: 100%;
        overflow-y: scroll;
        margin: 0% 0 30% 0;
    }



    .userName {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-top: 5%;
        padding: 5px 10px;
        width: 100%;
        font-size: 0.7em;
        height: 80%;
    }

    .numberDiv {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .ninDiv {
        font-size: 0.8em;
        width: 50%;
    }

    .testing::after {
        display: none;
    }

    .testing::before {
        display: none;
    }

    .userProductTitle {
        width: 100%;
        font-size: 1.5em;
        padding: 0;
        /* background-color: #3e446b; */
    }

}