.parentSubscriberprofile {
    /* flex: 1; */
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 2.3rem;
    height: 80vh;
    width: 18%;
    padding: 1.2rem;
}

.profileheaderPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profileEditDiv {
    background-color: #dde6ed;
    padding: 12px;
    border-radius: 10px;
    color: #969393;
    display: flex;
    align-items: center;
    justify-content: center;
}


.profileEditDiv .profileEditIcon {
    font-size: 20px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    text-transform: capitalize;
}

.userProfileInfo {
    height: 85%;
    margin-top: 2rem;
    background-color: #dde6ed;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.userDetails {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userDetails img {
    background: #fff;
    border-radius: 50%;
    width: 150px;
    margin-bottom: 10px;
}

.userDetails .subscriberName {
    color: #526d82;
    margin: 0;
    /* padding: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.linkedNumber {
    background: #fff;
    flex: 1;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    max-height: 550px;
}

.numbersDiv {
    background: #dde6ed;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.number {
    display: flex;
    gap: 20px;
    align-items: center;
}

.numberCover {
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
}

.mainNumberDiv {
    /* background-color: rebeccapurple; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.actualNum {
    font-size: 0.8em;
    font-weight: 900;
}

.mainNumberDiv .activeNum {
    font-size: 12px;
}

.numbersDiv .action {
    font-weight: 900;
    font-size: 1.2rem;
}

.subscriberEmail {
    color: #969393;
}

#mobileDivdisplay {
    display: none;
}

@media screen and (max-width: 800px) {
    .parentSubscriberprofile {
        display: none;
    }

   
}