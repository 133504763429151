/* *,
*::after,
*::before{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
} */

/* font-family: "Poppins", sans-serif; */


.nav_Central_call{
    color: white;
    text-align: center;
  }
  
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines{
    display: none;
  }
  
  .container-Nav{
    max-width: 1300px;
    width: 90%;
    margin: auto;
  }
  .Compo{
    color: red;
  }
  
  .navbar{
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    position: fixed !important ;
    width: 100% !important;
    background: #fff;
    /* color: #000; */
    /* opacity: 0.85; */
    margin: 0;
    padding: 0;
    z-index: 100;
    overflow: hidden !important;
  }
  .search_HomeS{
    margin-top: 0%;
  }
  
  .navbar-container{
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
  }
  
  .menu-items{
    order: 2;
    display: flex;
    /* z-index: 100; */
    /* background-color: #000; */
  
  
  }
  .nav_Central_call_sup{
    color: #D6DB4B;
  }
  .logo{
    order: 1;
    font-size: 1.2rem;
    /* margin-top: -2%; */
  }
  
  .menu-items li{
    list-style: none;
    margin-left: 1.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    color: #239ACD;
  
  }
  .menu-items li:hover{
  
    color: #D6DB4B;
  }
  .menu-items li:focus{
  
  }
  .employer_click{
    background-color: #239ACD;
    padding: 5px;
    color: white !important;
    margin-top: -3px;
    border-radius: 5px;
  }
  .employer_click a:hover{
    color: #D7DC4B !important;
  }
  
  .navbar a{
    /* color: #444; */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }
  
  .navbar a:hover{
    color: #117964;
  }
  .drop_forgetPass_div{
    background-color: #1D3C34;
    justify-content: center;
    text-align: center;
    margin-top: 2% !important;
  }
  .drop_forgetPassReady{
    color: white;
  }
  #forgetPassReady{
    cursor: pointer;
  }
  .cum-error{
    color: red;
  }
  .cumShow_no{
  
    text-align: center;
    color: red;
    font-weight: bold;
  }
  .profileside_Alls{
  
    /* background-color: #007b5e; */
    /* text-align: start; */
  }
  .loaderDivS{
    justify-content: center !important;
    text-align: center;
  }
  
  .loaderst {
    width: 48px;
    height: 48px;
    border: 5px solid #117964;
    border-bottom-color:#D6DB4B;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader {
    width: 25px;
    height: 25px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  
  
  @media (max-width: 768px){
    .navbar{
      opacity: 0.95;
    }
  
    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
      display: block;
    }
  
    .navbar-container{
      display: block;
      position: relative;
      height: 64px;
    }
  
    .navbar-container input[type="checkbox"]{
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }
  
    .navbar-container .hamburger-lines{
      display: block;
      height: 28px;
      width: 35px;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .navbar-container .hamburger-lines .line{
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #333;
    }
  
    .navbar-container .hamburger-lines .line1{
      transform-origin: 0% 0%;
      transition: transform 0.3s ease-in-out;
    }
  
    .navbar-container .hamburger-lines .line2{
      transition: transform 0.2s ease-in-out;
    }
  
    .navbar-container .hamburger-lines .line3{
      transform-origin: 0% 100%;
      transition: transform 0.3s ease-in-out;
    }
  
    .navbar .menu-items{
      padding-top: 100px;
      /* background: #fff; */
      height: 100vh;
      max-width: 300px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 40px;
      transition: transform 0.5s ease-in-out;
      /* box-shadow:  5px 0px 10px 0px #aaa; */
      overflow: scroll;
    }
  
    .navbar .menu-items li{
      margin-bottom: 1.8rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: white !important;
    }
  
    .logo{
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 2.5rem;
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .menu-items{
      transform: translateX(0);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
      transform: rotate(45deg);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
      transform: scaleY(0);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
      transform: rotate(-45deg);
    }
  
  }
  
  @media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
      display: none;
    }
  }
  
  
  
  
  /* .sectionttt {
    width: 100%;
    display: inline-block;
    background: #333;
    height: 50vh;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    text-decoration: underline;
  } */
  
  /* @import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
  section {
      padding: 60px 0;
  } */
  
  section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
  }
  #footer {
    background-color :#1D3C34 ;
    margin-top: 5%;
  }
  #footer h5{
    padding-left: 10px;
    border-left: 3px solid #eeeeee;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color:#ffffff;
  }
  #footer a {
    color: #ffffff;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  #footer ul.social li{
    padding: 3px 0;
  }
  #footer ul.social li a i {
    margin-right: 5px;
    font-size:25px;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
  }
  #footer ul.social li:hover a i {
    font-size:30px;
    margin-top:-10px;
  }
  #footer ul.social li a,
  #footer ul.quick-links li a{
    color:#ffffff;
  }
  #footer ul.social li a:hover{
    color:#eeeeee;
  }
  #footer ul.quick-links li{
    padding: 3px 0;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
  }
  #footer ul.quick-links li:hover{
    padding: 3px 0;
    margin-left:5px;
    font-weight:700;
  }
  #footer ul.quick-links li a i{
    margin-right: 5px;
  }
  #footer ul.quick-links li:hover a i {
    font-weight: 700;
  }
  
  @media (max-width:767px){
    #footer h5 {
      padding-left: 0;
      border-left: transparent;
      padding-bottom: 0px;
      margin-bottom: 10px;
    }
  }
  .formserach_search{
    /* background-color: #dcdddf; */
    justify-content: center;
    text-align: center;
    display: flex !important;
    background-image: url("https://png.pngtree.com/thumb_back/fh260/back_pic/03/76/37/2257bea537b28e6.jpg");
    padding-top: 3%;
  }
  .inner_searchDiv{
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #1D3C34;
    padding: 10px;
    gap: 10px;
  
  }
  /* .formserach_searchForm{
      padding: 30px;
  } */
  .search-job-input{
  
  }
  .iconStar_star{
    color:#D7DC4B ;
  }
  .no-border{
    background-color: #1D3C34 !important;
  }
  
  .cardPads{
    padding: 5px;
  }
  .card-titleed{
    color: white;
  }
  
  .searchBtN:hover{
    background-color: #d0ee27;
  }
  .searchBtN{
    width: 120px;
    padding:8px ;
    background-color: #D6DB4B;
    color: white;
    border: none;
  
  }
  
  #searchBtNId{
    width: 150px;
    height: 50px;
  }
  
  .categories_fieldI{
    width: 200px;
    background-color: white;
    padding: 7px;
    border: none;
  }
  
  #categories_fi{
    width: 400px;
  }
  .search-job-input{
    width: 300px;
    padding: 7px;
    border: none;
  }
  #search-job-input_id{
  
    width: 150px;
    padding: 7px;
    border: none;
  }
  .search-job-inputClass{
    width: 200px !important;
    height: 50px;
  }
  #search-jobS{
    width: 500px;
    height: 50px;
  }
  
  
  .widgetKK{
    padding-left: 10px;
    font-weight: bold;
    margin-top: 5px !important;
  }
  .shortDiscription_text{
    margin-top: 10px;
  }
  .iconJobs{
    padding-top: -5%;
  }
  .icondown_caT{
    /*float: inline-end;*/
    margin-top: 5px;
  }
  .containerJob_CardS{
    /* background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 2px 20px -14px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    padding: 10px; */
  }
  .containerSideProf_S{
    background-color: #1D3C34 !important;
    border-radius: 0.25rem;
    box-shadow: 0 2px 20px -14px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    padding: 10px;
  }
  .gutters-sm{
    /* background-color:#F8F8F8;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 5px;  */
  }
  .form-group label{
    font-weight: bold;
  }
  
  .meeting-action{
    background-color:#F8F8F8;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
  .dateJob_dis{
    font-weight: 500;
    margin-top: 5px;
  }
  .apply_BtNs{
    background-color: #1D3C34;
    border: none;
    padding: 5px;
    width: 100px;
    color: white;
    border-radius: 5px;
  }
  .apply_BtNs:hover{
    background-color: #306457;
  }
  .contain_slides{
    padding: 20px !important;
  }
  .cover_jobDetailsDiv{
    justify-content: center !important;
    /* text-align: center; */
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 1%;
  }
  .gen_jobDetails{
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    padding-top: 5%;
    flex-direction: column;
    overflow: hidden;
  }
  .gen_jobDetails h2{
    width:80% ;
    padding-left: 30px;
  
  }
  .verifiedPaed{
    position: absolute;
    margin-left: -20px;
    margin-top: 3px;
  }
  
  
  
  .details_textAdJust{
  
  
  }
  .details_textAdJustAll{
    padding-left: 30px;
    width: 70%;
    padding-bottom: 20px;
  }
  .majar_headers{
    margin-top: 2%;
  }
  .description_Major{
    margin-top: 2%;
  }
  .removeMajorBold{
    font-weight: 300;
  }
  .description_Major2{
    margin-top: 2%;
  }
  .profile_page_Cover{
    padding-top: 5%;
  }
  .btn_Registed{
    background-color: white;
    border: none;
    border-radius: 20px;
    padding: 5px;
    width: 150px;
    font-weight: bold;
    margin-top: 2%;
  }
  .btn_Registed:hover{
    background-color: whitesmoke;
  }
  .input_JobCodeS{
    width: 210px;
    height: 40px;
    border: #99999952 solid;
    border-radius: 10px;
  }
  .input_JobCodeS_btn{
    background-color: #1D3C34;
    color: white;
    border: none;
    width: 100px;
    height: 40px;
    border-radius: 10px;
  
  }
  .input_JobCodeSH{
    font-weight: bold;
  
  }
  .jobCode_Txt{
    font-weight: bold;
    margin-top: 5px;
  
  }
  #Card_ids{
    margin-top: 3%;
  
  }
  #search-jobS{
  
  }
  .table-striped{
    border: solid;
  }
  .stripTT{
    font-weight: bold;
  }
  .date-workedSEt{
    font-weight: bolder;
    color: #D6DB4B !important;
    background-color: #1D3C34 !important;
  }
  .generalSocialTb{
    font-size: 25px !important;
  }
  .staysallsocial{
    margin-top: -25px;
  }
  .emailShows{
    color: #DEE99E !important;
  }
  /* CSS */
  .button-43 {
    background-color: #1D3C34;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 16px;
    justify-content: center;
    padding: 1rem 1.75rem;
    text-decoration: none;
  
    border: 0;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  .menu-item-object-custom a:hover{
    color: #cece30 !important;
  }
  
  .button-43:hover {
    background-color: #D6DB4B;
    color: #000;
    font-weight: bold;
  }
  
  @media (min-width: 768px) {
    .button-43 {
      padding: 1rem 2rem;
    }
  }
  
  .description_Major2_similar{
    margin-top: 2%;
    padding: 5px;
    padding-left: 2%;
    padding-bottom: 5%;
  }
  .all_relatedJobSCoverhead{
    background-color: #1D3C34;
    padding: 10px;
    color: white;
  }
  
  .all_relatedJobSCover{
    border: solid 1px;
    width: 60%;
    padding: 5px;
  }
  .all_relatedJobSCoverUl li{
    color: black !important;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
  }
  .all_relatedJobSCoverUl li:hover{
    color: #D6DB4B !important;
  }
  .line_dive{
  
    height: 50px;
    justify-content: center;
    padding-left: 45%;
    padding-right: 45%;
  }
  .line_dive_jCard{
    border: #1D3C34 solid 2px;
  
  }
  #dowicon{
    margin-top: 5%;
    font-size: 12px;
    font-weight: bold;
  }
  #dowicon2{
    margin-top: 5%;
    font-size: 12px;
    font-weight: bold;
  }
  
  /* .employer_select{
      position: absolute;
  } */
  .ul_employer_select{
    background-color: #1D3C34;
    color: white;
    position: absolute;
    margin-top: 0px;
    list-style-type: none;
    display: none;
    padding: 15px;
    margin-left: -4%;
    border-radius: 10px;
  
  
  }
  .ul_employer_select li{
    padding: 5px;
  }
  
  .ul_employer_select2{
    background-color: #1D3C34;
    color: white;
    position: absolute;
    margin-top: 0px;
    list-style-type: none;
    display: none;
    padding: 15px;
    margin-left: 4% !important;
    border-radius: 10px;
  
  
  
  }
  
  .ul_employer_select li{
    margin-top: 2%;
  }
  /* #employer_select:hover{
      background-color: #117964;
  } */
  
  #employer_select:hover .ul_employer_select{
    display: block !important;
  
  
  }
  #employer_select2:hover .ul_employer_select{
    display: block !important;
  
  
  }
  .loginText_head2:hover .ul_employer_select{
    display: block !important;
  }
  /* .loginText_head2{
      margin-left: 2% !important;
  } */
  
  .ul_employer_select li:hover{
    color: #D6DB4B;
  }
  .text_second_textDiv_img{
    position: absolute;
    margin-left: -18%;
  }
  .text_second_textDiv_imgimg{
    width: 400px;
    margin-top: -20%;
    transition: ease-in ;
    transition-delay: 3s;
  
  
  }
  
  .box
  {
    /* background:#fff;
    width:300px;
    height:400px;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    flex-direction:column;
    box-shadow:0 30px 60px rgba(0,0,0,.4);
    transition: transform .2s; */
  }
  /* .box:hover
  {
   transform:scale(1.2);
  } */
  .box .percent
  {
    width:150px;
    height:150px;
    position:relative;
    margin-left: 20px;
  }
  .box .percent svg
  {
    width:100px;
    height:100px;
    position:relative;
  }
  .box .percent svg circle
  {
    width:50px;
    height:50px;
    fill:none;
    stroke-width:10;
    stroke:#000;
    transform:translate(5px,5px);
    stroke-dasharray:340;
    stroke-dashoffset:340;
    stroke-linecap:round;
  }
  .box .percent svg circle:nth-child(1)
  {
    stroke-dashoffset:0;
    stroke:#f3f3f3;
  }
  .box .percent svg circle:nth-child(2)
  {
    stroke-dashoffset:calc(340 - (360 * 40) / 100);
    stroke:#D6DB4B;
  
  }
  .box .percent .num
  {
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:absolute;
    color:white;
    margin-top: -15%;
  }
  .box .percent .num h2
  {
    font-size:25px;
  }
  .box .percent .num h2 span
  {
    font-size:24px;
  }
  .box .text{
    padding :0px 0 0;
    color:#999;
    font-weight:700;
    letter-spacing:1px;
    margin-top: -50px;
  }
  
  
  .inputdown_profile{
    width: 100%;
    height: 40px;
    border-radius: 15px;
    padding: 2px;
    position: relative;
    border: #DCE5DC solid;
  }
  .icon_Search_Profi{
    position: absolute;
    margin-left: -2.5%;
    margin-top: 5px;
  
  
  }
  .list-group-flush li{
    cursor: pointer;
  }
  .card_car{
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 2px 30px -14px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  .card_car_ProfilePad{
    padding: 10px;
    background-color: #1D3C34 !important;
  }
  .user_nameS{
    color: white !important;
  }
  #locationName{
    color: white !important;
  }
  
  .bold_Phone_profileside{
    font-weight: bold;
  }
  .icon_sideSHIFT{
    margin-left: 2%;
    padding: 5px;
    width: 100px;
    border: none;
    background: #1D3C34;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }
  
  
  
  
  
  .icon_sideSHIFT:hover{
    background: #1f4239;
  }
  
  
  .icon_sideSHIFTSave{
    margin-left: 2%;
    padding: 5px;
    width: 20%;
    border: none;
    background: #1D3C34;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  
  }
  .icon_sideSHIFTSave:hover{
    background: #1f4239;
  }
  #employerr-ragistration{
    top: 0;
    margin: 0;
  }
  .logoBounce{
  
    background-color: white;
  }
  .loginText_head{
    color: #d0ee27 !important;
    font-weight: bold;
    margin-left: 0%;
  
  }
  
  .btnRegister{
    background: #1D3C34 !important;
  }
  .btnRegister:hover{
    background-color: #235a4c !important;
  }
  .delete_accountBtn{
    background-color: red;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
  }
  .com_names_Display{
    font-size: 20px;
    font-weight: 500;
  }
  
  
  /* another nav */
  
  .nav_Central {
    /* height: 50px; */
    width: 100%;
    background-color:#1D3C34;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 100;
  }
  
  .nav_Central > .nav-header {
    display: inline;
  }
  
  .nav_Central > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
    margin-left: 5%;
  }
  
  .nav_Central > .nav-btn {
    display: none;
  }
  
  .nav_Central > .nav-links_nav {
    display: inline;
    margin-left: 40%;
    font-size: 18px;
    color: white;
    gap: 5%;
  
  }
  .profile_Ind{
    padding: 20px;
  }
  
  .nav_Central > .nav-links_nav > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
    margin-left: 2%;
    cursor: pointer;
  
  }
  .nav-links_nav_a:hover{
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav-links_nav_aDiv{
    padding: 10px;
  
  }
  .nav-links_nav_aDiv:hover{
    background-color: rgba(0, 0, 0, 0.3);
  
  }
  
  /* .nav_Central> .nav-links_nav> a:hover {
     background-color: rgba(0, 0, 0, 0.3);
  } */
  
  .nav_Central> #nav-check {
    display: none;
  }
  .comApply_name{
    font-weight: bold;
    color: #000000a6;
  }
  .name_SideBOld{
    font-weight: bold;
  }
  .name_SideBOld_text{
    font-weight: 300;
  }
  .move_rendindiv{
    margin-top: 4% !important;
  }
  .forSearch{
    margin-top: 4%;
  }
  .hold_image_Img{
    width: 100px;
    border-radius: 50px;
    background-color: white;
    padding: 5px;
  
  }
  .hold_image{
    justify-content: center;
    text-align: center;
    background-color: #1D3C34;
    padding: 10px;
  
  }
  /* .circle_imageProfile{
      width: 100px;
      height: 100px;
      border-radius: 50px;
  } */
  
  .hold_image_Img_name{
    color: white;
  
  }
  .hold_image_Img_plus{
  
    width: 50px;
    background: #007b5e;
    position: absolute;
    width: 30px;
    margin-left: -3%;
    margin-top: 8%;
    z-index: 100 !important;
    border-radius: 50px;
  }
  .hold_imageIcon_plus{
    color: white;
  }
  .bold_Phone_profileside{
  
  }
  .profileside_Text{
  
    display: flex;
    /* justify-content: space-between; */
    padding: 5px;
    /* gap: 15%;  */
  }
  .btn_EditProf{
    background-color: #1D3C34;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 20%;
  }
  #alerts_ID{
    position: absolute !important;
    width: 100%;
    z-index: 60;
    margin-top: 0%;
  
  }
  #showAlert_sucess{
    position: fixed;
    width: 100%;
    margin-left: 16%;
    margin-right: 20% !important;
    z-index: 50;
    margin-top: 1%;
  }
  .form_edit_PROFILE{
    padding: 30px;
  }
  
  .offcanvas{
    width: 40% !important;
  }
  .offcanvasCard li{
    list-style-type: none;
  }
  
  .offcanvasCard{
    padding: 5px;
    justify-content:center;
    align-items:center;
    position:relative;
    flex-direction:column;
    box-shadow:0 0px 2px rgba(0,0,0,.4);
  
  }
  .offcanvasCardTop{
    display: flex;
    justify-content: space-between;
    padding: 3px;
  }
  .pushSec_div{
    display: flex;
    justify-content: space-between;
  }
  .btnEdit_edit{
    background-color: #117964;
    color: white;
    border: none;
    width: 50px;
    border-radius: 5px;
  
  }
  .btnEdit_edit_div{
    padding: 5px;
  
  }
  .edit_viewMore{
    width: 100px;
    padding: 5px;
  }
  .padEdit_form{
    padding: 10px;
  }
  /* .bold_Phone{
  margin-left: 30%;
  } */
  /* .bold_Phone1{
   margin-left: 37%;
   }
   .bold_Phone2{
       margin-left: 40%;
   }
   .bold_Phone3{
       margin-left: 40%;
   }
   .bold_Phone4{
       margin-left: 38%;
   }
   .bold_Phone5{
       margin-left: 28%;
   }
   .bold_Phone6{
       margin-left: 31%;
   } */
  .careerInfo-Head{
    text-align: center;
    margin-top: 2%;
  }
  .careerInfo-Texts{
    padding: 5px;
  }
  .navVerifiedAccountlog{
    margin-left: 2%;
  }
  
  .erroDisPASS{
    /* background-color: #007b5e; */
    text-align: center;
    color: red;
    font-weight: bold;
    font-size: 17px;
  
  }
  .icodSET{
    color: #333 !important;
  }
  .spanCERTset{
    font-weight: bold;
    color: #b9b7b7;
  }
  .forwardCover{
    background-color: #1D3C34;
    color: white;
  }
  .profileIConsCover{
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
  .profile1DIV{
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
  .profile1DIVicon{
    color: #D6DB4B;
  }
  .careerNOTEt{
    color: #D6DB4B;
    padding: 10px;
    width: 80%;
  }
  .messageTextDISDiv{
    background-color:#FFFFFF;
    list-style-type: none;
    box-sizing: border-box;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
    border-color: white;
    padding: 20px;
  
  
  }
  .messageTextDISDiv:hover{
    background-color: #f7f5f5;
  }
  .messageTextDIS{
    font-weight: bold;
  }
  .cVNOCv{
    text-align: center;
    color: #D6DB4B;
  }
  .workedHEAD_TExtDiv{
    display: flex;
    justify-content: space-between;
  }
  .containerBar{
    background-color:white  !important;
  }
  .barCompleted{
    background-color: #007b5e ;
  }
  .wrapper{
    background-color: white;
  }
  .fileside_Tex2{
    padding-left: 40%;
  }
  
  .employeeeS{
    padding: 10px;
    border-radius: 20px;
    background-color: #d0ee27;
    font-weight: bold;
  }
  
  .controlTextAErea2{
    height: 100px !important;
  }
  
  
  
  
  
  
  /*report button CSS */
  .button-35 {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    color: #121212;
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-family: Inter,sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    padding: 1.6rem 1.2rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s,-webkit-box-shadow .2s;
    white-space: nowrap;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 200px;
  
  }
  
  .button-35:hover {
    box-shadow:  #1D3C34 0 0 0 3px, transparent 0 0 0 0;
  }
  .bTN-35div{
    display: flex;
    gap: 20px;
  
  }
  .DIVAs{
    padding: 2px;
  }
  .ems-ems-offers{
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  
  }
  .ems-emsNumber{
    font-weight: bolder;
  }
  .emsJOBname{
    font-weight: 900;
  }
  .ems-emsHEAdofer{
    color: #1f4239;
  }
  .vIewjDEtails{
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #1D3C34;
    color: white;
  }
  #form-control_TEXTarea{
    height: 300px;
  }
  #applybtnAdjust{
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-left: 10px !important;
  
  }
  #applybtnAdjust2{
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-left: 10px !important;
  }
  
  
  
  @media(max-width:1400px){
  
    .searchBtN{
      width: 100px;
  
    }
  
    #search-jobS{
      width: 350px;
      height: 50px;
    }
    .search-job-inputClass{
      width: 200px !important;
      height: 50px;
    }
    #searchBtNId{
      width: 120px;
      height: 50px;
    }
    .categories_fieldI{
      width: 200px;
    }
    .search-job-input{
      width: 250px;
  
    }
    #search-job-input_id{
      width: 110px;
    }
  
    .nav_Central > .nav-links_nav {
  
      margin-left: 10%;
    }
    .all_relatedJobSCover{
      border: solid 1px;
      width: 80%;
      padding: 5px;
    }
  }
  
  
  
  @media(max-width:993px){
    .nav_Central > .nav-links_nav {
  
      margin-left: 0%;
    }
    .search-job-input{
      width: 200px;
    }
    .categories_fieldI{
      width: 100px;
      height: 50px;
      display: none;
    }
    .profile_page_Cover{
      margin-top: 2%;
    }
    .offcanvas{
      width: 80% !important;
    }
  
  
    .forSearch{
      margin-top: 10%;
    }
    #categories_fi{
      width: 150px;
      height: 50px;
    }
  
    #search-jobS{
      width: 250px;
      height: 50px;
    }
    .search-job-inputClass{
      width: 150px !important;
      height: 50px;
    }
    #searchBtNId{
      width: 100px;
      height: 50px;
    }
    .searchBtN{
      width: 60px;
    }
    .span_SEArch{
      display: none;
    }
  
    .cover_jobDetailsDiv{
      justify-content: center !important;
      /* text-align: center; */
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 5%;
    }
    .details_textAdJustAll{
  
      padding-left: 30px;
      width: 90%;
      padding-bottom: 20px;
  
    }
    .gen_jobDetails h2{
      width: 90%;
    }
    .all_relatedJobSCover{
      border: solid 1px;
      width: 100%;
      padding: 5px;
    }
    .loginText-Div{
      margin-left: 5px !important;
  
    }
    .ul_employer_select2{
      margin-left: 80px !important;
    }
  
  }
  
  @media (max-width:850px) {
    .nav_Central > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .nav_Central > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav_Central > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .nav_Central> .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav_Central > .nav-links_nav {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #1D3C34 !important;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 60px;
      left: 0px;
    }
    .nav_Central > .nav-links_nav > a {
      display: block;
      width: 100%;
  
    }
    .nav_Central > #nav-check:not(:checked) ~ .nav-links_nav {
      height: 0px;
    }
    .nav_Central > #nav-check:checked ~ .nav-links_nav {
      height: calc(100vh - 50px);
      overflow-y: auto;
      margin-left: -0;
    }
    .navVerifiedAccountlog{
      margin-left: 25px;
    }
  
  }
  @media(max-width:640px){
    .forSearch{
      margin-top: 20%;
    }
    .offcanvas{
      width: 100% !important;
    }
  
    #categories_fi{
      width: 70px !important;
      height: 40px;
      display: none;
    }
    .search-job-inputClass{
      width: 80px !important;
      height: 40px;
    }
  
    #search-jobS{
      width: 200px;
      height: 40px;
    }
  
    #searchBtNId{
      width: 50px;
      height: 40px;
    }
    .span_SEArch{
      display: none;
    }
    #search-job-input_id{
      width: 70px !important;
    }
    .searchBtN{
      width: 30px;
    }
    .register-form{
      /* margin-left: -50px !important ; */
    }
    .navVerifiedAccountlog{
      margin-left: 18px;
    }
    .loginText-Div{
      margin-left: 0px !important;
  
    }
    .forwardCover{
      margin-top: 11%;
    }
    .button-35{
      width: 150px !important;
    }
  
  }
  
  
  /*Home page style*/
  
  
  /* font-family: 'Alfa Slab One', serif;
  
  font-family: 'Lobster', sans-serif;
  font-family: 'Russo One', sans-serif; */
  
  
  
  /* Foooooterrrr */
  
  /* a {
      color: #fff;
      text-decoration: none;
    } */
  .pg-footer {
    font-family: 'Jost','Roboto', sans-serif;
    margin-top: 5%;
  }
  
  
  .footers {
    background-color: #1D3C34;
    color: #fff;
  }
  .footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
  }
  .footer-wave-path {
    fill: #fffff2;
  }
  
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
  }
  
  .footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
  }
  
  .footer-content-column ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-logo-link {
    display: inline-block;
  }
  .footer-menu {
    margin-top: 30px;
  }
  
  .footer-menu-name {
    color: #fffff2;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }
  .footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
  }
  .footer-menu-list li {
    margin-top: 5px;
  }
  
  .footer-call-to-action-description {
    color: #fffff2;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .footer-call-to-action-button:hover {
    background-color: #fffff2;
    color: black;
  }
  .button:last-of-type {
    margin-right: 0;
  }
  .footer-call-to-action-button {
    background-color: #D0EE27;
    border-radius: 21px;
    color: black;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
  }
  .footer-call-to-action {
    margin-top: 30px;
  }
  .footer-call-to-action-title {
    color: #fffff2;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }
  .footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
  }
  .footer-call-to-action-link-wrapper a {
    color: #fff;
    text-decoration: none;
  }
  
  
  
  
  
  .footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
  
  }
  
  .footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
  }
  
  .footer-social-amoeba-path {
    fill:  #D0EE27;
  }
  
  .footer-social-link.linkedin {
    height: 26px;
    left: 3px;
    top: 11px;
    width: 26px;
    color: #1D3C34;
  }
  
  .footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
  }
  
  .hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
  }
  
  .footer-social-icon-svg {
    display: block;
  }
  
  .footer-social-icon-path {
    fill: #fffff2;
    transition: fill .2s;
  }
  
  .footer-social-link.twitter {
    height: 28px;
    left: 62px;
    top: 3px;
    width: 28px;
    color: #1D3C34;
  }
  .logoJust_footer{
    /* background-color: #027b9a; */
  }
  .logoJust_footer img{
    margin-left: -10%;
    width: 200px;
  }
  
  .footer-social-link.youtube {
    height: 24px;
    left: 123px;
    top: 12px;
    width: 24px;
    color: #1D3C34;
  }
  
  .footer-social-link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
    color: #1D3C34;
  }
  
  .footer-copyright {
    background-color: #D0EE27;
    color: #fff;
    padding: 15px 30px;
    text-align: center;
  }
  
  .footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
  
  .footer-copyright-text {
    color: black !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .footer-copyright-link {
    color: black;
    text-decoration: none;
  }
  
  
  .text_second_image{
    position: absolute;
    width: 350px;
    margin-left: -18%;
    margin-top: 5%;
  }
  
  .general_homeDive{
    top: 0;
    margin: 0;
    padding: 0;
    background-color: #F9F9F9;
  
  }
  .jobcards_DivHome{
    background-color: #00A2E2 !important;
  }
  .home_Text_aLL{
    /* background-color:#00A2E2  */
  }
  
  .home_Text_aLLH2{
    padding-top: 6%;
    text-align: center;
    color: white;
    padding-left: 20%;
    padding-right: 20%;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Jost', sans-serif;
  
  }
  .home_Text_aLL_second{
    display: flex !important;
    justify-content: space-around;
    top: 0;
  
    margin-top: 0;
    padding: 20px;
    height: 45vh;
  }
  .home_Text_aLL_sec{
    /* background-image: url("https://img.freepik.com/free-vector/minimal-style-geometric-round-shape-blue-background_1017-45301.jpg"); */
    background-size: cover;
    background-color: #1D3C34;
    height: 700px;
  }
  .text_first_textDiv{
    margin-top: 3%;
    /*display: flex;*/
  }
  
  .text_second_textDiv{
    color: white;
    margin-top: 3%;
  }
  .employer_HeadText{
    color: white ;
    font-size: 25px;
    font-weight: bold;
  }
  .employer_HeadTextp{
    color: #dee99e;
    font-size: 15px;
  
  }
  .bn363744{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.0rem 2rem;
    width: 150px;
    height: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    color: black !important;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
    border-radius: 3rem;
    cursor: pointer;
  }
  .bn363744:hover{
    color: white !important;
  }
  
  /* btn */
  .bn3637 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.0rem 2rem;
    width: 200px;
    height: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    color: #1D3C34;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
    border-radius: 3rem;
    cursor: pointer;
  }
  
  .bn37 {
    border-color: transparent;
    background-color:#d0ee27 ;
    color: black ;
    font-size: medium;
    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
  }
  
  .bn37:hover {
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
  }
  .employer_HeadText_dd{
  
    justify-content: center;
    width: 200px;
  }
  .bn3637{
  
  }
  .employeeeSeed{
    position: absolute;
    margin-top: -2.3%;
    margin-left: 7.8%;
    padding: 10px;
    border: none;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #D0EE27;
  
  }
  
  .employeeeSEarchbtn{
    padding: 10px;
    border-radius: 20px;
    border: none;
  }
  .employer_HeadTextp_click{
    font-weight: bold;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-style: italic;
    font-weight: lighter;
  }
  
  /*.searchbox-wrap {*/
  /*  display: flex;*/
  /*  width: 200px !important;*/
  /*}*/
  
  
  /*.inputFront_change{*/
  /*  flex                             : 1;*/
  /*  padding                          : 10px 5px;*/
  /*  font-size                        : 1.1em;*/
  
  /*  -webkit-border-top-left-radius   : 25px;*/
  /*  -webkit-border-top-right-radius   : 25px;*/
  /*  -webkit-border-bottom-left-radius: 25px;*/
  /*  -webkit-border-bottom-right-radius: 25px;*/
  /*  -moz-border-radius-topleft       : 25px;*/
  /*  -moz-border-radius-bottomleft    : 25px;*/
  /*  border-top-left-radius           : 25px;*/
  /*  border-bottom-left-radius        : 25px;*/
  /*  box-shadow                       : none;*/
  /*  border                           : none;*/
  /*  box-shadow                       : 2px 4px 6px rgba(0, 0, 0, 0.19);*/
  
  /*}*/
  
  /*.btsearchbox-wrap {*/
  
  /*  background-color: #1D3C34;*/
  /*  -webkit-border-top-right-radius: 25px;*/
  /*  -webkit-border-bottom-right-radius: 25px;*/
  /*  -moz-border-radius-topright: 25px;*/
  /*  -moz-border-radius-bottomright: 25px;*/
  /*  border-top-right-radius: 25px;*/
  /*  border-bottom-right-radius: 25px;*/
  
  /*  border: none;*/
  /*  cursor: pointer;*/
  /*  cursor: hand;*/
  /*}*/
  /*.span_chanGe{*/
  /*  margin-left     :-80px;*/
  /*  padding         : 13px 5px;*/
  
  /*  font-size       : 0.9em;*/
  /*  text-transform  : uppercase;*/
  /*  font-weight     :bold ;*/
  /*  color           : black;*/
  /*  background-color:#D0EE27;*/
  /*  border-radius: 30px;*/
  
  /*  box-shadow      : 2px 4px 6px rgba(0, 0, 0, 0.19);*/
  /*}*/
  /*.span_chanGe:hover{*/
  /*   background-color: #F2F051;*/
  /*   box-shadow      : 2px 2px 4px rgba(0, 0, 0, 0.19);*/
  /* }*/
  
  
  .searchbox-wrap{
    display     : flex;
    width       :200px !important;
    /* margin-top  : 8%;  */
    /* margin-left : auto;
    margin-right: auto; */
  }
  
  .searchboxInput{
    flex                             : 1;
    padding                          : 10px 5px;
    font-size                        : 1.1em;
  
    -webkit-border-top-left-radius   : 25px;
    -webkit-border-top-right-radius   : 25px;
    -webkit-border-bottom-left-radius: 25px;
    -webkit-border-bottom-right-radius: 25px;
    -moz-border-radius-topleft       : 25px;
    -moz-border-radius-bottomleft    : 25px;
    border-top-left-radius           : 25px;
    border-bottom-left-radius        : 25px;
    box-shadow                       : none;
    border                           : none;
    box-shadow                       : 2px 4px 6px rgba(0, 0, 0, 0.19);
  
  }
  
  .btsearchbox-wrap{
    /* padding-right                     : 10px; */
    /*display: none;*/
    /* background-color                  : #1D3C34;*/
    /*-webkit-border-top-right-radius   : 25px;*/
    /*-webkit-border-bottom-right-radius: 25px;*/
    /*-moz-border-radius-topright       : 25px;*/
    /*-moz-border-radius-bottomright    : 25px;*/
    /*border-top-right-radius           : 25px;*/
    /*border-bottom-right-radius        : 25px;*/
    /*box-shadow                        : 5px 4px 6px rgba(0, 0, 0, 0.19);*/
    /*border                            : none;*/
    /*border-color: #1D3C34;*/
    /*cursor                            : pointer;*/
    /*cursor                            : hand;*/
  }
  
  
  
  
  /*&:hover{*/
  /*   background-color: #F2F051;*/
  /*   box-shadow      : 2px 2px 4px rgba(0, 0, 0, 0.19);*/
  /* }*/
  
  
  
  
  .clickREview{
    margin-left     :-60px !important;
    padding         : 13px 5px;
  
    font-size       : 0.9em;
    text-transform  : uppercase;
    font-weight     :bold ;
    color           : black;
    background-color:#D0EE27;
    border-radius: 30px;
    cursor: pointer;
    border: none;
  
    /*!* border-top-right-radius: 18px;*/
    /*border-bottom-right-radius: 18px; *!*/
    box-shadow      : 2px 4px 6px rgba(0, 0, 0, 0.19);
  
  }
  .popImage_image1{
    width: 400px;
  }
  .popImage_DIV{
    justify-content: center;
    text-align: center;
  }
  .popImage_DIVcentral{
    display: flex;
  }
  .popImageTEXT{
    padding: 20px;
    font-size: 20px;
  }
  .popImageT2{
    margin-top: 5%;
    font-size: 20px;
  }
  .nav-links_nav_a-logout{
    margin-left: 30px;
    cursor: pointer;
  }
  .nav-links_nav_a{
    cursor: pointer;
    padding: 15px;
  }
  .employer_Headitali{
    color: #D0EE27;
  }
  .home_Textdown{
    font-size: 20px  ;
  }
  .employerLIttle{
    font-size: 11px;
    width: 250px;
    color: #d0ee27;
    padding: 2px;
  }
  .text_second_textDiv_imgimg{
    margin-top: 10%;
    width: 350px;
  }
  
  .aPPlyadust{
    padding-left: 10px !important;
    margin-left: -70px !important;
  }
  .redTextLOg{
    margin-left: 5% !important;
    background-color: #cece30;
    padding:8px ;
    cursor: pointer;
    border-radius: 3px;
  }
  .redTextLOgMy{
    margin-left: 3% !important;
    cursor: pointer;
  
    padding: 10px;
  
  }
  .redTextLOg:hover{
    color: red !important;
  }
  .redTextLOgMy:hover{
    background-color: #262626;
  
  
  }
  #links_nav{
    background-color: #D0EE27;
    padding: 5px !important;
    border-radius: 3px;
    color: #0c0e0f;
    font-weight: bold;
  }
  .v-verifytext{
    color:  #D0EE27;
  }
  
  
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    outline: none;
  }
  
  a,
  a:active,
  a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
  }
  
  /*ul {*/
  /*  margin: 0;*/
  /*  padding: 0;*/
  /*  list-style: none;*/
  /*}*/
  /*img {*/
  /*  max-width: 100%;*/
  /*  height: auto;*/
  /*}*/
  section {
    padding: 60px 0;
    /* min-height: 100vh;*/
  }
  
  .sec-title{
    position:relative;
    z-index: 1;
    margin-bottom:60px;
    padding: 5px;
  }
  
  .sec-title .title{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #1D3C34;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  .sec-title h2{
    position: relative;
    display: block;
    font-size:40px;
    line-height: 1.28em;
    color: #222222;
    font-weight: 600;
    padding-bottom:18px;
  }
  
  .sec-title h2:before{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    width:50px;
    height:3px;
    background-color:#d1d2d6;
  }
  
  .sec-title .text{
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
    margin-top: 35px;
  }
  
  .sec-title.light h2{
    color: #ffffff;
  }
  
  .sec-title.text-center h2:before{
    left:50%;
    margin-left: -25px;
  }
  
  .list-style-one{
    position:relative;
  }
  
  .list-style-one li{
    position:relative;
    font-size:16px;
    line-height:26px;
    color: #222222;
    font-weight:400;
    padding-left:35px;
    margin-bottom: 12px;
  }
  
  .list-style-one li:before {
    content: "\f058";
    position: absolute;
    left: 0;
    top: 0px;
    display: block;
    font-size: 18px;
    padding: 0px;
    color: #ff2222;
    font-weight: 600;
    -moz-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1.6;
    font-family: "Font Awesome 5 Free";
  }
  
  .list-style-one li a:hover{
    color: #44bce2;
  }
  
  .btn-style-one{
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 30px;
    color: #121266;
    padding: 10px 30px;
    font-weight: 600;
    overflow: hidden;
    letter-spacing: 0.02em;
    background-color: #68B8CC;
  }
  
  /* .btn-style-one:hover{
    background-color: #D0EE27;
    color: black;
  } */
  .about-section{
    position: relative;
    margin-top: 8%;
    /* padding: 120px 0 70px; */
  }
  .rahul_image{
    width: 400px !important;
    height: 500px !important;
  }
  
  .about-section .sec-title{
    margin-bottom: 45px;
  }
  
  .about-section .content-column{
    position: relative;
    margin-bottom: 50px;
  }
  
  .about-section .content-column .inner-column{
    position: relative;
    padding-left: 30px;
  }
  
  .about-section .text{
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
  }
  
  .about-section .list-style-one{
    margin-bottom: 45px;
  }
  
  .about-section .btn-box{
    position: relative;
  }
  
  .about-section .btn-box a{
    padding: 15px 50px;
  }
  
  .about-section .image-column{
    position: relative;
  }
  .appCardLogo-div{
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    flex-wrap: wrap;

  }
  .appCardLogo-div div{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 5px !important;
   
  }
  .appCardLogo{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: solid #a5e2f1 5px;
  }
  .about-section .image-column .text-layer{
    position: absolute;
    right: -110px;
    top: 50%;
    font-size: 325px;
    line-height: 1em;
    color: #ffffff;
    margin-top: -175px;
    font-weight: 500;
  }
  .displayRow{
    display: flex;
    justify-content: space-around !important;
  }
  .con_side-about{
    padding-left: 10%;
    padding-right: 10%;
  font-family: "Poppins", sans-serif;
  /* font-size: 15px; */
  }
  
  .about-section .image-column .inner-column{
    position: relative;
    /* padding-left: 80px;
    padding-bottom: 0px; */
  }
  .about-section .image-column .inner-column .author-desc{
    position: absolute;
    bottom: 16px;
    z-index: 1;
    background: orange;
    padding: 10px 15px;
    left: 96px;
    width: calc(100% - 152px);
    border-radius: 50px;
  }
  .about-section .image-column .inner-column .author-desc h2{
    font-size: 21px;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    margin: 0;
  }
  .about-section .image-column .inner-column .author-desc span{
    font-size: 16px;
    letter-spacing: 6px;
    text-align: center;
    color: #fff;
    display: block;
    font-weight: 400;
  }
  .about-section .image-column .inner-column:before{
    content: '';
    position: absolute;
    width: calc(50% + 100px);
    height: calc(100% + 160px);
    top: -80px;
    left: -3px;
    background: transparent;
    z-index: -20;
    border: 44px solid #121062;
  }
  /* .ulSETlist li{
  list-style-type: none;
  } */
  /* .fadeInLeft{
    margin-left: ;
  } */

  .listTap{
    list-style-type: none;
  }
  .lightbox-image{
    margin-left: 20%;
  }
  .about-section .image-column .image-1{
    position: relative;
  }
  .about-section .image-column .image-2{
    position: absolute;
    left: 0;
    bottom: 0;
  }
  
  .about-section .image-column .image-2 img,
  .about-section .image-column .image-1 img{
    box-shadow: 0 30px 50px rgba(8,13,62,.15);
    border-radius: 46px;
  }
  
  .about-section .image-column .video-link{
    position: absolute;
    left: 70px;
    top: 170px;
  }
  
  .about-section .image-column .video-link .link{
    position: relative;
    display: block;
    font-size: 22px;
    color: #191e34;
    font-weight: 400;
    text-align: center;
    height: 100px;
    width: 100px;
    line-height: 100px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 30px 50px rgba(8,13,62,.15);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .about-section .image-column .video-link .link:hover {
    background-color: #191e34;
    color: #0c0e0f;
  }
  
  
  
  
  
  
  
  
  
  
  
  /* Laptop..................... */
  @media(max-width:1600px){
    .text_second_textDiv_imgimg{
      width: 300px;
      margin-top: 20%;
    }
    .text_second_image{
      position: absolute;
      width: 300px;
      margin-left: -17%;
      margin-top: 7%;
    }
    .employeeeSeed{
      margin-top: -2.8%;
      margin-left: 9.2%;
    }
  }
  /* Small system................ */
  @media(max-width:1400px){
    .text_second_image{
      position: absolute;
      width: 250px;
      margin-left: -18%;
      margin-top: 3%;
    }
    .text_second_textDiv_imgimg{
      width: 250px;
      margin-top: 0%;
      margin-left: 10%;
    }
    .employeeeSeed{
  
      margin-top: -3.41%;
      margin-left: 11.5%;
  
    }
  
  }
  @media(max-width:1150px){
    .home_Text_aLLH2{
      font-size: 20px;
      padding-top: 12%;
      text-align: center;
      color: white;
      padding-left: 5% !important;
      padding-right: 5% !important;
  
    }
    .text_second_image{
      display: none !important;
    }
    .text_second_textDiv_imgimg{
      display: none !important;
    }
  
  
  }
  
  
  
  /* Ipad................. */
  @media(max-width:993px){
    .text_second_image{
      display: none;
    }
    .text_second_textDiv_img{
      display: none;
    }
    .employeeeSeed{
  
      margin-top: -5.64%;
      margin-left: 19%;
  
    }
    .home_Text_aLLH2{
      font-size: 20px;
      padding-top: 10%;
      text-align: center;
      color: white;
      padding-left: 5% !important;
      padding-right: 5% !important;
  
    }
    .image-column{
      display: none;
    }
  
  }
  
  
  
  
  
  /* Media Query For different screens */
  @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
  
  }
  @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  
  }
  @media (min-width:1281px) { /* hi-res laptops and desktops */
  
  }
  @media (min-width: 760px) {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 450px;
      position: relative;
    }
  
    .footer-wave-svg {
      height: 50px;
    }
  
    .footer-content-column {
      width: 24.99%;
    }
  }
  @media (min-width: 568px) {
    /* .footer-content-column {
        width: 49.99%;
    } */
  }
  
  
  /* mobile................... */
  @media(max-width:640px){
  
    .home_Text_aLL{
      margin-top: 10%;
    }
    .home_Text_aLLH2{
      font-size: 20px;
      padding-top: 10%;
      text-align: center;
      color: white;
  
      padding-left: 10% !important;
      padding-right: 10% !important;
  
    }
    .home_Text_aLL_second{
      display: grid !important;
      justify-content: space-around;
      top: 0;
      margin-top: 0;
      padding: 20px;
      height: 500px !important;
    }
    .home_Text_aLL_sec{
  
      background-size: cover;
      background-color: #1D3C34;
      height: 1050px !important;
  
    }
    #links_nav{
      background-color: #D0EE27;
      padding: 5px !important;
      border-radius: 3px;
      color: #0c0e0f;
      font-weight: bold;
      width: 100px;
      text-align: center;
    }
    .con_side-about{
        padding-left: 0%;
        padding-right: 0%;
      font-family: "Poppins", sans-serif;
      padding: 2px;
      }
  
  }
  /* @media(max-width:480px){
      .home_Text_aLL_second{
          height: 70vh;
      }
  } */
  
  
  
  
  