.setfamilyImg{
    width: 100% !important;
    height: 300px !important;
}
.setfamilyDiv{
   
}
.ban_sec {
    width: 100%;
  }
  .ban_imgs {
    width: 100%;
    position: relative;
  }
  .ban_imgs img {
    width: 100%;
    /* background-position: center center; */
    height: 70vh;
  }
  .ipadSaveIMG{
    display: none;
  }
  .phoneSaveIMG{
    display: none;
  }

  .ban_text {
    /* position: absolute; */
    top: 50%;
    left: 6%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .ban_text strong {
    font: 800 62.22px/70px "Montserrat", sans-serif;
    color: black;
    font-size: 30px;
    text-transform: uppercase;
    /* padding-right: 90% !important; */
  }
  .voiceDiv-strong{
    font: 800 80.22px/70px "Montserrat", sans-serif;
    color: #0A074E ;
    font-size: 60px;
    font-weight: 900;
  }
  .ban_text strong span {
    font: 800 44.44px/52px "Montserrat", sans-serif;
    letter-spacing: 3px;
    width: 100%;
    
  }
  .ban_text p {
    font: 400 25px/30px "Montserrat", sans-serif;
    color: #10117D;
    margin: 7px 0 25px;
    font-weight: bold;
  }
  .learDropLearny {
    display: inline-block;
    font: 800 19.39px/24px "Montserrat", sans-serif;
    background:#2BBCF1 ;
    border-radius: 26px;
    color: #fff !important;
    padding: 12px 28px;
    text-align: center;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration:none;
  }
  .learDropLearny:hover {
    background: white;
    text-decoration: none;
    color: black !important;
  }
  
  @media (min-width: 1200px) and (max-width: 1399px) {
    .ban_text p {
      font-size: 21px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .ban_text p {
      font-size: 17px;
    }
    .ban_text strong {
      font-size: 50px;
      line-height: 60px;
    }
    .ban_text strong span {
      font-size: 37px;
      width: 100% !important;
    }
    .ban_text a {
      font-size: 16px;
      line-height: 19px;
  
    
  }
  
  @media only screen and (max-width: 991px) {
    .ban_text strong {
      font-size: 35px;
      line-height: 40px;
    }
    .ban_text strong span {
      font-size: 28px;
      line-height: 35px;
      letter-spacing: 2px;
      width: 100% !important;
    }
    .ban_text p {
      font-size: 14px;
      line-height: 20px;
    }
    .ban_text a {
      font-size: 13.39px;
      line-height: 15px;
    }
    .voiceDiv-strong{
      
      font-size: 40px !important;
      font-weight: 700;
    }
  }
  .ban_imgs img {
   display: none;
  }
  .ipadSaveIMG{
    display: block !important;
    height: 70vh !important;
  }
  }
  @media only screen and (max-width: 767px) {
    .ban_imgs img {
      min-height: 290px;
      object-fit: cover;
    }
    .ban_text strong {
     color: white;
    }
    .ban_imgs img {
      display: none;
     }
     .ipadSaveIMG{
       display: none;
     }
     .phoneSaveIMG{
      display: block !important;
      height: 70vh !important;
     }

    
  }
  @media only screen and (max-width: 575px) {
    .ban_text strong {
      background: rgba(0, 0, 0, 0.8);
      padding: 10px;
      width: 100% !important;
      display: block;
    }
    .ban_text strong span {
        font-size: 22px;
        width: 100%;
        line-height: 31px;
        letter-spacing: 1px;
      }
      .voiceDiv-strong{
        font-size: 30px !important;
        font-weight: 700;
      }
  }
  @media only screen and (max-width: 480px) {
    .ban_text strong span {
      font-size: 22px;
      line-height: 31px;
      width: 100% !important;
      letter-spacing: 1px;
    }
    .ban_text {
      left: 2%;
    }
   
  }
  