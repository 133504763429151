


/* .container {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
} */

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

#contact {
  background: #FFFFFF;
  padding: 25px;
  border: #60606065 1px solid;
  /* margin: 150px 0; */
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
}
#contact-submit{
 
  border-radius: 5px !important;
  box-shadow: rgb(38, 57, 77) 0px 10px 30px -10px !important;
  background-image: linear-gradient(#2BBCF1, #0F0C7D) !important;
  width: 50% !important;
}
.allBTNSUMit-product{
  width: 50%;
}
.allBTNSUMit-products{
  width: 50%; 
}

#contact-submitCancel{
 border-radius: 5px !important;
  box-shadow: rgb(134, 176, 221) 0px 10px 30px -10px !important;
  background-image: linear-gradient(#2BBCF1, #3f3d6b) !important;
  width: 50% !important;
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}


#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  /* min-width: 100%; */
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  /* background: #4CAF50; */
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  /* background: #43A047; */
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

.form_centerText{


  padding-left: 5%;
  padding-right: 5%;
  /* background-color: #F9F9F9; */
}
.row_doWn{
 
  width: 100%;
  display: flex;
}
.row_sec_doWn{
  width: 50%;
  
}
.leftDotset{

}

.setDivFOrm{
  display: flex;
  gap: 2%;
}
.checkALERt{
  padding: 15px;
  display: flex ;
   justify-content: space-between;
   flex-wrap: wrap;
}
.setProDUCtbtn{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
   
    text-align: center;
}




.navbar::before{
  content: "";

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  z-index: -1;
  background: linear-gradient(to right,  #1e5799 0%, #3ccdbb 0%, #16c9f6 100%);
}
.nav-item a{
  color: #fff!important;
  font-weight: bold;
}
.header-section{
  width: 100%;
  height: inherit;
  color: #fff;
  text-align: center;
  position: relative;
}
.center-div{

  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.header-buttons a{
  border: 1px solid #fff;
  border-radius: 100px;
  margin: 0 5px;
  padding: 12px 35px;
  outline: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}
.header-buttons a:hover{
  text-decoration: none;
  color: #50d1c0;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.center-div p{
  font-size: 1.3rem;
  padding: 10px 0 20px 0;
  color: #fff;
}


.header-extradiv{
  width: 100%;
  height: auto;
  margin: 100px 0;
  text-align: center;
}
.extradiv-cav p{text-align: justify;}
.extradiv-cav{
  background-color: #fff;
  border: none;
  padding: 30px!important;
  border-radius: 3px;
  transition: 0.3s;
}
.extradiv-cav:hover{
  box-shadow: -0px 0px 5px 0 rgba(0,0,0,0.3);
  transform: translateY(-1px);
}
.centralSaint_div{
/* background-color: #00abff; */
/* justify-content: center !important; */
}



.serviceoffers{
  background-color: #f7f7f9;
  padding: 50px 0;
  margin-bottom: 50px;
}
.headings_cav{
  margin-bottom: 50px;
}
.headings_cav h1{
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.names h1{
  color: #2e2e2e;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
}
.service-icons{

  display: flex;
  justify-content: center;
  align-items: center;
}
.progress{
  height: 0.6rem!important;
  margin-bottom: 25px!important;
}


.project-work{
  margin: 100px 0;
}
.project-work h1{
  font-size: 2rem;
  text-align: center;
}


.pricing_cav{
  width: 100%;
  /* height: 100vh; */
  /* padding: 50px; */
  position:  relative;
  margin-top: 2px;
}
.pricing_cav::before{
  content: "";
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(160deg, #16c9f6 55%, #fff 0%);
}
.money{
  font-size: 40px;
  line-height: 1;
  color: #606060;
}
.card{
  transition: 0.4s ease;

}
.card-header_cav{
  font-size: 1.6rem;
  font-weight: bold;
  background: #fff!important;
  padding: 25px 0!important;
}
.card-header{
  font-weight: bold;
  font-size: 20px;
  background-image: linear-gradient(#2BBCF1, #0F0C7D);
  color: white;

}
.card-body_cav{
/* width: 500px; */
font-size: 20px;
/* background-color: #154734; */
width: 100% !important;
padding: 10px;
}
.card-body_cav li{
 
  color: #606060;
  
}
.card-footer_cav{
  background: #fff!important;
  padding: 30px 0!important;
}
.card-footer_cav a{
  border: 1px solid #50c1d0;
  border-radius: 100px;
  margin: 0 5px;
  padding: 12px 35px;
  outline: none;
  color: #50d1c0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}
.card_cav{
  height: 400px !important;
  border: #504e4e6b 1px solid;
  border-radius: 5px !important;
}
.card_cav:hover .card-footer a{
  text-decoration: none;
  color: #fff;
  background-color: #50d1c0;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.card_cav:hover{
  transform: translateY(-20px);
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.card_cav:hover .card-header_cav, .card:hover .money{
  color: #50d1c0;
}
.card-second{
  transform: translateY(-20px);
  
}
.carD-Set{
 
}

.side-imageS_sec{
  width: 100%;
  padding: 40px;
}


.happyclients{
  width: 100%;
  /* background-color: #00abff; */
  /* height: 100vh; */
  /* padding: 80px 0; */
}
.box_cav{
  text-align: center;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  border-radius: 2px;
  transition: 0.3s ease;
}
.box_cav:hover{
  background: #16c9f6;
}
.box_cav:hover p{
  color: #fff;
}
.carousel-indicators{

  right: 0;
  bottom: -60px!important;
}
.carousel-indicators li{
  background-color: #16c9f6!important;
}
.box_cav a{
  position: relative;
}
.box_cav a img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 20px;
}
.box_cav a::after{
  content: "\f10d";
  font-family: FontAwesome;
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, #1e5799 0%, #3ccdbb 0%, #16c9f6 100%);
  color: #fff;
  
  top: 120%;
  left: 70%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_cav h1{
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}
.box_cav h2{
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 20px;
}
  
.contactus{
  width: 100%;
  height: 100vh;
  padding: 80px 0;
  position: relative;
}
.contactus::before{
  content: "";
 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(330deg, #16c9f6 55%, #fff 0%);
}
.form-button button{
  border: 1px solid #50c1d0;
  border-radius: 100px;
  margin: 0 5px;
  padding: 12px 35px;
  outline: none;
  color: #50d1c0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  background: transparent;
}
form:hover .form-button button{
  background: #fff;
  color: #50d1c0;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
::placeholder{
  font-size: 0.85rem;
}
 

.newsletter{
  width: 100%;
  height: auto;
  margin: 80px 0;
}
.newsletter-input{
  border-radius: 100px 0px 0px 100px!important;
  min-width: 150px;
  min-height: 45px;
}
.input-group-text{
  color: #fff!important;
  background: #5bc0de!important;
  border-radius: 0 100px 100px 0!important;
  min-width: 130px;
  min-height: 45px;
}
.input-group-text:hover{
  cursor: pointer;
  opacity: 0.8;
}



.footersection{
  width: 100%;
  height: auto;
  padding: 70px 0 20px 0;
  background: #00abff;
  position: relative;
}
.footersection p{
  color: #fff;
}
.footer-navlinks{
  text-align: center;
}
.footersection li a{
  font-size: 0.9rem;
  line-height: 1.6;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
}
.footersection h3{
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 25px;
  font-size: 1.2rem!important;
  font-weight: 600;
  text-shadow: 0 2px 5px rgba(0,0,0,0.4);
}
#myBtn{
 
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 10;
  border: none;
  color: #fff;
  background: #00abff;
  padding: 10px;
  border-radius: 10px;
}
#myBtn:hover{
  background: #606060;
}

@media (max-width: 768px) {
  .nav-item{
    text-align: center!important;
  }
  .pricing_cav, .happyclients, .contactus{
    height: auto;
    margin-top: 50px;
  }
  .card-second{
    transform: translateY(0px);
    margin: 30px 0;
  }
  .contactus p{
    padding: 0 50px;
  }
  .footer-navlinks{
    text-align: left;
  }
  .footer-div{
    margin: 30px 0;
  }
}


.alertthank{
  text-align: center;
  color: green;
}
.tecShowDEtails{
 display: flex; 
 flex-wrap: wrap;
 justify-content: space-between;
 background-color: aliceblue;
 padding: 10px;
}
.holdMain{
font-weight: bold;
}
.yourDataN{
  font-weight: bold;
  color: rgba(22, 21, 21, 0.597);
}
.closeSHOWDet{
  width: 20%;
  padding: 10px;
  color: white;
   border-radius: 5px;
   margin: 0 auto;
   background-color: #00abff;
  
}
.closeSHOWDetDiv{
  justify-content: center;
  text-align: center;
 
}

.ourPro_all{
  /* background-image: url("../../images/chucks1an1.png"); */
  /* width: 100%; */
  height: 50vh;
  color: white;
  /* background-size: cover; */

} 
.kktest{
 
  background-image: url("../../images/upgradeNow.png");
  /* background: linear-gradient(330deg, #16c9f6 55%, #fff 0%); */
  height: 60vh;
  background-size: cover;
background-color: rgba(0, 0, 0, 0.026);
}
.kktest_insideDiv{
 padding-top: 6%;
 padding-left: 5%;
  
}
.kktest_insideDivIN{
  width: 750px;
   font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; 
  /* margin: 0 auto;
  justify-content: center;
  text-align: center; */
  background-color: rgba(0, 0, 0, 0.042);
  /* color: white; */
  padding: 50px;
  font-weight: bold;
 
}
.kktest_insideDivIN h2{
  font-weight: bold;
  font-size: 45px;
 
  /* color: linear-gradient(to right, #5756A2, #8DCFE1) !important; */
}
.toNEtwork{
  font-size: 30px;
  color: #1e5799;
}


.button-firstDiv {
  appearance: none;
  background-color: transparent;
  border: 2px solid #2BBCF1 !important;
  background-image: linear-gradient(#2BBCF1, #0F0C7D);
  /* border-radius: 15px; */
  box-sizing: border-box;
  color: black !important;
  color: white !important;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  /* font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 200px;
  will-change: transform;
  margin-left: 3%;
  margin-top: 3% !important;

}
/* .row{
  display: flex;
} */

.button-firstDiv:disabled {
  pointer-events: none;
}

.button-firstDiv:hover {
  color: #154734;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-firstDiv:active {
  box-shadow: none;
  transform: translateY(0);
}
.button-firstDiv{
 
  justify-content: center !important;
  text-align: center;
}
.coinTainSet{
  margin-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
}
.LINEdiv{
  border: 2px solid black;
  height: 2px;
  width: 100px;
  margin: 0 auto;
}






















.contain-alldiv{

  background-image: url("allHomeChttps://info.ehl.edu/hubfs/1440/1440x960-beer-types.jpg");
  width: 100%;
  height: 70vh;
  background-size: cover;
  
  }


  .divControFave {
      height: 600px;
      margin: 0;
      display: grid;
      grid-template-rows: 500px 100px;
      grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
      align-items: center;
      justify-items: center;
    }
    
    
    div#carousel {
      grid-row: 1 / 2;
      grid-column: 1 / 8;
      width: 100vw;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    
      transform-style: preserve-3d;
      perspective: 600px;
      --items: 5;
      --middle: 3;
      --position: 1;
      /* pointer-events: none; */
    }
    
    div.item {
      position: absolute;
      width: 250px;
      height: 300px;
     cursor: pointer;
      --r: calc(var(--position) - var(--offset));
      --abs: max(calc(var(--r) * -1), var(--r));
      transition: all 0.25s linear;
      transform: rotateY(calc(-10deg * var(--r)))
        translateX(calc(-300px * var(--r)));
      z-index: calc((var(--position) - var(--abs)));
      color:white;
      background-color: #3A6350 !important;
    
    }

    .backgroundBig-image{
     
      --r: calc(var(--position) - var(--offset));
      --abs: max(calc(var(--r) * -1), var(--r));
      transition: all 0.25s linear;
      transform: rotateY(calc(-10deg * var(--r)))
      translateX(calc(-300px * var(--r)));
      z-index: calc((var(--position) - var(--abs)));
      margin-top: 2%;
      margin-left: 10%;
    } 
    .dash_searchImp{
      border: 1px solid #3A6350;
      width: 30px;
      height: 1px;
      margin-top: 2%;
    }
    .dash_searchImpNext{
      border: 1px solid white;
      width: 30px;
      height: 1px;
      margin-top: 3%;
    }



    .dash_searchImpDiv{
       display: flex; 
       gap: 5px;  
    }
    .carousel__slides{
      display: block;
    }

   
    .test1e{
      /* opacity: 1 !important; */
     
      height: 300px !important;

    }
    .coverDivst{
      background-color: rgba(0, 0, 0, 0.705);
      width: 250px !important;
      height: 300px !important;
      color: white;
      justify-content: center;
      text-align: center;
      padding: 5px;
    }
    .coverDivst:hover{
      color: #007749 !important;
    }
    
    div.item:nth-of-type(1) {
      --offset: 1;
      background-color: #90f1ef;
      background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2022/02/crestedXeightdegrees_cut_out.png");
      /* opacity: 0.5; */
    }
    div.item:nth-of-type(2) {
      --offset: 2;
     
      background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2022/02/blackbarrel700ml_cut_out.png");
    }
    div.item:nth-of-type(3) {
      --offset: 3;
      background-color: #ff9770;
      background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2022/02/BB_proof-front.png");
    }
    div.item:nth-of-type(4) {
      --offset: 4;
      background-color: #ffd670;
      background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2022/03/18_Years_png.png");
    }
    div.item:nth-of-type(5) {
      --offset: 5;
      background-color: #e9ff70;
      background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2022/02/crested750_cut_out.png");
    }
    .inChange{
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    .coverDivsth1{
      position: relative;
     
    }
    
    .inChange:nth-of-type(1) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
    .inChange:nth-of-type(1):checked ~ div#carousel {
      --position: 1;
    }
    .inChange:nth-of-type(1):checked ~ .backgroundBig-image {
      --position: 1;
    }
    
  .inChange:nth-of-type(2) {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
    .inChange:nth-of-type(2):checked ~ div#carousel {
      --position: 2;
    }
  .inChange:nth-of-type(2):checked ~ .backgroundBig-image {
      --position: 2;
    }

    
    .inChange:nth-of-type(3) {
      grid-column: 4 /5;
      grid-row: 2 / 3;
    }
    .inChange:nth-of-type(3):checked ~ div#carousel {
      --position: 3;
    }
    .inChange:nth-of-type(3):checked ~ .backgroundBig-image {
      --position: 3;
    }
    
    .inChange:nth-of-type(4) {
      grid-column: 5 / 6;
      grid-row: 2 / 3;
    }
    .inChange:nth-of-type(4):checked ~ div#carousel {
      --position: 4;
    }
    .inChange:nth-of-type(4):checked ~ .backgroundBig-image {
      --position: 4;
    }
    
    .inChange:nth-of-type(5) {
      grid-column: 6 / 7;
      grid-row: 2 / 3;
    }
    .inChange:nth-of-type(5):checked ~ div#carousel {
      --position: 5;
    }
    .inChange:nth-of-type(5):checked ~ .backgroundBig-image {
      --position: 5;
    }
    .classdiv-text{
      padding-left: 16%;
      padding-right: 16%;
      margin-top: 3%;
    }
    .classdiv-text2{
      padding-left: 16%;
      padding-right: 16%;
      margin-top: 3%;
      margin-left: 2%;
    }
    .diferentFirst{
      font-size: 50px;
    
      width: 500px;
      font-weight: bold;
    }
    .AllsecDiv{
      background-color: #FEF8E1;
    }
    .homeSection{
      background-color: #FEF8E1;
      overflow: hidden;
    }
    .firstnmaCo{
      color: #154734;
     
    }
    .secondnmaCo{
      color: #007749 ;
    }
    #text_changeUp{
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      }

    .oFFAge{
      color: red;
      font-size: 15px;
    }

    .boldFeayurediv{
      display: flex;
      gap: 25px;
    }
    .boldFeayuredP{
       border: 1px solid;
       height: 2px;
       width: 35px;
       margin-top: 12px;
       color:  #154734;
    }
    .ourPro_all{
      background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2022/02/6-Bottle-test-2.jpg");
      width: 100%;
      height: 80vh;
      color: white;
    } 



   


/* CSS */
.button-28 {
appearance: none;
background-color: transparent;
border: 2px solid #154734;
/* border-radius: 15px; */
box-sizing: border-box;
color: #154734;
cursor: pointer;
display: inline-block;
font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
font-size: 16px;
font-weight: 600;
line-height: normal;
margin: 0;
min-height: 60px;
min-width: 0;
outline: none;
padding: 16px 24px;
text-align: center;
text-decoration: none;
transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
width: 200px;
will-change: transform;

}

.button-28:disabled {
pointer-events: none;
}

.button-28:hover {
color: white;
background-color:  #154734;
box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
transform: translateY(-2px);
}

.button-28:active {
box-shadow: none;
transform: translateY(0);
}
.divbutton-28{

justify-content: center !important;
text-align: center;
}

.button-firstDiv_prodetails {
appearance: none;
background-color: transparent;
border: 2px solid white;
/* border-radius: 15px; */
box-sizing: border-box;
color: white;
cursor: pointer;
display: inline-block;
font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
font-size: 16px;
font-weight: 600;
line-height: normal;
margin: 0;
min-height: 60px;
min-width: 0;
outline: none;
padding: 16px 24px;
text-align: center;
text-decoration: none;
transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
width: 200px;
will-change: transform;
margin-left: 18%;

}
.button-firstDiv_prodetails:disabled {
pointer-events: none;
}

.button-firstDiv_prodetails:hover {
color: #154734;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
transform: translateY(-2px);
}

.button-firstDiv_prodetails:active {
box-shadow: none;
transform: translateY(0);
}
.button-firstDiv_prodetails{

justify-content: center !important;
text-align: center;
}




.button-firstDiv {
appearance: none;
background-color: transparent;
border: 2px solid white;
/* border-radius: 15px; */
box-sizing: border-box;
color: white;
cursor: pointer;
display: inline-block;
font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
font-size: 16px;
font-weight: 600;
line-height: normal;
margin: 0;
min-height: 60px;
min-width: 0;
outline: none;
padding: 16px 24px;
text-align: center;
text-decoration: none;
transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
width: 200px;
will-change: transform;
margin-left: 3%;

}

.button-firstDiv:disabled {
pointer-events: none;
}

.button-firstDiv:hover {
color: #154734;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
transform: translateY(-2px);
}

.button-firstDiv:active {
box-shadow: none;
transform: translateY(0);
}
.button-firstDiv{

justify-content: center !important;
text-align: center;
}



.button-firstLastT {
appearance: none;
background-color: transparent;
border: 2px solid white;
/* border-radius: 15px; */
box-sizing: border-box;
color: white;
cursor: pointer;
display: inline-block;
font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
font-size: 16px;
font-weight: 600;
line-height: normal;

min-height: 60px;
min-width: 0;
outline: none;
padding: 16px 24px;
text-align: center;
text-decoration: none;
transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
width: 200px;
will-change: transform;
margin-top: 80%;
margin-left: -3%;

}

.button-firstLastT:disabled {
pointer-events: none;
}

.button-firstLastT:hover {
color: #154734;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
transform: translateY(-2px);
}

.button-firstLastT:active {
box-shadow: none;
transform: translateY(0);
}
.button-firstLastT{

justify-content: center !important;
text-align: center;
}
.controlWhitec{
display: flex;
width: 100% !important;
padding-top: 20px;
position: relative;
}
 

.divdarkgreeenc{
background-color: #154734;
width: 50%;
height: 600px;

}
.divGreyc{
background-color: #FEF8E1;
width: 50%;
height: 300px;
}

.allProductc{
background-color: white;
width: 60%;
justify-content: center;
height: 500px;
/* text-align: center; */
align-items: center;
appearance: none;
background-color: white;
border-radius: 0.25rem;
box-shadow: 0 0px 40px -14px rgba(0, 0, 0, 0.25);
border-color: white;

}
.itemallProducts{
display: flex;

position: absolute;
z-index: 10;
justify-content: center;
margin-left: 15%;
height: 400px;
width: 80%;
margin-top: 4%;

}
.shiftWhitr{
margin-left: 30%;
margin-top: 10%;
}
.inputContolD input{
padding: 15px;
 width: 200px; 

margin-left: 10px;
margin-top: 40px;
align-items: center;
appearance: none;
background-color: white;
border-radius: 0.25rem;
box-shadow: 0 0px 40px -14px rgba(0, 0, 0, 0.25);
border-color: white;
cursor: pointer;
display: inline-flex;


}
.jamesSearchB{
font-size: 60px;
font-weight: bold;
color: #154734;
}
.jamesSearchBar{
font-size: 60px;
font-weight: bold;
color: #007749;

}
.ImageHover{
position: absolute;
margin-left: -60%;
margin-top: 3.5%;
} 
.textdown-image{
background-color: #007749;
top: 0 !important;
position: absolute;
width: 100%;
 margin-top: 100%;
}
.borderyShows{
border: 1px solid;
border-color: white;
width: 20px;
margin-top: 5px;

} 
.borderyShowsdiv{
margin-left: 20px;
} 
.knowusDiscover{
background-color: #F9F9F9;
padding: 20px;
display: flex;
gap: 10px;
margin-left: 5%;
margin-right: 5%;
}
.discoverTextFirst{
font-size: 30px;
font-weight: bold;
color: #2BBCF1;
}
.discoverTextFirstword{
 font-size: 50px;
 font-weight: bold;
 color: #FEF8E1;
}
.down_divMark{
border: 1px solid;
border-color: #2BBCF1;
width: 30px;
height: 0px;
margin-top: 5px;
}
.knowusDiV{
background-color: #3A6350;
position: relative;
height: 50vh;
}
/* .individual_cardS{
background-image: url(../Images/bag3.jpg);
width: 300px;
height: 350px;

} */

.main_conT_div{
  display: flex;
  width: 100%;
  /* background-color: #000000; */
 
  justify-content: center !important;
  
}
.side_firstFF_pic{
  width : 50%;
  /* background-color: #95cdb8; */
}
.firstFF_pic{
 width: 50%;
 
}
.gen_conT_div{
  margin-top: 2%;
}
.side_firstFF_text{
  width: 50%;

  /* background-color: #007749; */
}



.linneCard_shp{
border: 1px solid;
border-color: white;
width: 30px;
height: 0px;
}
.coverWith-back{
background-color: rgba(0, 0, 0, 0.76);
height: 350px;
color: white;

}
.coverWith-backText{
padding-top: 15%;

}
.shift_Icon{
/* padding: 10px; */
text-align: end;
margin-left: 3%;
padding-top: 20px;
}
.shift_Iconi{
background-color: #3A6350;
padding: 10px;
width: 100px;
padding: 15px;
z-index: 20;


}
.coverWith-backTextonly{
padding: 30px;
}
.general_cards{
 /* position: absolute; */
display: flex;
/* margin-left: 25%; */
margin-top: 2%;
justify-content: center;

}
.shift_Iconi:hover{
 background-color: white;
 color: #154734;
}
.SideTexti{
font-size: 30px;
font-weight: bold;
color:  #154734;
}
.allHomeC{
background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2023/12/Jameson-January-Homepage-City-Guides-Desktop.jpg");
height: 90vh;
display: flex;
/* justify-content: center; */
/* text-align: center; */
width: 100%;
/* overflow-y: hidden; */
background-size: cover;
}
.allAboutContain{
background: #FAF3D5;
top: 0 !important;
padding: 0;
}
.firstDiveOntracn{
 /* background-color: #154734ea;  */
position: absolute;
margin-top: 10%;
margin-left: 12%;
/* padding: 20px; */
color: white;
z-index: 50;
width: 500px;
}
.HtfirstText{
font-size: 50px;
font-weight: bold;
/* background-color: #FEF8E1; */
padding: 10px;
}
.ptagFirsttext{
font-size: 30px;
/* background-color: #FEF8E1; */
padding: 10px;
}
/* .whatsNew{
position: absolute;
margin-top: 2%;
}
   */
   .btnUpSroll{
    padding: 1px;
    color: #FEF8E1;
   }
   .btnUpSroll:hover{
    color: white !important;
   }

#text_changeUp:hover{
color: #154734;
}




.mdl-card--horizontal {
flex-direction: column;
height: 1vh; /* 1 */
padding-left: 150px;
width: 100%;

.mdl-card__media {
  left: 0;
  position: absolute;
  width: 150px;
}

.mdl-card__supporting-text {
  flex: 1 1 auto;
  width: auto;
}
}

.mdl-card--horizontal-2 {
flex-direction: row;
flex-wrap: wrap;
min-height: 0px;

.mdl-card__title {
  align-items: flex-start;
  flex-direction: column;
  flex: 1 auto;
  float: left;
}
.mdl-card__title-text {
  align-self: flex-start;
}
.mdl-card__media {
  flex: 0 auto; 
  float: right;
  height: 112px;
  margin: 16px 16px 0 0;
  width: 112px;
}
.mdl-card__actions {
  clear: both;
  flex: 1 auto; 
}
}


.backIMage_d{
background-image: url("https://spencers.in/media/catalog/product/1/1/1193105_1.jpg");
width: 70%;
height: 50vh;
background-size: cover;
 margin: 1rem auto; 
}

.backIMage_dNext{
background-image: url("https://assets.gqindia.com/photos/64ba3d5c8f837ce57057b1e9/4:3/w_1440,h_1080,c_limit/German-beers.jpg");
width: 70%;
height: 50vh;
background-size: cover;
 margin: 1rem auto; 
}

.downDiv_Covert{

justify-content: center;

padding-top: 10%;
}
.letterNewsy{
color: white;
margin-top: 2%;
}
.front_DivTextINews{
display: flex;
width: 100%;
height: 50vh;
background-color: #154734d7;
justify-content: space-around;

}
.TextExclusive{
font-size: 39px;
width: 400px;
font-weight: bold;
margin-top: 10%;
}
.TextExclusive1{
font-size: 39px;
width: 300px;
font-weight: bold;
margin-top: 10%;
margin-left: 5%;
}
.cover_exClusiveImg{
margin-top: -3%;
/* width: 200px; */
}
.cover_exClusiveImg2{
margin-top: 4%;
/* width: 200px; */
}
.exASpan{
color: antiquewhite;
}
.imageNewsBoot{
width: 100%;
height: 100px;
}
.modalAlllletter{
background-color: #154734 !important;
}
.singBtnNews{
width: 80%;
padding:5px;
border: #007749 2px;
border-radius: 5px;
}
.singBtnNews:hover{
  background-color: #67a387 ;
  color: white;
}

.singBtnNewsDiv{
background-color: #154734 !important;
justify-content: center;
text-align: center;
padding: 5px;
}
.showmeFirstDiv{
background-image: url("https://www.jamesonwhiskey.com/wp-content/uploads/2023/01/Landing-Page-Cover-Image-Desktop-Gradient-scaled-aspect-ratio-2.67-1-1920x720.jpg");
width: 100%;
height: 80vh;
color: white;
}
.nest_coveAll{
width: 500px;
padding-top: 5%;

margin-left: 18%;
}

.nest_coveAll_ProDetails{
width: 500px;
padding-top: 10%;
  color: white;
 margin-left: 18%; 
}


.nest_coveAll1{
display: flex;
gap: 20px;
}
/* .nest_coveAll1line{
padding-top: 7%;
} */
.nest_coveAll1h1{
width: 300px;
font-size: 60px;
font-weight: bold;
}
.nest_coveAll1h1product{
width: 600px;
font-size: 60px;
font-weight: bold;
}
.nest_coveAll1h1product_prodetails{
width: 400px;
font-size: 40px;
font-weight: bold;
}
.pText_next{
font-size: 20px;
}
.pText_next_details{
font-size: 18px;
}
.nest_coveAll1line{
border: 1px solid;
border-color: white;
width: 50px;
height: 0px !important;
margin-top: 10px;
}




.image-area
{
/* top: 50%; */
/* left: 50%; */
/* transform: translate(-50%, -50%);
position: absolute; */
}

.img-wrapper
{
width: 400px;
height: 400px;
position: relative;
overflow: hidden;
}

.img-wrapper:before
{
content: '';
 position: absolute; 
top: 0;
left: 180%;
height: 100%;
width: 100%;
background: rgba(255, 255, 255, 0.055);
z-index: 1;
transform: skew(45deg);
transition: .5s;
}

.img-wrapper:hover:before
{
left: -180%;
}

.img-wrapper img
{
height: 400px;
width: 400px;
filter: grayscale(100%);
transition: 2s;
}
.img-wrapper:hover img
{
filter: grayscale(0%);
transform: scale(1.1);
}

.img-wrapper h2
{
background: #67a387;
font-family: Poppins;
color: #fff;
text-align: center;
text-transform: uppercase;
margin: 0;
padding: 10px 0;
position: absolute;
bottom: 0;
width: 100%;
transform: perspective(400px) rotateY(90deg);
transform-origin: right;
transition: 1s;
}

.img-wrapper:hover h2
{
transform: perspective(400px) rotateY(0deg);
}

.img-wrapper ul
{
position: absolute;
top: 0;
left: 0;
margin: 0;
padding: 0;
list-style: none;
background: rgba(255,255,255,0);
}

.img-wrapper ul li
{
background: #3333331c;
height: 40px;
width: 40px;
text-align: center;
line-height: 40px;
transform: perspective(800px) rotateY(90deg);
transition: .5s;
transform-origin: left;
}

.img-wrapper:hover ul li
{
transform: perspective(800px) rotateY(0deg);
}

.img-wrapper:hover ul li:nth-child(1)
{
transition-delay: .2s;
}

.img-wrapper:hover ul li:nth-child(2)
{
transition-delay: .6s;
}

.img-wrapper:hover ul li:nth-child(3)
{
transition-delay: .8s;
}

.img-wrapper:hover ul li:nth-child(4)
{
transition-delay: 1s;
}

.img-wrapper ul li a
{
color: tomato;
background: rgba(255,255,255,0);
}

.img-wrapper ul li i
{
color: tomato;
background: rgba(255,255,255,0);
}

.img-wrapper ul li i:hover
{
color: #fff;
background: rgba(255,255,255,0);
}
.image-area_div{
background-color: #007749;

}
.image-area_divAll{
/* display: grid;
grid-template-columns: auto auto auto; */
display: flex;

gap: 5px;
justify-content: center;
padding: 10px;
}
.TextfrontText{
position: absolute;
color: white;
z-index: 20;
text-align: center;
padding: 20px;
font-size: 30px;
font-weight: bold;
}

.all_products_Mark{
background-color: #F8EFCB;
padding-top: 5%;
}
.lineProductSides{
 display: flex;
 margin-left: 14.5%;
 gap: 20px;
}
.lineProductSidesLine{
border: 1px solid;
border-color: #3A6350;
width: 50px;
height: 0px !important;
margin-top: 10px;
}
.lineProductSidestext{
color: #3A6350;
}
.lineProductSidesh1{
margin-left: 18%;
font-size: 60px;
font-weight: bold;
color: #154734;
}
.lineProductSides_textAll{

justify-content: center;

padding-left: 20%;
padding-right: 20%;
}
.lineProductSides_text{
width: 60%;

}

/* card start  */
.card_Cards{
position: relative;
width: 320px;
height: 480px;
background: #154734;
/* border-radius: 20px; */
overflow: hidden;
cursor: pointer;


}
.card_Cards::before {
content: "";
position: absolute;
top: -50%;
width: 100%;
height: 100%;
background: #F8EFCB;
transform: skewY(345deg);
transition: 0.5s;
}
.card_Cards:hover::before {
top: -70%;
transform: skewY(390deg);
}
.card_Cards::after {
content: "JAMESON";
position: absolute;
bottom: 0;
left: 0;
font-weight: 600;
font-size: 6em;
color: rgba(0, 0, 0, 0.1);
}
.card_Cards .imgBox {
position: relative;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding-top: 20px;
z-index: 1;
}
.card_Cards .contentBox {
position: relative;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: 2;
}
.card_Cards .contentBox h3 {
font-size: 18px;
color: white;
font-weight: 500;
text-transform: uppercase;
letter-spacing: 1px;
}
.card_Cards .contentBox .price {
font-size: 24px;
color: white;
font-weight: 700;
letter-spacing: 1px;
}
.card_Cards.contentBox .buy {
position: relative;
top: 100px;
opacity: 0;
padding: 10px 30px;
margin-top: 15px;
color: #000000;
text-decoration: none;
background: deepskyblue;
border-radius: 30px;
text-transform: uppercase;
letter-spacing: 1px;
transition: 0.5s;
}
.card_Cards:hover .contentBox .buy {
top: 0;
opacity: 1;
}
.mouse {
height: 300px;
width: auto;
}
.card_Cards_Div{
display: flex;
gap: 20px;
padding: 10px;

}
#all_products_id{
background-color: #FAF3D5;
}
.ourPro_all_section{
background-color: #FAF3D5;
}
.product_detailssDiv_all{
display: flex;
/* padding-top: 50px; */
height: 80vh;
}
.product_detailssDiv_imgside{
width: 50%;
background: #3A6350;
}
.product_detailssDiv_textside{
width: 50%;
background-color: #154734;
padding-top: 2%;
}
.product_detailssDiv_img{
width: 100%;
height: 80vh;
}
.product_details_Sectional{
background-color: #FEF8E1;
}

.productDetail_boxDiv{

justify-content: center;
display: flex;
gap: 2%;
padding: 30px;
}
.productDetail_box{
 border: #000000 solid 2px;
 width: 350px;
 padding: 30px;
 height: 300px;
}
.productDetail_boxupText{
color: #007749;   
}
.productDetail_boxline{
border: solid 1px;
width: 30px;
height: 0;
}
.productDetail_boxupTextbig{
margin-top: 5%;

}
.teRadio_set{
  display: flex;
  justify-content: space-between;
}
.mobile_Show{
  display: none;
}
.mobile_noneShow{
  display: block;
}



@media(max-width:1700px){
.news_letterInnerDiv{
  margin-top: 10px !important;
}
.general_cards{
  margin-top: 0% !important;
}
.individual_cardS{
 
  width: 300px;
  height: 350px;
 
}
.letterNewsy{

}
.downDiv_Covert{

margin-top: 15%;
}
.cover_exClusiveImg{
  margin-top: 4%;
  /* width: 200px; */
}

} 
@media(max-width:1400px){
.backIMage_d{
  margin-top:5% ;
}
.product_detailssDiv_textside{
  
}


}

@media(max-width: 640px){
  .mobile_noneShow{
    display: none;
  }
  .mobile_Show{
    display: block;
  }
  .setHeadProduct{
    font-size: 17px;
  }
  .setDivFOrm{
    display: grid;
    gap: 2%;
  }
  .teRadio_set{
    display: grid !important;
    
  }

  .kktest_insideDivIN{
    width: 330px;
    /* margin: 0 auto;
    justify-content: center;
    text-align: center; */
    background-color: rgba(0, 0, 0, 0.042);
    /* color: white; */
    padding: 50px;
    font-weight: bold;
   
  }
  .row_doWn{
 
    width: 100%;
    display: grid;
  }
 

  .kktest_insideDivIN h2{
    font-weight: bold;
    font-size: 30px;
   
    /* color: linear-gradient(to right, #5756A2, #8DCFE1) !important; */
  }
  .toNEtwork{
    font-size: 20px;
    color: #1e5799;
  }
  
}



  














