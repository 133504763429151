

.control_Set_formUp{
    width: 100%;
    height: 50vh;
    background-color: #FAFAFA;
   
    position: absolute;
}
.corpForDiv{
    padding-left: 20%;
    padding-right: 20%;
    position: relative;
    padding-top: 4% !important;
}

@media( max-width: 993px){

    .corpForDiv{
        padding-left: 0%;
        padding-right: 0%;
        position: relative;
        padding-top: 4% !important;
    }
}

@media( max-width: 640px){
    
}