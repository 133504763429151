.parentCartDiv{
    border: 2px solid #2BBCF1;
    width: 100%;
    border-radius: 10px;
}

.deleteProduct{
    color: red;
    cursor: pointer;
}

.increaseAndDecreass{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10;
}

.tractminusCart {
    background-color: #030334;
    margin-right: 2%;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    color: white;

}

.trackplusCart {
    background-color: #030334;
    margin-left: 2%;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    color: white;

}

.incrementQtyNum {
    font-weight: bold;
    margin-top: 1%;
    color: black;
    padding: 0px 5px 0px 5px;
}