.transactionHeader {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
}

.transactionHeader {
    /* background-color: antiquewhite; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.transmangeSubActionHeader select {}

.transactionHeader .componentheader .pageTitle {
    color: #526d82;
    font-size: 1.5rem;
    font-weight: bold;
}

.transactionHeader .componentheader {
    color: #526d82;
    /* font-size: 1.5rem; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    /* background-color: #2a4955; */
    width: 50%;
    flex-wrap: wrap;
}

.transactionHeader .componentheader select {
    height: 35px;
    width: 250px;
    border-radius: 5px;
    font-size: 1em;
    padding: 8px 12px;
    border: 1px solid #dde6ed;
    color: #526d82;
    font-weight: bold;
    /* background-color: #1A9AC9; */
}

.transactionHeader select {
    height: 35px;
    width: 250px;
    border-radius: 2px;
    font-size: 1em;
    padding: 8px 12px;
    border: 1px solid #dde6ed;
    color: #526d82;
    font-weight: bold;


}

.transactionHeader select option {
    background-color: #f0f0f0;

    font-weight: bold;

}

.historyOptionDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* background-color: #1A9AC9; */
    gap: 10px;
    width: 45%;
}

.historyDateDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background-color: bisque; */
}

.transactionListDiv {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 550px;
    overflow-y: scroll;
}

.transMainList {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
}

.transactionListDet {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #526d82;

}

.productPurchaseName {
    font-size: 1.1rem;
}

.productImg {
    width: 50px;
    background: #dde6ed;
    border-radius: 50px;
}

.transMainList:hover {
    background: #dde6ed;
    border-radius: 10px;
    cursor: pointer;
}

.transactionAction {
    font-weight: 700;
    font-size: 1em;
    background-color: #1A9AC9;
    color: white;
    width: 80%;
    text-align: center;
    padding: 7px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 800px) {
    .transactionAction {
        width: 100%;
    }

    .transactionHeader .componentheader {
        gap: 10px;
        width: 100%;
        margin-bottom: 2%;
        width: 80%;

    }



    .transactionHeader .componentheader .pageTitle {

        font-size: 1rem;
    }

    .historyDateDiv {
        width: 48%;
    }

    .historyDatepick2 {
        width: 100%;
    }

    .historyOptionDiv {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        /* background-color: #1A9AC9; */

        width: 100%;
    }
}