.error{
    color: red;
}

.errorMessage{
    font-size: 1.3em;
    font-weight: 700;
}

.inValidNumError{
    text-transform: capitalize;
    color: red;
    padding: 0px 4px 0px 4px;
    background-color: rgb(255, 255, 255);
}

.rechargeIcon{
    font-size: 1.4em;
}

/* .background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */