@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentVitelService {
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.parentVitelService::before {
  content: '';
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 3%;
  /* background-color: #0CA8E5; */
  background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
  /* background: linear-gradient(to right, #FFFFFF, #FFFFFF); */
  border-bottom-left-radius: 500px;
  border-bottom-right-radius: 500px;
}

.parentVitelService:after {
  content: '';
  position: absolute;
  top: 95%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5%;
  background: url('../../images/getSecuredBck.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  border-top-left-radius: 300px;
  border-top-right-radius: 300px;
}

.userProductDiv {
  margin-top: 5%;
  color: black;
}

.userProductHr {
  border: 3px solid #1b1b1b;
  width: 20%;
  margin-left: 0%;
}

/* .serviceCardContainer {
    width: 90%;
    margin-bottom: 3%;
    padding: 30px 15px 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    background-color: antiquewhite;
} */

.serviceCardContainer {
  width: 90%;
  margin-bottom: 3%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  /* padding-right: 15px; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  /* 5 equal columns */
  /* grid-gap: 10px; */
  /* space between the grid items */
  /* grid-auto-rows: 200px; */
  /* uniform row height */
  align-items: center;
  justify-content: center;
  /* background-color: #0F0C7C; */
}

.parentServiceCard {
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* height: 446px; */
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c0c0c0;
  text-decoration: none;
}

.parentServiceCard:hover {
  text-decoration: none;
  color: #1b1b1b;
}

.parentServiceCard2 {
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* height: 446px; */
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c0c0c0;
}

.parentServiceCard2 img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.upperServiceCard {
  width: 100%;
  border-radius: 10px;
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serviceCardImg {
  width: 65%;
  height: 140px;
  border-radius: 10px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.serviceCardImg2 {
  width: 100%;
  height: 140px;
  border-radius: 10px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.serviceCardImg2 img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.serviceCardImg img {
  width: 40%;
  height: 55%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.serviceNameDiv {
  /* background-color: rgb(209, 116, 116); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px 15px 20px;
  border-bottom: 1px solid #e8e8e8;
}

.categoryname {
  font-size: 0.8em;
  font-weight: 300;
  /* background-color: beige; */
}

.serviceName {
  font-size: 1.2em;
  font-weight: 500;
  /* background-color: rgb(43, 68, 90); */
}

.lowerServiceFooter {
  padding: 15px;
  background: linear-gradient(to left, rgba(33, 150, 243, 1), rgba(33, 150, 243, 1));
  /* background: linear-gradient(to left, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */
  /* margin-top: 10%; */
  /* background-color: rgba(15, 12, 124, 0.7); */
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.lowerServiceCard {
  padding: 10px 15px 10px 20px;
  font-size: 0.8em;
  /* background-color: antiquewhite; */
  height: 100%;
  /* height: 78px;
    overflow-y: scroll; */
}

.servicePriceDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.onlyDiv {
  font-size: 0.8em;
  font-weight: 300;
}

.servicePriceDiv {
  font-size: 1.2em;
  font-weight: 400;
}

.servicePriceIcon {
  font-size: 1.5em;
}

.showMoreLesBtn {
  border: none;
  color: red;
  background: none;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .serviceCardContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) {
  .serviceCardContainer {
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns on medium screens */
    /* grid-auto-rows: 180px; */
    /* smaller row height for medium screens */
  }
}

@media (max-width: 768px) {
  .serviceCardContainer {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns on smaller screens */
    /* grid-auto-rows: 160px; */
    /* smaller row height */
  }

  .userProductHr {
    border: 1px solid #1b1b1b;
    width: 100%;
    margin-left: 0%;
  }

  .parentVitelService::before {
    height: 0.5%;
  }

  .parentVitelService:after {
    top: 99.5%;
    height: 0.5%;
  }
}

@media (max-width: 480px) {
  .serviceCardContainer {
    grid-template-columns: 1fr;
    /* 1 column on mobile screens */
    /* grid-auto-rows: 150px; */
    /* smaller row height */
  }
}
