.parentAirtimeTopup {
    height: 80vh;
    margin-top: 5%;
    display: flex;
    margin-bottom: 10%;
    align-items: center;
    justify-content: center;
    /* background-color: antiquewhite; */
   
}
#staticBackdropLabel{
    color: green;
}
.setDivSENdtop{
    /* background-color: aqua; */
    display: flex;
    justify-content: space-around;
}
.rechargewithVite{
    background-color:#12258F;
}
.insTred{
    color: red;
}

.secondLayerTopUp {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 20px;
    gap: 20px;
    width: 80%;
    height: 100%;
  
   
    /* background-color: rgb(48, 48, 102); */
}

.topupIMGDiv {
    width: 25%;
    height: 75%;
    border-radius: 20px;
    /* background-color: aliceblue; */
}

.topupIMGDiv img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.topUpMainDiv {
    height: 75%;
    width: 60%;
    padding: 30px;
    background-color: #FFFF;
    box-shadow: darkgray 1px 1px 5px 1px;
    border-radius: 20px;
}
.payNowDivBTn{
    justify-content: end;
    text-align: end;
    padding: 8px;
}

.payNowDiv {
    /* background-color: aqua; */
    height: 20%;
    /* margin-top: 10%; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.topUpMainDiv h3 {
    margin-bottom: 3%;
    background-color: #000000;
    color: white;
    text-align: center;
}

@media screen and (max-width: 800px) {

    .parentAirtimeTopup {
        margin-bottom: 30%;
    }

    .topupIMGDiv {
        display: none;
    }

    .topUpMainDiv {
        width: 100%;
        margin-top: 5% !important;
    }

    .secondLayerTopUp {
        width: 100%;
        padding: 10px;
    }

    .topUpMainDiv h3 {
        font-size: 1.5em;
        text-align: center;
        /* background-color: aqua;
        color: white; */
    }
    .getStartedBtn{
      
    }
    .payNowDiv{
        height: 15% !important;
    }
    .setDivSENdtop{
        gap: 5%;
        margin-top: 2%;
    }
}