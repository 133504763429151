.parentPartnerForm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  /* margin-top: 5%;
    margin-bottom: 10%; */
}

.partnerFormdivmain {
  width: 80%;
  /* border: 2px solid gray; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.partnerFormdiv {
  width: 55%;
  /* background-color: antiquewhite; */
  /* margin-top: 4%; */
  /* padding: 2%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partnerFormdivimg {
  width: 45%;
}

.partnerFormdivimg img {
  width: 100%;
}

.partnerFormdiv h3 {
  /* background-color: aqua; */
  width: 90%;
  padding-bottom: 0%;
  font-size: 2.5em;
  font-weight: 800;
}

.parentpartnerInputdiv {
  background-color: rgb(255, 255, 255);
  width: 90%;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 20px;
  padding: 15px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.partnerInputdivshare {
  /* background-color: #5756A2; */
  width: 100%;
}

.inputOnlyDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
}

.partnerInputdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1%;
  /* background-color: #2BBCF1; */
  width: 100%;
}

.partnerInputdiv select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
}

.partnerInputdiv input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
  /* color: white; */
}

.partnerInputdiv textarea {
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
}

.partnerbtndiv {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  /* background-color: #5756A2; */
  width: 100%;
}

.partnerbtndiv .sendRequestBtn {
  padding: 5px 10px 5px 10px;
  /* margin-top: 10%; */
  /* margin-left: 5%; */
  font-size: 1.3em;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(38, 57, 77) 0px 10px 10px -10px;
  background: linear-gradient(to right, #5756a2, #2bbcf1);
  background-size: 200% 200%;
  color: white;
  border: none;
  animation: gradientAnimation 10s ease-in-out infinite;
  font-family: 'Poppins', sans-serif;

  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.backpartnerbtn {
  padding: 5px 10px 5px 10px;
  /* margin-top: 10%; */
  /* margin-left: 5%; */
  font-size: 1.3em;
  border-radius: 10px;
  cursor: pointer;
  background: transparent;
  /* box-shadow: rgb(38, 57, 77) 0px 10px 10px -10px;
    background: linear-gradient(to right, #000000, #6a9eb1); */
  background-size: 200% 200%;
  color: black;
  border: 2px solid #6a9eb1;
  animation: gradientAnimation 10s ease-in-out infinite;
  font-family: 'Poppins', sans-serif;

  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.backpartnerbtn:hover {
  color: white;
  box-shadow: rgb(38, 57, 77) 0px 10px 10px -10px;
  background: linear-gradient(to right, #000000, #6a9eb1);
}

.error {
  color: red;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
}

.closeModal {
  padding: 5px 15px 5px 15px;
  /* margin-top: 10%; */
  /* margin-left: 5%; */
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgb(38, 57, 77) 0px 10px 10px -10px;
  background: linear-gradient(to right, #5756a2, #2bbcf1);
  background-size: 200% 200%;
  color: white;
  border: none;
  animation: gradientAnimation 10s ease-in-out infinite;
  font-family: 'Poppins', sans-serif;

  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal-body {
  font-family: 'Poppins', sans-serif;
}

.formDetails {
  background: #BCBCBC;
  width: 100%;
  padding: 10px;
  color: white;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
}

.successMessage {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconSpan {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: bolder;
  color: green;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
  border-radius: 10px;
}

.successMessage p {
  width: 60%;

}

@media screen and (max-width: 1030px) {
  .partnerFormdivmain {
    flex-direction: column;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .partnerFormdiv {
    /* background-color: aqua; */
    width: 100%;
  }

  .parentpartnerInputdiv {
    width: 100%;
  }

  .partnerFormdivimg {
    display: none;
  }

  .inputOnlyDiv {
    flex-direction: column;
  }

  .partnerFormdiv h3 {
    width: 100%;
    font-size: 1.7em;
  }
}