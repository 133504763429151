@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');



.parentLoginContainer {
    background-color: #c9d6ff;
    background: linear-gradient(to right, #e2e2e2, #c9d6ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

.container p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.container span {
    font-size: 12px;
}

.container a {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

/* .container button {
    background-color: #512da8;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
} */

.signInBtn {
    background-color: #512da8;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.verifyBtn333 {
    background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
    /* background-color: #512da8; */
}

.verifyBtn333Disable {
    background-color: gray;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.homebtn {
    background-color: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    color: #fff;
    border: 1px solid transparent;
    padding: 10px 45px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    font-size: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-color: #fff;
    /* color: black; */
}

.loginLogo {
    width: 35%;
    /* border-radius: 50%; */
}

.loginLogo img {
    width: 100%;
    border-radius: 30px;
}

.container button.hidden {
    background-color: transparent;
    border-color: #fff;
}

.buttonDiv {
    /* background-color: #ccc; */
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.container .parentSignInDiv {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.container .emailInput {
    background-color: #eee;
    color: black;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;

}

.sign-in {
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.active .sign-in {
    transform: translateX(100%);
}

.sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.social-icons {
    margin: 2px 0 10px 0;
}

.social-icons a {
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggle-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.container.active .toggle-container {
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle {
    background-color: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    height: 100%;
    background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.resendOtpBtn {
    background-color: none;
    padding: 0;
    margin: 0;
    border: none;
    color: black;
    background-color: transparent;
    cursor: pointer;
}

.container.active .toggle {
    transform: translateX(50%);
}

.toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-panel-para {
    /* background-color: red; */
    width: 80%;
}

.toggle-left {
    transform: translateX(-200%);
}

.container.active .toggle-left {
    transform: translateX(0);
}

.toggle-right {
    right: 0;
    transform: translateX(0);
}

.container.active .toggle-right {
    transform: translateX(200%);
}

.otp-input-card2 {
    margin: 20px 0;
    display: grid;
    gap: 20px;
    /* width: 100%; */
    /* background-color: #5542B0; */
    justify-content: center;
    grid-template-columns: repeat(6, auto);
}

.otp-input-card2 .otpinput {
    width: 100%;
    height: 30px;
    font-size: 35px;
    text-align: center;

    border-radius: 10px;
    border: 1px solid black;
    font-size: 1em;
    background-color: rgb(255, 255, 255);
    color: #000000;
}

.otp-input-card2 .otpinput:focus {
    outline: 2px solid #5542B0;
    border-color: transparent;
    color: #000000;
}

.parentinputForm {
    /* background-color: #c9d6ff; */

    width: 100%;
}

@media screen and (max-width: 768px) {
    .toggle-container {
        display: none;
    }

    .sign-in {
        width: 100%;
        background-color: #000000;
    }

    .parentLoginContainer {
        padding: 0px 30px 0px 30px;
    }
}

@media screen and (max-width: 360px) {
    .toggle-container {
        display: none;
    }

    .sign-in {
        width: 100%;
        background-color: #000000;
    }

    .parentLoginContainer {
        padding: 0px 15px 0px 15px;
    }
}