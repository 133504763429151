@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.parentContainer {
  /* background-color: black; */
  /* padding: 1% 5% 1% 5%; */
  color: white;
  /* opacity: 0.5; */
  background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
  /* position: relative;
    top: 0px; */
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 6.5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Arial Nova, and Gill Sans;
}

.lowerNavEle {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
  /* position: absolute; */
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-radius: 5px; */
}

.navItemDiv {
  width: 80%;
  /* background-color: #65bc54; */
  /* background-color: rgb(43, 82, 116); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loginlink {
  list-style: none;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 600;
  font-size: 1em;
  /* font-family: 'Montserrat', sans-serif; */
}

.accountIcon {
  font-size: 1.3em;
}

.Loginlink:hover {
  color: white;
  list-style: none;
  text-decoration: none;
}

.Loginlink:focus,
.Loginlink:active {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.companyLogoDiv {
  width: 5%;
  height: 100%;
  cursor: pointer;
  /* background-color: rebeccapurple; */
}

.companyLogoDiv img {
  width: 100%;
  height: 100%;
}

.lowerNavEle ul {
  display: flex;
  width: 85%;
  color: whitesmoke;
  /* background-color: azure; */
  list-style: none;
}

.lowerNavEle ul li {
  /* background-color: black; */
  margin-right: 1%;
  padding: 2px 8px 2px 8px;
  cursor: pointer;
}

.search {
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.myAccountDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.myAccounIcon {
  font-size: 1.3em;
  font-weight: 600;
}

.supportDivNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  /* background-color: aqua; */
  width: 30%;
}

.whatsappIcon {
  font-size: 2em;
  color: #65bc54;
}

.supportNum {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: 900;
}

.justNumber {
  /* font-size: 1.2em; */
  font-weight: 800;
}

.harmburgerDiv {
  display: none;
}

.MobileNavItemDiv {
  display: none;
}

.navData {
  /* background-color: #65BC54; */
  width: 100%;
}

.navRight {
  width: 80px;
  font-size: 10px;
}

@media screen and (max-width: 1615px) {
  .companyLogoDiv {
    width: 9%;
  }

  .navItemDiv {
    width: 95%;
    /* background-color: antiquewhite; */
  }
}

@media screen and (max-width: 1518px) {
  .companyLogoDiv {
    width: 6%;
    height: 100%;
  }

  .navItemDiv {
    width: 95%;
    /* background-color: antiquewhite; */
  }
}

@media screen and (max-width: 1077px) {
  .companyLogoDiv {
    width: 10%;
  }

  .navItemDiv {
    width: 95%;
    /* background-color: antiquewhite; */
  }

  .navItemDiv .search {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .lowerNavEle {
    width: 95%;
  }

  .lowerNavEle ul {
    display: flex;
    width: 95%;
    color: whitesmoke;
  }

  .navItemDiv {
    width: 95%;
    /* background-color: antiquewhite; */
  }

  .navItemDiv .search {
    width: 50%;
    justify-content: flex-start;
  }

  .companyLogoDiv {
    width: 10%;
  }
}

@media screen and (max-width: 850px) {
  .parentContainer {}

  .navItemDiv {
    display: none;
  }

  .companyLogoDiv {
    width: 18%;
  }

  
  .MobileNavItemDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100 !important;
    background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
  }
  .harmburgerDiv {
    display: block;
    z-index: 0 !important;
    font-size: 2em;
  }


  .MobileNavItemDiv ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    gap: 20px;
    margin: 0;
    width: 100%;
    /* background-color: #65BC54; */
  }

  .MobileNavItemDiv .search {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 2%;
    /* background-color: #65BC54; */
  }
  .supportDivNav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    /* background-color: aqua; */
    width: 50%;
    margin-left: -20% !important;
  }
}