.menuContain {
    width: 18%;
    /* background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: center; */
    gap: 2.3rem;
    height: 100vh;
    /* position: relative; */
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 2.3rem;
    height: 80vh;
    width: 100%;
    background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));

}



.logo {
    /* text-align: center; */
    padding: 20px;
    /* color: #fff;
    
    
    gap: 20px; */
    height: 15%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}

.logo img {
    height: 100%;
    width: 100%;
    border-radius: 30px;
}

.logo-icon {
    font-size: 1.4rem;
}

.menu-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menu-list .item {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 600;
    padding: 10px;
    /* background-color: antiquewhite; */
    border-radius: 10px;
    transition: 0.3s ease-in-out;
    color: #fff;
    width: 100%;
}

.menu-list .item:hover,
.active {
    background-color: white;
    color: #27374d !important;
}

.icon {
    font-size: 1.4rem;
}

.logoutItem {
    color: aliceblue;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 600;
    padding: 10px;
    /* background-color: antiquewhite; */
    border-radius: 10px;
    transition: 0.3s ease-in-out;
    width: 100%;
}

.menu-list .logoutItem:hover,
.active {
    background-color: white;
    color: #27374d !important;
}

.linkStyle {
    text-decoration: none;

}



.hamburgerDivdashbod {
    display: none;
}

.expandHamburgerdiv {
    display: none;
}



.menu.show-menu {
    display: flex;
    flex-direction: column;
    gap: 2.3rem;
    height: 100vh;
    width: 70%;
    background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    position: absolute;
    left: -2%;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
}

.show-menu_content {
    display: none;
}

.mobileprofilediv {
    display: none;
}

@media screen and (max-width: 800px) {
    .menuContain {
        /* height: 150vh !important; */
    }

    .menu {
        width: 0%;
        transition: transform 0.3s ease-in-out;
    }

    .menu-list {
        transform: translateX(0);
        /* background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */
        /* Show the menu */
    }

    .hamburgerDivdashbod {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15%;
        height: 2%;
        position: absolute;
        right: 20%;
        left: 3%;
        font-size: 2em;
        /* background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */
        color: #000;
        padding: 0.5em;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1000;
        cursor: pointer;
    }

    .expandHamburgerdiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15%;
        height: 2%;
        position: absolute;
        right: 20%;
        /* left: 0; */
        font-size: 2em;
        /* background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */
        color: #000;
        padding: 0.5em;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1000;
        cursor: pointer;

    }

    .mobileprofilediv {
        display: flex;
    }

    .menu-list {
        display: none;
    }

    .show-menu_content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: absolute;
        /* background-color: antiquewhite; */

    }

    .show-menu_content .item {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 1.1rem;
        text-decoration: none;
        font-weight: 600;
        padding: 10px;
        border-radius: 10px;
        transition: 0.3s ease-in-out;
        color: #fff;
        width: 100%;
    }

    .show-menu_content .item:hover,
    .active {
        background-color: white;
        color: #27374d !important;
    }

    .show-menu_content .logoutItem:hover,
    .active {
        background-color: white;
        color: #27374d !important;
    }
}