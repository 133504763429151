.supportParentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    /* background-color: #2bbcf1; */
}

.mainSupportdiv {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #2bbcf1; */
}

.supportDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;

}

.supportdivtext {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.supportdivtext h2 {
    font-size: 4em;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.supportimgDiv {
    width: 50%;
}

.supportimgDiv img {
    width: 100%;
}

.supportdivtext p {
    width: 69%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
    /* font-size: 1.2em; */
    font-weight: 500;
    color: #7D7D7D;
}

.parentsupportTrending {
    width: 100%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.parentsupportTrending h3 {
    background-color: #F4F5FA;
    width: 75%;
    padding: 30px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}

.supportTrending {
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.FaqDiv {
    width: 55%;
}

.FaqDivimgDiv {
    width: 40%;
}

.FaqDivimgDiv img {
    width: 100%;
}

.supportwhatsappDiv {
    box-shadow: rgb(38, 57, 77) 0px 10px 10px -10px;
    background: linear-gradient(to right, #5756a2, #2bbcf1);
    color: white;
    padding: 5px;
    margin-top: 1%;
    margin-bottom: 1%;
    cursor: pointer;
    text-decoration: none;
}
.supportwhatsappDiv:hover{
    color: white;
}

@media screen and (max-width: 800px) {
    .mainSupportdiv {
        margin-top: 10%;
        padding: 50px 12px 10px 12px;

    }

    .supportDiv {
        flex-direction: column;
        width: 100%;
        /* background-color: #5756a2; */
    }

    .supportdivtext {
        width: 100%;
    }

    .supportimgDiv {
        width: 100%;
    }

    .supportdivtext p {
        width: 100%;
    }

    .supportdivtext h2 {
        font-size: 3.5em;
    }

    .parentsupportTrending h3 {

        width: 100%;
        padding: 10px;
        font-size: 1.4em;

    }

    .supportTrending {
        width: 100%;

        /* flex-direction: row-reverse; */
        flex-direction: column;

        gap: 0px;
    }

    .FaqDivimgDiv {
        width: 100%;
        /* background-color: #2bbcf1; */
        margin-bottom: 20%;
    }

    .FaqDiv {
        width: 100%;
        /* background-color: #23353b; */
    }
}