@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.parentWishList {
    /* background-color: #414852; */
    /* font-family: 'Montserrat', sans-serif; */
}

.parentWishList h4 {
    /* background-color: #ddd; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
}

.successAlert {
    background-color: #E5EFE5;
    color: #006F7E;
    font-size: 0.7em;
    font-weight: 400;
    padding: 3px 10px 3px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.addedcheckIcon {
    color: #006400;
}

.wishCheckout {
    font-size: 0.8em;
    font-weight: 400;
    border-radius: 5px;
    padding: 3px 10px 3px 10px;
    background-color: #1F86E4;
    color: white;
}

.wishlistWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    align-items: center;
    /* justify-content: flex-start; */

}

.singleCardWish {
    position: relative;
    width: 280px;
    height: 420px;
    margin: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    background-color: whitesmoke;
    border-radius: 15px;
    transition: all 0.8s ease-in-out;

}

.singleCardWish:hover {
    transform: translateY(-10px);
}

.wishListImage {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wishListImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.wishListImage:hover img {
    transform: scale(1.1);
}

.overLayWish {
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    /* margin-top: 9%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    /* height: 100%; */
    /* background-color: rgba(0, 174, 255, 0.8); */
    opacity: 1;
    transition: opacity 0.3s ease;
}

.wishListImage:hover .overLayWish {
    opacity: 1;
}


.viewWishListToCart {
    background-color: #fff;
    color: #000;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
}

.addWishListToCart {
    background-color: #111983;
    color: #fff;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.AddItem {
    background-color: #05A69E;
    color: #fff;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    margin-top: 2%;
    padding: 10px 30px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: crosshair;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.outofStock {
    background-color: grey;
    color: #fff;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: crosshair;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.viewWishListToCart {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
}

.whislistInfo {
    padding: 10px;
    text-align: center;
    color: #000000;
}

.whislistInfo h3 {
    margin: 15px 0 5px 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid gray;
    /* background-color: antiquewhite; */
}

.wishprice {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.favoritIcon {
    font-size: 30px;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.8);
    transition: all 0.8s ease-in-out;
    position: absolute;
    right: 3%;
}

.favoritIcon:hover {
    transform: translateY(-10px);
    cursor: pointer;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    padding: 3px;
}