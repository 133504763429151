@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentProductDetailsPage {
    background-color: #FBF8F6;
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}



/* CSS */
.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100%;
}

.button-13:hover {
    background-color: #f7fafa;
}

.button-13:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}


.button-Upper {
    background-color: #2bbcf17c;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100%;
}

.button-Upper:hover {
    background-color: #f7fafa;
}

.button-Upper:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}






.productDetailsPage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    /* padding-top: 100px; */
    gap: 10px;
    width: 90%;
  
    background-color: #FEFEFE;
}

.productDeilsImgDiv {
    width: 25%;
    /* background-color: rgb(43, 42, 41); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    padding: 15px;
}

.planBuyNow2 {
    border-radius: 15px;
    width: 100% !important;
    height: 50px;
    padding: 10px;
    font-weight: 600;
    color: #634C4C;
    background-color: #DBDBDB;
    border: 1px solid #DBDBDB;
    cursor: pointer;
}

.productDetIMG {
    /* height: 400px; */
    width: 100%;
}

.productDetIMG img {
    width: 100%;
    height: 100%;
}

.productOtherIMG {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0 0 10px 0;
    overflow-x: scroll;
}

.image1 {
    width: 50%;
    height: 100%;
}

.image1 img {
    width: 100%;
    /* height: 100%; */
}

.productDetailsDescripDiv {
    width: 45%;
    /* background-color: rgb(238, 231, 221); */
    padding: 30px;
}

.productDetHeadPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productDeliveryDiv {
    width: 18%;
    padding: 20px 5px 20px 5px;
    background-color: #FBFBFB;
    /* background-color: #008296; */
    border-radius: 5px;
}

.productdetName h3 {
    font-size: 1.3em;
    font-weight: 700;
}

.productdetName .productNameSpan {
    font-weight: 700;
    font-size: 1.7em;
}

.productdetName p {
    font-weight: 500;
    padding: 10px 0 5px 0;
}

.productdetName h4 {
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 0;
}

.promotionalOffer {

    font-weight: 400;
    font-size: 1em;
    margin-bottom: 1.5%;
    /* background-color: aquamarine; */
}

.modelNameInfoDiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
}

.modelNameInfoDiv span {
    /* background-color: brown; */
    width: 30%;
}

.modelNameInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: blue; */
    width: 70%;
    font-weight: 600;
}

.quantityDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 80%;
}

.quantityLabel {
    /* background-color: blue; */
    /* width: 30%; */
    text-decoration: line-through;
    font-size: 0.9em;
    color: grey;
}

.quantityBtnDiv {
    background-color: #98BFFB;
    color: white;
    padding: 5px;
    border-radius: 10px;
    font-size: 0.8em;
    /* width: 70%; */
}

.quantityBtnDiv button {}

.decreaseBtn {
    color: black;
    background-color: white;
    width: 18%;
    padding: 4px;
    font-size: 1.2em;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    border: 1px solid gray;
}
.stockDesing-ch{
    background-color: #A59B9B;
    color: white;
    padding: 5px;
    margin-top: 3px;
    border-radius: 5px;
}

.increaseBtn {
    cursor: pointer;
    color: black;
    background-color: white;
    width: 18%;
    padding: 4px;
    font-size: 1.2em;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid gray;
}

.quantityBtnDiv input {
    height: 38px;
    width: 18%;
    padding: 2px 20px 1px 10px;
    border: 1px solid gray;
}

.buyItemDivProductDes {
    width: 40%;
    /* background-color: blueviolet; */
    display: grid;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

/* .buyItemDivProductDes .planAddTOCart2 {
    border-radius: 15px;
    
    height: 50px;
    padding: 10px;
    color: white;
    background-color: #132A93;
    
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
} */
.planAddTOCart2 {
    border-radius: 15px;
    /* width: 100%; */
    height: 50px;
    padding: 10px;
    color: white;
    background-color: #132A93;
    /* border: 1px solid #3586FF; */
    cursor: pointer;
    /* width: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.cartIcon{
    font-size: 1.5em;
}

.imgMOdalDiv {

    width: 100%;
    display: flex;
    padding: 20px;
    gap: 10px;
    width: 90%;
}

.imgMOdal {
    width: 70px;
}

.dtailDiv1 {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25);
    width: 80%;
}

.hmCSubtotal {
    font-weight: bold;
    padding: 5px;
}

.dtailDiv2 {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25);
    width: 20%;
    padding: 10px;
}

.dtailDiv1-inside {
    display: flex;
    justify-content: center;
    text-align: center;
    /* margin: 0 auto; */
    padding: 20px;
}


.deliveryIconDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

}

.inStockDiv {
    font-size: 1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10%;
    color: #3586FF;
    border-radius: 5px;
}

.deliveryIcon {
    font-size: 2em;
}

.cardText {
    font-size: 0.8em;
}
.breadcrumbs {
    border: 1px solid #cbd2d9;
    border-radius: 0.3rem;
    display: inline-flex;
    overflow: hidden;
    cursor: pointer;
   }
  
  .breadcrumbs__item {
    background: #fff;
    color: #333;
    outline: none;
    padding: 0.75em 0.75em 0.75em 1.25em;
    position: relative;
    text-decoration: none;
    /* transition: background 0.2s linear; */
  }
  
  .breadcrumbs__item:hover:after,
  .breadcrumbs__item:hover {
    background: #edf1f5;
  }
  
  .breadcrumbs__item:focus:after,
  .breadcrumbs__item:focus,
  .breadcrumbs__item.is-active:focus {
    background: #323f4a;
    color: #fff;
  }
  
  .breadcrumbs__item:after,
  .breadcrumbs__item:before {
    background: white;
    bottom: 0;
    clip-path: polygon(50% 50%, -50% -50%, 0 100%);
    content: "";
    left: 100%;
    position: absolute;
    top: 0;
    /* transition: background 0.2s linear; */
    width: 1em;
    z-index: 1;
  }
  .toDivvats{
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  }
  
  .breadcrumbs__item:before {
    background: #cbd2d9;
    margin-left: 1px;
  }
  
  .breadcrumbs__item:last-child {
    border-right: none;
  }
  
  .breadcrumbs__item.is-active {
    background: #edf1f5;
  }
  .bredMove{
    /* background-color: #008296; */
    width: 100%;
    padding-left: 5%;
  }
 
  .product-containerAll{
    width: 90%;
  
    background-color: #FEFEFE;
    margin-top: 2%;
  }
  .youmay-Like{
    padding: 10px;
    padding-left: 2%;
  }

  .product-container-Like{
    display:flex;
    flex-wrap:wrap;
     width:100%;
    justify-content:center;
   
  } 
  .product-card-like {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    border: 1px solid lightgray;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    background-color: white; 
    padding-bottom:1%
  
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .product-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
  }
  
  .product-description {
    font-size: 0.875rem;
    margin: 10px 0;
    text-align: center;
  }
  
  .product-price {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 10px 0;
    color: orange;
  }
  
  .add-to-cart-button {
    padding: 10px 20px;
    background-color: #008ABF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .add-to-cart-button:hover {
    background-color: #32a6d4;
  }
  
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   }
  .original-price {
    text-decoration: line-through;
    color: lightgray;
    font-size: 0.875rem;
  }
  
  .discount-price {
    color: orange;
    font-size: 1.125rem;
    font-weight: bold;
  }



  

  @media(max-width: 1000px) {
    .productDetailsPage {
        display: grid;
        /* flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start; */
        justify-content: center;
        /* padding-top: 100px; */
        gap: 10px;
        width: 90%;
        /* height: 100%; */
        background-color: #FEFEFE;
       }
       .dtailDiv1 {
       
        width: 100%;
    }
    

       .dtailDiv2 {
       display: none;
    }

    .productDeilsImgDiv {
        width: 100%;
        /* background-color: rgb(43, 42, 41); */
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        gap: 10px;
        padding: 15px;

        }

    .productDetIMG img {
        /* height: 400px; */
        width: 150px;
    }

    .image1 {
        /* width: 50%; */
        /* height: 100%; */
        width: 50px;
    }

    .productDetailsDescripDiv {
        width: 100% !important;
        /* background-color: rgb(238, 231, 221); */
        padding: 0;
    }

    .productdetName {
        width: 100%;
    }

    

    .productDeliveryDiv {
        width: 100%;
        padding: 13px;
        background-color: #FBFBFB;
        border-radius: 5px;
    }

}

@media(max-width: 700px){

    .planAddTOCart2 {
       
        width: 100%;
    }
    .cartIcon{
        display: none;
    }
}