@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.parentDeviceSection {
    height: 100vh;
    background-image: url("../../images/deviceBack.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /* background-color: antiquewhite; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}

.parentDeviceSection::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 5%;
    background: linear-gradient(to right, whitesmoke, whitesmoke);
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;

}
/* .ban_textNextDevices{
    margin-top: 8%;
} */

.parentDeviceSection::after {
    content: '';
    position: absolute;
    top: 95%;
    left: 0;
    width: 100%;
    height: 5%;
    background-color: white;
    /* background: url("../../images/getSecuredBck.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed; */
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;

}

.deviceSectionContainer {
    /* background-color: rebeccapurple; */
    margin-top: 3%;
    width: 80%;
    height: 90%;
    
}

.deviceSectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    /* background-color: antiquewhite; */
    margin-bottom: 3%;
}

.deviceHeaderTitle {
    font-size: 2.3em;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.deviceButton{
    /* background-color: red; */
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.deviceButton button{
    font-family: "Poppins", sans-serif;
    border-radius: 15px;
    background-color: transparent;
    color: black;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
    cursor: pointer;
}

.deviceButton button:hover{
    background-color: #046388;
    border: none;
    color: white;
}


.mainDevicesList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 300px);
    /* Specifies the height of each row */
    grid-gap: 20px;
}

.mainDevicesList .virtual {
    grid-column: 1 / span 1;
    grid-row: span 2;
    
}

.mainDevicesList .bracelet,
.mainDevicesList .braceletSecond {
    grid-column: 2 / span 1;
    grid-row: span 1;
}

.mainDevicesList div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
   
}

.mainDevicesList img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.yup_viewall {
    display: inline-block;
    font: 800 19.39px/24px "Montserrat", sans-serif;
    background:#2BBCF1 !important ;
    border-radius: 26px;
    color: #fff !important;
    padding: 12px 28px;
    text-align: center;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration:none;
  }
  .yup_viewall:hover {
    background: white;
    text-decoration: none;
    color: black !important;
  }









@media (max-width: 768px) {
    .deviceSectionHeader{
        flex-direction: column;
        align-items: flex-start;
    }

        .deviceButton {
            /* background-color: red; */
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    .mainDevicesList {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .mainDevicesList > .virtual {
        grid-column: span 1;
        grid-row: span 1;
    }

    .mainDevicesList > div {
        height: 150px;
       
    }
}