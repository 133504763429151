.footerSection {
    position: relative;
 
    width: 100%;
    background-color: #3586ff;
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.footerSection .socialIcon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    /* background-color: rebeccapurple; */
}

.footerSection .socialIcon li {
    list-style: none;
}

.footerSection .socialIcon li a {
    font-size: 2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

.footerSection .socialIcon li a:hover {
    transform: translateY(-10px);
}

.footerSection .menuItem {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    /* background-color: red; */
}

.footerSection .menuItem li {
    list-style: none;
}

.footerSection .menuItem li a {
    font-size: 1.2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    opacity: 0.75;
}

.footerSection .menuItem li a:hover{
    opacity: 1;
}
.menuItem li{
    cursor: pointer;
}

.footerSection .footerParagr{
    color: white;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 1.1em;
    text-transform: capitalize;
}

.footerSection .wave{
position: absolute;
top: -100px;
left: 0;
width: 100%;
height: 100px;
background: url("../../images/wave.png");
background-size: 1000px 100px;
}

.footerSection .wave#wave1{
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWave 6s linear infinite;
}
.footerSection .wave#wave2{
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 6s linear infinite;
}

.footerSection .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave_02 5s linear infinite;
}

.footerSection .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_02 5s linear infinite;
}

@keyframes animateWave {
    0%{
        background-position-x: 1000px;
    }
    100%{
        background-position-x: 0px;
    }
}
@keyframes animateWave_02 {
    0%{
        background-position-x: 0px;
    }
    100%{
        background-position-x: 1000px;
    }
}

.square-holder {
    padding:30px;
    border: 1px solid #cecece;
    align-items: center;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    background-color:#f1f1f1;
    min-height:200px
  }
  
  .square-holder img {
    max-width:100%;
    filter: grayscale(100%);
    transition: all 0.3s;
  }
  
  .square-holder:hover img{
    filter:none;
  }
  .section-default{
    padding-left: 10%;
    padding-right: 10%;
  }

