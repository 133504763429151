@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentVitelFeatures {
    /* background-color: black; 
    */
    background: url("../../images/extraback.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
    height: 100vh;
    position: relative;
    font-family: "Poppins", sans-serif;
}

.parentVitelFeatures::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 8%;
    background: url("../../images/getSecuredBck.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;

}

.parentVitelFeatures::after {
    content: '';
    position: absolute;
    top: 95%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5%;
    background: linear-gradient(to right, whitesmoke, whitesmoke);
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;

}

.mainvitelFeature {
    /* background-color: black; */
    height: 100%;
}

.vitelFeatureHeader {
    /* background-color: rebeccapurple; */
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vitelFeatureDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 50%; */
    /* background-color: antiquewhite; */
    font-size: 2em;
    font-weight: 600;
    width: 80%;
    margin-top: 4%;
    padding: 20px;

}

.vitelFeatureDiv hr {
    border: 2px solid #ffffff;
    width: 8%;
    margin-left: 0%;
}

.vitelCardinal {
    /* margin-top: 2%; */
    /* margin-bottom: 1%; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 75%;
    /* background-color: bisque; */
}

.vitelCardinalMain {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    height: 80%;
    gap: 30px;
    /* background-color: rgb(43, 202, 202); */
}

.seconLayerDiv {
    background-color: rgba(44, 32, 32, 0.5);
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 6px 5px 6px;
    box-shadow: inset 0px -10px 30px rgba(255, 255, 255, 0.5);

}

.seconLayerDiv img {
    width: 100%;
    height: 100%;
    border-radius: 35px;
}

.featureimageDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-bottom: 10%;
}

.featureimageDiv img {
    width: 100%;
    border-radius: 100px;
}

.featureTextDiv {
    /* background-color: #005f8f; */

}

.productName {
    /* background-color: rgb(173, 153, 125); */
    /* padding: 10px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 600;

}

.pointSlogan {
    font-size: 0.5em;
    font-weight: 200;

    /* background-color: #910b0b; */
}

.productDescript {
    /* text-align: justify; */
    /* background-color: bisque; */
    padding: 5px 10px 20px 10px;
    font-size: 0.8em;
    margin-top: 15%;

}

.productDescript ul {
    list-style-type: none;
    padding-left: 0;
}

.productDescript ul li {
    /* background-color: aqua; */
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;
}

.productDescript ul li::before {
    content: "✔";
    /* Or use another symbol like ✓ */
    position: absolute;
    left: 0;
    top: 0;
    color: #f3eaea;
    /* Set the color of the checkmark */
    font-weight: bold;
}

.diffStyling {
    font-size: 0.5em;
}

@media screen and (max-width: 1300px) {
    .vitelCardinalMain {
        /* background-color: antiquewhite; */
        width: 90%;
        flex-wrap: wrap;
        height: 100%;
        overflow-y: scroll;
        /* background-color: aquamarine; */
    }

    .seconLayerDiv {
        /* background-color: #4e2d2d; */
        height: 80%;
        width: 50%;
    }

    .seconLayerDiv {
        width: 40%;
    }
}

/* @media screen and (max-width: 1300px) {} */

@media screen and (max-width: 850px) {
    .parentVitelFeatures {
        height: 100vh;
    }

    .parentVitelFeatures::before {
        height: 5%;
    }

    .vitelCardinal {
        height: 75%;
    }

    .vitelCardinalMain {
        /* background-color: antiquewhite; */
        flex-wrap: wrap;
        /* gap: 20px; */
        height: 100%;
        overflow-y: scroll;
        padding: 10px 0px 10px 0px;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .vitelCardinalMain::-webkit-scrollbar {
        display: none;
    }

    .seconLayerDiv {
        /* background-color: #684444; */
        width: 40%;
        /* height: 30%; */
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 750px) {
    .parentVitelFeatures {
        height: 100vh;
        /* margin-bottom: 40%; */
    }

    .vitelCardinalMain {
        flex-wrap: wrap;
        gap: 10px;
        width: 90%;
        height: 100%;
        overflow-y: scroll;
    }

    .vitelCardinal {
        /* background-color: bisque; */
        align-items: flex-start;
        height: 75vh;
    }

    .vitelCardinalMain {
        /* background-color: aqua; */
    }

    .seconLayerDiv {
        width: 90%;
    }



    .parentVitelFeatures::before {
        height: 3%;
    }

    .vitelFeatureDiv {
        /* background-color: aqua; */
        margin: 10% 0% 5% 0%;
        width: 100%;
        padding: 0px 10px 10px 10px;
    }

}