@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.parentSecuremeDash {
    /* background-color: #dde6ed; */
    /* background: linear-gradient(90deg, #001f3f, #005f8f); */
    background: linear-gradient(to top, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));
    font-family: 'Montserrat', sans-serif;
    /* font-family: Arial, Arial Nova, and Gill Sans; */
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    margin: 5% 2% 10% 2%;
    position: relative;
    /* width: 100%; */
    flex: 1;
}

.dashboard-content {
    background-color: #ffffff;
    /* flex: 1; */
    width: 70%;
    /* min-height: 100%; */
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    /* justify-content: space-between; */

}



@media screen and (max-width: 800px) {
    .parentSecuremeDash {
        margin: 13% 2% 22% 0%;
        width: 100%;
        flex: 1;
        /* gap: 0px; */
        padding: 20px 20px 20px 20px;
        /* overflow: scroll; */
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        gap: 20px;
        overflow-y: scroll;
    }

    .dashboard-content {
        width: 95%;
        padding: 1rem;
       position: absolute;
       top: 3%;
    }
}