.downloadBage{
    background-color: #2bbcf11c;
    width: 100%;
    height: 10vh;
    

    padding-left: 5%; 
}
.downloadBageDiv{
    /* text-align: center; */
    font-size: 20px;
    font-weight: 600;
    margin-top: 2% ;
   
}
.downBackdownDiv{
    /* padding-top: 1%; */
    /* position: absolute; */
    padding-top: 1.5%;
    display: flex;
    gap: 3%;
}
.ytkAnd{
    font-size: 20px;
    text-align: center;
}
.ad-CenterHome{
    /* text-align: center; */
}
.downloadBage-Enjoy{
    text-align: center;
    font-size: 20px;
}
.downloadSet-btns{
  display: flex;
  gap: 10px;
/* justify-content: end;
  gap: 20px;
  padding-right: 5%;
  padding-top: 3%; */
}
.tenSIze1{
    width: 120px;
}


@media(max-width:640px){
    .downBackdownDiv{
        /* padding-top: 1%; */
        /* position: absolute; */
        padding-top: 1.5%;
        display: grid;
        gap: 3%;
        padding: 10px;
    }
    .downloadBage{
        background-color: #2bbcf11c;
        width: 100%;
        height: auto;
        
    
        padding-left: 0; 
    }
    .downBackdown{
        font-size: 15px;
        text-align: center;
    }
    .downloadSet-btns{
        justify-content: center;
        text-align: center;
    }
    .tenSIze1{
        width: 80px;
    }


}