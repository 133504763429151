.parentSubquicktopup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* background-color: #000000; */
}

.subuserQuickTopup {
    padding: 20px 0px 20px 0px;
    /* background: linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1)); */
    background-color: #DDE6ED;
    width: 100%;
    height: 100%;
    /* max-height: 550px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.selectAData{
    width:100% ;
    height:50px ;
    border-radius: 5px;
}


.adjustedErroDivUser {
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 0;
}

.topupTitleUser {
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    background-color: #000000;
    width: 70%;
    font-size: 2em;
    font-weight: 500;
    padding: 5px 3px 5px 3px;
    color: white;
    text-align: center;
}

.numberInputuser {
    height: 100%;
    width: 70%;
    /* margin-top: 3%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background-color: rebeccapurple; */
}

.numberInputuser label {
    color: rgb(3, 3, 3);
    font-size: 1.2em;
    font-weight: 800;
}

.numberInputuser input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
    font-size: 1.1em;
    font-weight: 600;
    position: relative;
}
     


.userRechargeNowBtnDiv {
    width: 70%;
    /* background-color: antiquewhite; */
}

.getUserQuicktopBtn {
    border: none;
    width: 100%;
    padding: 15px 30px 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #000000;
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.getUserQuicktopBtn:hover{
    background:linear-gradient(to right, rgba(33, 150, 243, 1), rgba(15, 14, 122, 1));

}

.rechargIcon{
    font-size: 1.5em;
}

@media screen and (max-width: 800px) {
    .parentSubquicktopup { 
        width: 100%;  
        height: 100%;
        flex: 1;
        width: 350px;
    }

    .topupTitleUser{
     width: 100%;
    }
    .numberInputuser{
        width: 100%;
    }

    .userRechargeNowBtnDiv{
        width: 100%;
    }
    .subuserQuickTopup {
        width: 80%;
        margin-left: -8%;
    }
    /* .adjustedErroDivUser {
        display: flex;
        flex-direction: column;
        gap: 25px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        width: 80%;
        height: 100%;
        padding: 0;
    } */
}