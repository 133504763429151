.parentDefault {
    flex: 1;
    /* max-height: 600px;
    overflow-y: scroll; */
    /* background-color: aqua; */
    /* width: 100%; */
}

.parentCardContainer {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
    margin-top: 1rem;
    /* background-color: aqua; */
    width: 100%;
}

.itemName {
    font-weight: 900;
    color: black;
}

.dashBoardItem {
    font-size: 1.5em;
}

.userInfoCard {
    /* flex: 1; */
    background: #2a5270;
    height: 200px;
    width: 250px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    transition: 0.5s ease-in-out;
}

.userInfoCard:hover {
    transform: scale(1.04);
    cursor: pointer;
}

.userCardIcon {
    background-color: #fff;
    height: 50%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    color: #969393;
}

.userCardIcon img {
    height: 100%;
}

.userCardTitle {
    background-color: #ffffffc0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    color: #526d82;
    border-radius: 10px;
}

.userInfoCard2 {
    background: #2a5270;
    height: 200px;
    width: 250px;
    padding: 20px;
    border-radius: 10px;
    display: none;
    /* align-items: center;
    flex-direction: column; */
    gap: 20px;
    transition: 0.5s ease-in-out;
}

@media screen and (max-width: 800px) {
    .userInfoCard2 {
        display: flex;
        background: #2a5270;
        height: 200px;
        width: 250px;
        padding: 20px;
        border-radius: 10px;

        align-items: center;
        flex-direction: column;
        gap: 20px;
        transition: 0.5s ease-in-out;
    }

    .parentCardContainer {
        display: flex;
        gap: 1rem;
        overflow-x: auto;
        padding: 1rem;
        margin-left: 0;
        scroll-snap-type: x mandatory;
        /* Snap each card into place */
        scroll-padding-left: 8rem;
        /* Padding before the first card */
        scroll-padding-right: 1rem;
        /* Padding after the last card */
        -webkit-overflow-scrolling: touch;
    }

    .userInfoCard {
        background: #2a5270;
        height: 200px;
        width: 250px;
        flex-shrink: 0;
        border-radius: 10px;
        padding: 1rem;
        scroll-snap-align: start;

    }
}