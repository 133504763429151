@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.IoTDeviceParent {
    padding: 0;
    font-family: "Poppins", sans-serif;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
/* position: relative; */

}



.IoTHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: antiquewhite; */
    width: 100%;
    margin-bottom: 1%;
}

.IoTSecondHeaderDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rebeccapurple; */
    width: 80%;
}

.IoTSeconParent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 20px; */

    width: 90%;
    height: auto;
    /* background-color: rgb(82, 50, 50); */
}

.IoTHeaderText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 50%; */
    /* background-color: antiquewhite; */
    font-size: 2em;
    font-weight: 600;
    width: 80%;
    /* margin-top: 4%; */
    padding: 20px;
}

.IoTHeaderText hr {
    border: 2px solid #000000;
    width: 8%;
    margin-left: 0%;
}

.IoTfilterDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    text-transform: lowercase;
    /* background-color: #000000; */
}

.IoTfilterDiv label{
    text-transform: capitalize;
    font-weight: 600;
}

.IoTfilterDiv select {
    border-radius: 5px;
    width: 60%;
    height: 35px;
}

.IoTDeviceCard {
    height: 500px;
    margin: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 5px 5px 20px black;
    overflow: hidden;
    position: relative;
}

.iotImageDiv img {
    height: 500px;
    width: 350px;
    border-radius: 3px;

    transition: 0.5s ease-in-out;
}

.iotDetailsDiv {
    height: 70px;
    width: 350px;
    padding: 6px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgb(27, 27, 27, 0.5);
    color: white;
    transition: 0.5s ease-in-out;
}

.iotDetailsDiv h1 {
    margin-top: 10px;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.IoTPrice {
    background-color: black;
    padding: 5px;
    width: auto;
    /* margin-left: 80%; */
}

.iotDetailsDiv .IoTDescription {
    font-size: 0.8em;
    margin: 20px;
    visibility: hidden;
    opacity: 0;
}

.IoTDeviceCard:hover {
    cursor: pointer;
}

.IoTDeviceCard:hover .iotDetailsDiv {
    height: 220px;
    bottom: 0px;
    background: black;
    overflow: hidden;

}

.IoTDeviceCard:hover .IoTDescription {
    opacity: 1;
    visibility: visible;
    /* background-color: brown; */
    padding-bottom: 10px;

}

.IoTDeviceCard:hover img {
    transform: scale(1.1) rotate(-3deg);
}

@media screen and (max-width: 768px) {
    .iotDetailsDiv h1 {
        margin-top: 10px;
        font-size: 1em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        justify-content: center;
    }

    .IoTHeaderText hr {
        border: 1px solid #000000;
        width: 100%;
        margin-left: 0%;
    }

    .IoTSecondHeaderDiv {
        flex-direction: column;
    }

    .IoTHeaderText {
        /* background-color: bisque ; */
        font-size: 1.5em;
        font-weight: 500;
        width: 100%;
    }

    .IoTfilterDiv {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* background-color: blueviolet; */
    }

    .IoTfilterDiv label {
        font-size: 1.1em;
        font-weight: 400;
        text-transform: capitalize;

    }

    .IoTfilterDiv select {
        border-radius: 5px;
        width: auto;
        height: 100%;
    }
}