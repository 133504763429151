.product-card {
    width: 300px;
    position: relative;
    box-shadow: 0 2px 7px #dfdfdf;
    margin: 10px auto;
    background: #fafafa;
    /* margin-bottom: 10%; */
}


.badge {
    position: absolute;
    left: 2px;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
  background-color: #fbb72c;
    color: #fff;
    padding: 3px 10px;
}
/* .img-product-tumb{
  width: 300px;
} */

.product-tumb {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    padding: 50px;
    background: #f0f0f0;
}

.product-tumb img {
    max-width: 200%;
    max-height: 200%;
}
/* .serch_dis{
    display: ;
} */

.product-details {
    padding: 10px;
}

.product-catagory {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 18px;
}

.product-details h4 a {
    font-weight: 500;
    display: block;
    margin-bottom: 18px;
    text-transform: uppercase;
    color: #363636;
    text-decoration: none;
    transition: 0.3s;
}

.product-details h4 a:hover {
    color: #fbb72c;
}

.product-details p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 18px;
    color: #999;
}
.stockDesing-check{
  background-color: #A59B9B;
  padding: 5px;
  position: absolute;
  right: 0;
  color: white;
  border-radius: 3px;
  
}



.display-Outofstock:before {
	content: "";
	background: rgba(255,255,255,0.5);
	width: 100%;
	height: 100%;
	/* position: absolute; */
	left: 0; top: 0;
}

.display-Outofstock:after {
	content: "OUT OF STOCK";
	/* position: absolute; */
	background: rgba(155, 144, 144, 0.9);
	border-radius: 100%;
	text-align: center;
	width: 70px;
	height: 70px;
	top: calc(50% - 35px);
	left: calc(50% - 35px);
	font-size: 0.8em;
	font-weight: bold;
	display: flex;
	align-items: center;
}

.yetLoasder{
  color: #1F88E6 !important;
 font-size: 20px;
 padding: 10px;

 
}
.product-bottom-details {
    overflow: hidden;
    border-top: 1px solid #eee;
    /* padding-top: 20px; */
    padding: 10px;
}

.product-bottom-details div {
    float: left;
    width: 50%;
}

.product-price {
    font-size: 18px;
    color: #fbb72c;
    font-weight: 600;
}
/* /subscribers */

.product-price small {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
}

.product-links {
    text-align: right;
}

/* .wishFavicon{
  background-color: #ccc;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
} */

.product-links a {
    display: inline-block;
    margin-left: 5px;
    color: #e1e1e1;
    transition: 0.3s;
    font-size: 17px;
}

.product-links a:hover {
    color: #fbb72c;
}




.search-container{
    display: block;
    margin: 0 auto;
    margin-top: 1%;
   }



  input#search-bar{
     width: 700px;
     height: 50px;
    outline: none;
    border-radius: 5px;
    border: #363636;
    &:focus{
      border: 1px solid #008ABF;
      transition: 0.35s ease;
      color: #008ABF;
      &::-webkit-input-placeholder{
        transition: opacity 0.45s ease; 
          opacity: 0;
       }
      &::-moz-placeholder {
        transition: opacity 0.45s ease; 
          opacity: 0;
       }
      &:-ms-placeholder {
       transition: opacity 0.45s ease; 
         opacity: 0;
       }    
     }
   }

.list_mainName{
  position: relative;
  color: white;
}
.listS-categories{
  position: absolute;
  z-index: 100;
  background-color: #2BBCF1 !important;
  display: none;
 width: 400px;
 text-align: start;
 padding: 10px;
 font-size: 18px;
 border-radius: 10px;
 
}
.listApp-mg{
  width: 20px;
}

.listS-categories li:hover{
 color: #fbb72c;
}
.listS-categories li{
  padding: 8px;
 }

.list_mainName-ul{
   list-style-type:none;
}
.list_mainName:hover  .listS-categories{
  display: block;
}

   .categoryDrop{
    margin-top: 20px;
    font-size: 20px;
    /* font-family: "Poppins", sans-serif; */
    font-family: Arial, Arial Nova, and Gill Sans;
    cursor: pointer;
    /* color: white; */
    color: black;
   }
   .cart-Mycart-span{
     background-color: black ;
    /* font-family: "Poppins", sans-serif; */
    font-family: Arial, Arial Nova, and Gill Sans;
    color: white;
    padding: 10px;
    position: relative;
    padding-left: 30px;
    border-radius: 5px;
    margin-left: -100%;
    cursor: pointer;
   }

   .cart-Mycart-div{
    margin-top: 20px;

   }
   .cartIicon{
    position: absolute;
    margin-left: -25px;
    margin-top: 2px;

   }
   .num-Cart{
    background-color: white;
    color: #fbb72c;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-left: 4px;
    font-weight: bold;
   }
   .linktodisplay-search li{
    text-align: start;
   }
   
   .search-icon{
    position: relative;
     float: right; 
     width: 75px; 
    top: -10px;
    right: 80px;
  } 
  .categoryIcon{
    margin-top: 7px;
    position: absolute;
    padding: 1px;
    margin-left: 2px;
  }


  


















  .jx-send{
    display: flex;
    background-color: #2BBCF1;
    justify-content: space-around;
    text-align: center;
    height: 80px;
    width: 100%;
    margin-top: 3.4%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .cart-Mycart{
      
  }

  .grid-container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;

    /* background-color: #2196F3; */
    /* padding: 10px; */
    justify-content: center;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(223, 218, 218, 0.8);
    text-align: center;
    border-radius: 5px;
  }
  .bt_addTocart{
    background-color: #008ABF;
    padding: 5px;
    color: white !important;
    border-radius: 5px;
    z-index: 100 !important;
    margin-top: -1%;
    cursor: pointer;
    /* font-family: "Poppins", sans-serif; */
  }
  .bt_addTocart:hover{
    background-color: #42b5e2b0;
    padding: 5px;
    color: black !important;
    border-radius: 5px;
    z-index: 100 !important;
    margin-top: -1%;
    cursor: pointer;
    /* font-family: "Poppins", sans-serif; */
  }


  /* shopping cart style */
  .fpo {
    position: relative;
    margin: 25% auto;
    display: block;
}
.pulsate{
  background: linear-gradient(-45deg, #DDDDDD, #F0F0F0, #DDDDDD, #F0F0F0);
	background-size: 400% 400%;
	-webkit-animation: Gradient 2.25s ease infinite;
	-moz-animation: Gradient 2.25s ease infinite;
	animation: Gradient 2.25s ease infinite;
}
.block {
  display:block;
  width:271px;
  height:16px;
  color: black;
}
.block2 {
  width:78px;
  height:8px;
  margin-bottom:8px;
}
.block3 {
  width:131px;
  height:8px;
  margin-bottom:16px;
}
.circle {
  width:28px;
  height:28px;
  border-radius:50%;
  float:right;
  
}
#card {
  box-sizing: border-box;
	width: 335px;
  background: #fff;
	position: relative;
	margin:auto;
	top:25%;
}
.card-image {
  box-sizing: border-box;
  display:block;
  width:335px;
  height:243px;
  background:#FAFAFA;
  padding:16px;
}
.card-content {
  clear:both;
  box-sizing: border-box;
  padding:16px;
  background:#fff;
}
@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@media(max-width: 1500px) {
.jx-send {
    display: flex;
    background-color: #2BBCF1;
    justify-content: space-evenly;
    text-align: center;
    height: 80px;
    width: 100%;
    margin-top: 4.2%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

  @media(max-width: 1200px){

    input#search-bar{
      width: 300px;
      height: 50px;
     outline: none;
     border-radius: 5px;
     border: #363636;
     &:focus{
       border: 1px solid #008ABF;
       transition: 0.35s ease;
       color: #008ABF;
       &::-webkit-input-placeholder{
         transition: opacity 0.45s ease; 
           opacity: 0;
        }
       &::-moz-placeholder {
         transition: opacity 0.45s ease; 
           opacity: 0;
        }
       &:-ms-placeholder {
        transition: opacity 0.45s ease; 
          opacity: 0;
        }    
      }
    }
    .list_mainName{
    font-size: 15px !important;
    }
    .jx-send{
      display: flex;
      background-color: #2BBCF1;
      justify-content: space-evenly;
      text-align: center;
      height: 80px;
      width: 100%;
      margin-top: 3.4%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .cart-Mycart-span{
      background-color: black ;
     /* font-family: "Poppins", sans-serif; */
     font-family: Arial, Arial Nova, and Gill Sans;
     color: white;
     padding: 10px;
     position: relative;
     padding-left: 30px;
     border-radius: 5px;
     cursor: pointer;
    }
   
  }

  @media(max-width:993px){
    .pushIpad-dd{
      padding-top: 5%;
    }
  }

  @media(max-width: 700px){
    .jx-send{
      display: grid;
      background-color: #2BBCF1;
      justify-content: space-evenly;
      text-align: center;
      height: 80px;
      width: 100%;
      margin-top: 14%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .categoryDrop{
     position: absolute;
     margin-top: 12.5%;
     margin-left: -2%;
    }
      .mainNamen{
       display: block;
       color: black;
      }
      .mainNamen-all{
        display: none;
      }
      .categoryIcon{
        font-size: 20px;
      }
    input#search-bar{
      width: 350px;
      height: 50px;
     outline: none;
     border-radius: 5px;
     border: #363636;
     &:focus{
       border: 1px solid #008ABF;
       transition: 0.35s ease;
       color: #008ABF;
       &::-webkit-input-placeholder{
         transition: opacity 0.45s ease; 
           opacity: 0;
        }
       &::-moz-placeholder {
         transition: opacity 0.45s ease; 
           opacity: 0;
        }
       &:-ms-placeholder {
        transition: opacity 0.45s ease; 
          opacity: 0;
        }    
      }
    }
    .search-icon{
      position: absolute;
       float: right; 
       width: 75px; 
      margin-top: 7% !important;
      right: 20px !important;
    } 
    .cart-Mycart-span{
      background-color: rgba(0, 0, 0, 0.185) ;
     /* font-family: "Poppins", sans-serif; */
     font-family: Arial, Arial Nova, and Gill Sans;
     color: white;
     padding: 10px;
     position: fixed;
     padding-left: 30px;
     border-radius: 5px;
     margin-left: 27%;
     cursor: pointer;
     margin-top: -20px;
     z-index: 100;
    }
    .cart-Mycart{
      display: none;
    }
     .cartIicon{
    
     color: black;
  
     }

  }

 

  