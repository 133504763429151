.parentTrackerList{
    flex: 1;
    /* width: 85%; */
    /* background-color: aqua; */
}

.header-title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    text-transform: capitalize;
}

.trackingList{
    height: 85%;
    margin-top: 2rem;
    background-color: #dde6ed;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-name{
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.icon1{
    color: red;
    cursor: pointer;
}