.parentQuickLink {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px 3px 0px;
    margin-bottom: 7%;
    /* background-color: #000000; */
}

.quickLinkContainer {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 20px;
    height: 90%;
    width: 90%;
    background: url("../../images/vitelgirl.jpg") no-repeat center;
    background-size: cover;
    background-position: center 70%;
    box-shadow:
        inset 0px -50px 50px rgba(60, 70, 85, 0.5),
        inset 0px -50px 50px rgba(60, 70, 85, 0.5),
        inset 0px -300px 300px rgba(0, 0, 0, 1);
}

.quickLinkSecond {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 40px; */
    margin-top: 10%;
    /* background-color: antiquewhite; */
}

.quickLinkTitle {
    font-size: 2em;
    color: whitesmoke;
    font-weight: 700;
    /* background-color: antiquewhite; */
    width: 70%;

}

.quickLinkDiv {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    /* Adjust min-width to 150px for mobile */
    gap: 20px;
    width: 70%;
    height: 40%;
    align-items: center;
    justify-content: center;
}

.quickLinkCard {
    padding: 20px 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.clickHereBtn {
    background-color: #000000;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-transform: capitalize;
    margin-top: 10%;
    cursor: pointer;
}

.clickHereBtn .clickmeIcon {
    font-size: 1.5em;
    font-weight: 600;
}

.quickLinkText {
    font-size: 1em;
    font-weight: 600;
    color: white;
}

.quickImage {
    font-size: 3em;
    font-weight: 600;
    color: #ececec;
}

@media screen and (max-width:750px) {
    .parentQuickLink {
        /* background-color: antiquewhite; */
    }

    .quickLinkContainer {
        width: 90%;
    }

    .quickLinkDiv {
        margin-top: 20%;
        width: 100%;

        gap: 0px;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        /* background-color: rgb(230, 142, 0); */
        overflow-y: scroll;
    }

    .quickLinkCard {
        width: 90%;
    }

    .quickImage {
        font-size: 5em;
    }

    .quickLinkTitle {

        /* width: 100%;
        padding: 10px 10px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 15px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px); */
        display: none;
    }


}