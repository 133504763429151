@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parent404 {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    background-image: url("../../images/backimg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.error404 {
    color: red;
}

.MainDiv404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.backHome {
    background-color: black;
    color: white;
    padding: 14px;
    border-radius: 10px;
    display: flex;

    align-items: center;
    justify-content: center;
    gap: 10px;
}

.backHome:hover {
    color: white;
}

.homeIcon {
    font-size: 1.4em;
}