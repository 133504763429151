.parentPartnerDiv {
    font-family: Arial,
        Arial Nova,
        and Gill Sans;
    margin-bottom: 10%;
    background-color: #F9FAFB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partnerHerosection {
    height: 70vh;
    width: 80%;
    /* border: 2px solid black; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 5%;
}

.partnerherotex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    /* background-color: rgb(40, 68, 92); */
}

.herotitletext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.joinspan {

    font-size: 5em;
    font-weight: 900;
}

.mission {
    color: #2BBCF1;
    font-weight: bolder;
    text-transform: capitalize;
}

.doublespan {
    /* color: #000; */
    font-size: 4em;
    font-weight: 700;
    /* background-color: azure; */
    /* padding: 0; */
}

.payspan {
    font-size: 3.7em;
    font-weight: 900;
}

.doublehus {
    color: #0F0F7B;
    font-weight: 800;
}

.payspan {
    font-size: 3.5em;
    font-weight: 800;
    /* background-color: azure; */
}

.partnerHeroBtn .linkBuuton {
    padding: 15px 15px 15px 15px;
    /* margin-top: 10%; */
    /* margin-left: 5%; */
    font-size: 1.5em;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgb(38, 57, 77) 0px 20px 10px -10px;
    background: linear-gradient(to right, #5756A2, #2BBCF1);
    background-size: 200% 200%;
    color: white;
    border: none;
    animation: gradientAnimation 10s ease-in-out infinite;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    white-space: nowrap;
}

@media screen and (max-width: 800px) {
    .partnerherotex{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .partnerHerosection {
        /* flex-direction: column-reverse; */
        width: 100%;
        height: 70vh;
        padding: 5px;
    }

    .partnerheroimg {
        display: none;
    }

    .joinspan {
        font-size: 2.3em;
    }
    .doublespan{
        font-size: 2.3em;
    }
    .payspan{
        font-size: 2.3em;
    }
}