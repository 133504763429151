@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentLearnMore {
    font-family: "Poppins", sans-serif;
}

.learnMoreHero {
    /* background-color: antiquewhite; */
    background-image: url("../images/backimg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.learnMoreHero::before {
    content: "";
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    height: 56vh;
    background: linear-gradient(to right,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.5));
    z-index: 1;
}

.learnMorecontainer2 {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.learnMoreHeader {
    font-size: 4em;
    font-weight: 700;
}

.learnMoretxt {
    /* background-color: aqua; */
    width: 45%;
    text-align: center;
    font-weight: 400;
}

.ourPartners {
    color: #3B49A2;
}

.partnerFormReserve {
    font-family: "Poppins", sans-serif;
    /* background-color: #3B49A2; */
    background-image: url("../images/backimg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.firstParentRadio {
    /* background-color: aliceblue; */
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.firstRadioSection {
    /* background-color: rebeccapurple; */
    width: 35%;
    padding: 10px;
}

.secondRadioSection {
    /* background-color: rebeccapurple; */
    width: 35%;
    padding: 10px;
}

.firstRadioSection legend {
    /* background-color: rebeccapurple; */
    /* width: 100%; */
    font-size: 2em;
    font-weight: 600;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.secondRadioSection legend {
    /* background-color: rebeccapurple; */
    /* width: 100%; */
    font-size: 2em;
    font-weight: 600;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.form__group {
    /* background-color: antiquewhite; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    margin-bottom: 1.5%;
}

.form__group label {
    /* background-color: aqua; */
    margin-top: 1%;
}

.parentFormField22 {
    /* background-color: rgb(99, 37, 37); */
    width: 100%;
    height: 80vh;
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
}

.partnerFormFieldDiv {
    /* background-color: bisque; */
    width: 40%;
    height: 100%;
}

.sectionHeader {
    /* background-color: beige; */
    font-size: 1.5em;
    font-weight: 600;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 2%;
}

.GPSinfo {
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: 300;
    width: 100%;
    font-size: 0.8em;
    color: #4b5e25;
}

.formSaveButton {
    width: 100%;
    /* background-color: #3B49A2; */
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.formSaveButton button {
    width: 15%;
}


.areaTable {
    /* background-color: bisque; */
    width: 40%;
}

.areaTableHead {
    font-size: 1.5em;
    font-weight: 600;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 2%;
}

.setDivide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 4%;
    margin-bottom: 4%;
    /* Adjust the gap between the fields as needed */
}

.labelText {
    font-weight: 500;
    margin-bottom: 5px;
    /* display: block; */
}

.selectmain {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #050303;
    color: #333;
    appearance: none;
    /* Additional styling for the select dropdown */
}

.selectmain:focus {
    border-color: #070535;
    outline: none;
}

.classic22 {
    background-image: linear-gradient(45deg, transparent 50%, #070535 50%),
        linear-gradient(135deg, #070535 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    padding-right: 30px;
}


.selectmain.classic22 {
    background-color: #ffffff;
}

.partnerTable {
    margin-top: 5%;
}

.partnerAddBtn {
    /* background-color: #070535; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addPartnerMainBtn {
    background-color: #354291;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 7px;
    border: none;
    padding: 8px 15px 8px 15px;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 750px) {
    .partnerFormReserve {
        padding: 0px 10px 0px 10px;
    }

    .learnMoreHero::before {
        content: "";
        display: none;
    }

    .learnMoreHero {
        height: 25vh;
    }

    .learnMorecontainer2 {
        width: 100%;
        height: 50%;
        /* background-color: #ccc; */
    }

    .learnMoreHeader {
        font-size: 2em;

    }

    .learnMoretxt {
        width: 80%;
        font-size: 0.7em;
    }

    .firstParentRadio {
        /* background-color: rgb(13, 82, 143); */
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    }

    .firstRadioSection legend {
        font-size: 1.2em;
    }

    .secondRadioSection legend {
        font-size: 1.2em;
    }

    .firstRadioSection {
        width: 100%;
        /* background-color: #ccc; */
    }

    .secondRadioSection {
        width: 100%;
        padding: 0px;
    }

    .parentFormField22 {
        /* background-color: rgb(207, 32, 32); */
        width: 100%;
        height: 100vh;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }

    .partnerFormFieldDiv {
        width: 100%;
        height: 100%;
        /* background-color: #070535; */
        overflow-y: scroll;
    }

    .sectionHeader {
        width: 100%;
        /* background-color: #ccc; */
        font-size: 1em;
        font-weight: 600;
        border-bottom: 2px solid gray;
        padding-bottom: 5px;
        text-transform: capitalize;

        margin-bottom: 2%;
    }

    .areaTableHead {
        font-size: 1em;
        font-weight: 600;
        border-bottom: 2px solid gray;
        padding-bottom: 5px;
        text-transform: capitalize;
        width: 100%;
        margin-bottom: 2%;
    }

    .GPSinfo {
        width: 100%;
    }

    .areaTable {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .learnMoreHeader {
        font-size: 1.5em;
    }

    .learnMoretxt{
        font-size: 0.6em;
        width: 80%;
        font-weight: 600;
        color: #333333;
    }
}