@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');





.findstoreparentdiv {
    min-height: 50vh;
   
    font-family: "Poppins", sans-serif;
    color: white;
    margin-bottom: 10%;

}
.firstInputSec{
   
    width: 70%;
    justify-content: center !important;
    text-align: center !important;
    margin: auto 0;
   
}
.firstInputSec-form{
    
}
.firstInputSec-input{
    width: 70% !important;
    height: 50px;
    background-color: white;
    border-radius: 5px;
    justify-content: center;
  
    color: black;
   
}


.firstInputSec input::placeholder {
    text-align: left !important; 
    }

.setBtnLocation{
    padding: 10px 30px;
    margin-left: 1%;
    background-color: #0F137E;
    height: 50px;
    font-size: 1.3em;
    font-weight: 700;
    border-radius: 10px;
    width: 220px;
    text-align: center;
}

.findstoreHeaderparentdiv {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background: url("../../images/bac-search.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
 
    width: 100%;
    position: relative;
}

.findstoreparentFormdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
 
}
.no-reSultFoun-d{
    font-size: 20px;
    font-weight: 600;
    color: black!important;
}

.findstoreHeaderparentdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;

}

.findIcon {
    color: white;
}

.finstoreText {
    font-size: 3em;
    font-weight: 700;
    z-index: 100;
   
    text-transform: capitalize;
    color: black;
    margin-top: 4%;
}

.findStoreField {
  

    z-index: 200;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    padding: 13px;
  
}

.findStoreFieldOrDiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;


}

.findStoreFieldOrDiv h4 {
  
    border-radius: 50px;
    
    color: black;
}

.findStoreField input {
    width: 60%;
    height: 50px;
    border-radius: 10px;
    padding: 15px;
    color: rgb(0, 0, 0);
    border: 2px solid black;
    background-color: transparent;
}
#searcMob-btn{
width: 20px;
height: 20px;
border-radius: 10px;
}

.findStoreField button {
    padding: 10px 30px;
  
    background-color: #0F137E;
    height: 50px;
    font-size: 1.3em;
    font-weight: 700;
    border-radius: 10px;
}


.findStoreFieldSelect button {
    padding: 10px 30px;
      
    background-color: #0F137E;
    height: 50px;
    font-size: 1.3em;
    font-weight: 700;
    border-radius: 10px;
}

.findStoreFieldSelect {
   
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13px;
    padding: 20px;
   
}

.selectstorecity {
    width: 100%;
   
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}

.selectstorecity select {
    width: 20%;
    height: 50px;
    border-radius: 5px;
    padding: 15px;
    color: black;
    border: 1px solid grey;
    background-color: white;
}

.findStoreFieldSelect .selectStoretitle {
   
    width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;

}

.findStoreFieldSelect .selectStoretitle h4 {
  
    font-size: 1.3em;
    width: 78%;
   
    padding: 5px 20px;
    color: black !important;
}
.cdtitle{
    color: black;
}

.locationListParent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    padding: 20px;
}

.locationList {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.locationList .findStoresectionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    color: black;
    font-size: 2em;
    font-weight: 700;
    padding: 20px;
    margin: 2% 0% 0% 0%;
}

.findStoresectionHeader h3 {
    font-size: 1.4em;
    font-weight: 600;
}

.findStoresectionHeader p {
    font-size: 0.9em;
    font-weight: 200;
}

.locationtablediv {
   
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 1px;
    padding: 50px;
    border-radius: 10px;
}
.ErrorShow{
  
    padding: 5px;
}

.viewMapbtn {
    background-color: #3586FF;
    padding: 10px;
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    cursor: pointer;
}

.viewIcon {
    font-size: 1.4em;
}

.hideMapDiv {
   
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.hideMapDiv button {
    background-color: #3586FF;
    padding: 10px;
    white-space: nowrap;
   
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    cursor: pointer;
}
.tableMAP-Set{
    color: black !important;
    padding: 10px;
}

.mapContainer {
  
    width: 100%;
    height: 700px;
}
.form-cen_div{
    justify-content: center !important;
    text-align: center !important;
  
    width: 100%;
    margin: 0 auto !important;
}

@media screen and (max-width: 800px) {
    .finstoreText {
      
        
        padding: 0% 2px;
    }
    .finstoreText{
        font-size: 15px;
        font-weight: 800;
        margin-top: 5%;
        
    }
    .findstoreHeaderparentdiv{
        margin-top: 9%;
    }

    .findIcon {
        display: none;
    }

    .findStoreField {
        width: 100%;
    }

    .findStoreField input {
        width: 75%;
    }

    .findStoreField button {
        font-size: 1em;
        font-weight: 500;
        padding: 5px 5px;
    }
    .selectstorecity{
        display: grid;
        width: 100%;
     

    }
    .selectstorecity select {
        width: 300px !important;
    }
    .locationList {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .firstInputSec{
     
        width: 100%;
        justify-content: center !important;
        text-align: center !important;
       
    }
    .setBtnLocation{
        padding: 10px ;
        margin-left: 1%;
        background-color: #0F137E;
        height: 50px;
        font-size: 12px;
        font-weight: 200;
        border-radius: 10px;
        width: auto;
    }
    .hideLOcat{
        display: none;
    }
    .findStoresectionHeader{
        font-size: 20px !important;
    }
    .locationtablediv {
        
        width: 100% !important;
        
    }
    #premiumSection{
        width: 100% !important;
    }
   
}