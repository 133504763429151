.parentAirtimetopsuccess {
    height: 100vh auto;
    width: 100%;
    display: flex;
    margin-bottom: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.book_form_room_list {
    /* background: RGB(255, 255, 255); */
    margin-top: 3%;
    /* background-color: rebeccapurple; */
    width: 50%;
    /* height: 70% auto; */
    /* margin-left: 3%; */
    padding: 1.5%;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.book_form_room_list::after {
    content: " ";
    position: absolute;
    top: 0%;
    right: 0%;

    left: 0%;

    width: 100%;
    height: 35px;
    transform: rotate(180deg);
    background: linear-gradient(-45deg,
            transparent 33.33%,
            #F5F5F5 33.333%,
            #F5F5F5 66.667%,
            transparent 66.667%),

        linear-gradient(45deg,
            transparent 33.33%,
            #F5F5F5 33.333%,
            #F5F5F5 66.667%,
            transparent 66.667%);

    background-size: 30px 60px;
}



.book_hotel__details {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    padding-bottom: 20px;
    /* border-bottom: 1px dashed gray; */
}

.book_hotel__details h3 {
    text-align: justify;
    margin-left: 3%;
    margin-right: 3%;

    color: #2E2D4D;
}

.book_hotel__details p {
    margin-top: 7px;
    color: black;
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Times New Roman', Times, serif;
}

.book_hotel_img {
    width: 18%;
    height: 5%;
}

.book_hotel_img img {
    width: 100%;
    height: 100%;
}

.book_hotel__details ul {
    display: flex;
    align-items: center;
}

.book_hotel__details ul li {
    list-style: none;
    margin-left: 20px;
    margin-right: 40px;
}

.book_hotel__details input {
    font-family: 'Poppins', sans-serif;
    padding: 5px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
}

.book_hotel__details2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    text-transform: capitalize;
    /* background-color: antiquewhite; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* border-bottom: 1px dashed gray; */
}

.book_hotel__details2 h4 {
    width: 80%;
    text-align: center;
}

.receiptLogo{
    width: 120px;
    /* background-color: #2E2D4D; */
}

.receiptLogo img{
    width: 100%;
}

.book_view_more {
    margin-top: 3%;
    width: 100%;
    padding: 10px;
    background-color: #E5F7FF;
    border: none;
    color: #2E2D4D;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;


}

.book_view_more .spanDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.book_view_more_with_tax .spanDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.book_view_more_with_tax {
    /* margin-top: 4%; */
    /* width: 95%; */
    padding: 10px;
    background-color: #E5F7FF;
    border: none;
    color: #2E2D4D;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    margin-top: 1.5%;
}

.subTotal {
    margin-top: 1.5%;
}

.orderbreakDownDiv {
    /* background-color: #EEEEEE; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

}

.deliveryInfo {
    border: 5px solid #E5F7FF;
    border-radius: 5px;
}

.deliveryText {
    padding: 10px 15px 10px 15px;
    font-size: 0.8em;
}

.book_view_more_with_tax .spanDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.backToHome {
    padding: 10px;
    /* background-color: #E5F7FF; */
    border: none;
    color: #2E2D4D;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
}

.getBackBtn {
    border: none;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #000000;
    /* background: linear-gradient(to bottom, rgba(16, 14, 123, 0.7), rgba(16, 14, 123, 0.7) 45%); */
    color: white;
    transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.getBackBtn:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    /* Add nice shadow effect on hover */
    transform: translateY(-5px);
    /* Slightly move the element upwards */
}

.saveIcon {
    font-size: 2em;
    cursor: pointer;
}

.saveIconDiv {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    border-bottom: 3px solid #E5F7FF;
    /* padding-right: px; */
    font-size: 0.8em;
    transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.saveIconDiv:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    /* Add nice shadow effect on hover */
    transform: translateY(-5px);
    padding: 0px 3px 0px 0px;
    border-radius: 5px;
}

.watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../images/newLogo.png");
    background-size: 50%;
    /* Adjust size as necessary */
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
    /* Adjust transparency */
    z-index: -1;
    /* Ensure watermark stays behind content */
}



.shareIconDiv {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 0.8em;
    border-bottom: 3px solid #E5F7FF;
    transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.shareIconDiv:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    /* Add nice shadow effect on hover */
    transform: translateY(-5px);
    padding: 0px 3px 0px 0px;
    border-radius: 5px;
}

.shareIconspan {
    background-color: #E5F7FF;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.8em;
}





@media only screen and (min-width: 1600px) {
    /* Your styles here */
}

@media only screen and (max-width: 1500px) {
    .parentAirtimetopsuccess {


        display: flex;
        margin-bottom: 17%;

    }

    .book_form_room_list {
        width: 60%;
        margin: 10% 3% 0% 3%;
    }
}

@media screen and (max-width: 768px) {
    .book_form_room_list {
        width: 100%;
        margin: 18% 3% 15% 3%;
    }

    .book_hotel__details2 {
        font-size: 0.7em;
        color: green;
    }
}

/* @media only screen and (min-width: 1200px) {
    
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
   
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
   
}

@media only screen and (max-width: 575.98px) {
  
} */