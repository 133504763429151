
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.mainLearnHead{

  background-image: url('../images/p2.jpg');
  background-size: cover;
}
.choosinTexT{
  font-size: 30px;
  font-weight: bold;
}
.selectDDiv{
  width: 100%;
  background-color: #F2F2F2 !important;
  height: 40px;
  border-radius: 5px;
  border: rgb(204, 198, 198) solid 1px;
  color: black;
  
}
.removecat {
  font-weight: bold;
  color: red;
  cursor: pointer;
  
}

.containFAQ{
  padding-left: 10%;
  padding-right: 10%;
}
.removeARea{
  font-weight: bold;
  color: red;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 5px;
}
/* .centerGetstart{
  text-align: center;
  font-size: 40px !important;
} */
/*  */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: -100px !important;

}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #2BC5F5}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}



.need-help-div{
    display: flex;
    justify-content: space-between;
    /* background-color: #100E7B; */
    color: #100E7B;
    font-weight: bold;
    flex-wrap: wrap;
    padding: 10px;
  }
  .mark-div{
    height: 20px;
    border: 2px solid #2BC5F5;
    width: 2px;
  }
  .logo-adjust{
    width: 80px;
    margin-top: 5px;
  }
  .menu-back{
      background-color:#100E7B ;
      color: white;
      margin-top: 11%;
      padding: 10px;
      width: 200px;
      border-radius: 3px;
    border: none;
  }
  .reserve-back{
    background-color:#100E7B ;
    color: white;
    padding: 10px;
    margin-top: 5%;
    width: 200px;
    border-radius: 3px;
    border: none;
    
  }
  .getstarted-back{
    background-color:#100E7B ;
    color: white;
    padding: 10px;
    margin-top: 20%;
    border-radius: 3px;
    border: none;
  }
  .control-menu-div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .select-locationcover{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .locatioN-div{
    justify-content: center;
    text-align: center;
  }
  .locatioN-div button{
    background-color: #100E7B;
    color: white;
    border: none;
    padding: 10px;
    width: 200px;
    border-radius: 3px;
  }
  .locatioN-div p{
    
    /* width: 200px; */
  }
  .con-Center{
    text-align: center;
    font-weight: bold;
    color: #100E7B;
   font-size: 20px;
  }
  .needH{
    margin-left: 15%;
    padding: 5px;
  }
  .lineinneed{
    border: #100E7B solid 1px;
    width: 50px;
  }
  .congsm{
    padding: 20px;
  }

  .main-footer {
    /* margin-top: 10%; */
    padding: 70px 0;
    display: flex;
    justify-content: space-evenly;
    background-color:#100E7B;
  }
  
  .main-footer ul {
    list-style: none;
  }
  
  .main-footer h1 {
    font-size: 22px;
    line-height: 117%;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .main-footer h4 {
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
  }
  
  .main-footer ul li a {
    color: #ffffffcc;
    text-decoration:none;
  }
  
  footer {
    background-color: #100E7B;
    border-top: 1px solid white;
    font-size: 17px;
    padding: 15px 5px;
    color: #ffffff;
    text-align: center;
  }
  
  footer a {
    text-decoration: none;
    color: #ffffff;
  }
  .firCover{
    background-color:#0A074E ;  
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logoinfo p {
    color: white;
    font-size: 17px;
    margin-top: 5px;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-details li {
    list-style: none;
    margin: 10px 0;
  }
  .contact-details p{
    font-style: italic;
  }
  
  .contact-details li a {
    text-decoration:none;
    color: #f1f1f1;
  }
  
  .contact-details .fa {
    color: #f1f1f1;
    margin-right: 10px;
  }
  
  .sociallogos{
    padding:20px 0;
  }
  .askIcon{
    /* margin-top: 20px; */
    position: absolute;
    margin-left: -2%;
    margin-top: 0.2%;
   }
  .cardfaq{
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 0px 0px  rgba(0, 0, 0, 0.25);
    display: flex;
    padding-top: 3%;
    flex-direction: column;
    justify-content: space-between !important;
  }
  .cardfaq h4{
    cursor: pointer;
  }

  .sociallogos .logobox a{
    padding:0 10px;
    text-decoration:none;
    color:#ffffff;
    font-size:22px;
  }
  .containfaq-title{
    text-align: center;
  }
  
  .containfaq-title h1{
    padding: 10px;
  }
  .cardfaqli{
    display: flex;
    justify-content: space-between;
 
    padding: 5px;
    padding-left: 20px;
  }
  .vitelsAnswer{
    
    text-align: start;
    padding: 5px;
    padding-left: 20px;
  }
  .learnhead{
    text-align: center;
  }
  
  /* .cardfaq p{
  text-align: start;
  } */
  
  .com ul li{
    padding:5px 0;
  }
  /* .footerSet{
    margin-top: 10%;
  } */
  .imageLOgo{
 width: 30px;
 background-color: white;
 padding: 2px;
  }


.btwide{
  width: 100%;
  height: 45px;
}
.btwide option{
  background-color: white;
  text-align: start !important;
color: black !important;
  padding: 10px;
}
.btwide option :hover{
  background-color: #100E7B;
}
.looking-vitel{

  font-size: 40px;
  
}
.backimageLOgo{
  background-color: white;
  padding: 10px;
}
.locatioN-div p{
   font-weight: bold;
}
.classicss{
  height: 40px !important;
}


.button-15 {
  background-image: linear-gradient(#2EC2F5, #100E7B);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
 width: 300px;
  overflow: visible;
  /* padding: 4px 15px; */
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15:disabled {
  cursor: default;
  opacity: .3;
}

.button-15:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.button-15:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}

.button-15:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}

/* reserve button style */

.button-15-reserve {
  background-image: linear-gradient(#2EC2F5, #100E7B);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
 width: 300px;
 height: 50px;
  overflow: visible;
  /* padding: 4px 15px; */
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15-reserve:disabled {
  cursor: default;
  opacity: .3;
}

.button-15-reserve:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.button-15-reserve:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}

.button-15-reserve:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}







/* select button style */
.button-15-select {
  background-image: linear-gradient(#2EC2F5, #100E7B);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-family: "SF Pro Text","SF Pro Icons","AOS Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -.022em;
  line-height: 1.47059;
 
 
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15-select:disabled {
  cursor: default;
  opacity: .3;
}

.button-15-select:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.button-15-select:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}

.button-15-select:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}


  @media(max-width: 1700px){
    .needH{
      margin-left: 8%;
      padding: 5px;
    }
  } 

  @media(max-width: 1200px){
    .askIcon{
      /* margin-top: 20px; */
      position: absolute;
      margin-left: -3%;
      margin-top: 0.2%;
     
    }
  }
  @media(max-width:993px){
    .dropdown-content {
     
      z-index: 1;
      margin-left: -150px !important;
    
    }
    .containfaq-title{
      margin-top: 8% !important;
     }
  }
  
  @media only screen and (max-width: 749px) {
    .main-footer {
      padding:20px;
      display:grid;
      grid-template-columns: 1fr 1fr;
    }
      .info{
        padding:20px 0;
    }
    .askIcon{
      /* margin-top: 20px; */
      position: relative !important;
      margin-left: 0%;
      margin-top: 0%;
     
    }
    .dropdown-content {
     
      z-index: 1;
      margin-left: -170px !important;
    
    }
  .rmmark{
      display: none;
    }
    .select-locationcover{
      display: grid;
     
      justify-content: center !important;
    }
    .dropdown_hideMobile{
      display: none !important;
    }
    .button-15-reserve {
    
     width: 200px;
     height: 50px;
    
    }
  }
  
  @media (max-width: 480px) {
    .main-footer {
      grid-template-columns: 1fr;
    }
    .sociallogos{
      padding:20px 0;
    }
    .com{
      padding:20px 0;
    } 
  
  }
  

 
