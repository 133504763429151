.loaderButton {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.avaiRed {
  color: rgb(185, 79, 79);
}

/* CSS */
.button-65 {
  appearance: none;
  backface-visibility: hidden;
  background-color: #2f80ed;
  border-radius: 10px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 14px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-65:hover {
  background-color: #1366d6;
  box-shadow: rgba(0, 0, 0, 0.05) 0 5px 30px, rgba(0, 0, 0, 0.05) 0 1px 4px;
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0.35s;
}

.setDivPayInput {
  width: 100%;
}

.button-65:hover:after {
  opacity: 0.5;
}

.button-65:active {
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 6px 0, rgba(0, 0, 0, 0.1) 0 0 10px 0,
    rgba(0, 0, 0, 0.1) 0 1px 4px -1px;
  transform: translateY(2px);
  transition-duration: 0.35s;
}

.button-65:active:after {
  opacity: 1;
}

@media (min-width: 768px) {
  .button-65 {
    padding: 14px 22px;
    width: 176px;
  }
}

/* CSS */

.button-70-picked {
  background-image: linear-gradient(#9acd32, #a0c6f3);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 0.9em;
  margin: 5px;
  padding: 5px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.cum-error {
  color: red;
}

.image-Empty {
  width: 200px;
}

.button-70 {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;

  font-size: 0.9em;
  margin: 5px;
  padding: 5px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.numDisplayBold {
  font-weight: bold;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #0067b9;
  border-bottom-color: #100e7b;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderSimpler {
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 5px;
}

.seLectHead {
  text-align: center;
}

.labelText {
  font-size: 15px;
  font-weight: bold;
}

.red-text {
  color: red;
}

.selectmain {
  /* styling */
  background-color: white;
  border: thin solid #100e7b;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 100% !important;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.moveFooterRes {
  /* margin-top: 10%; */
}

.secTech {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: white;
  background-image: none;
  cursor: pointer;
  border: #0067b9 solid 1px;
  color: #0a074e;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}

/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
}

/* Transition */
.select:hover::after {
  color: #0067b9;
}

/* Other styles*/

/* 가운데정렬 - flexbox*/
/* 근데 이건 1행 정렬이된다. */
/* .container {
    display: flex;
    justify-content: center;
    align-items: absolute;
  } */
.container {
  z-index: 50;
}

.down_note {
  display: flex;
  justify-content: center;
}

/* arrows */

select.classic {
  background-image: linear-gradient(45deg, transparent 50%, #100e7b 50%),
    linear-gradient(135deg, #100e7b 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

select.classic:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, #100e7b, #100e7b);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: #100e7b;
  outline: 0;
}

.button-15-s {
  background-image: linear-gradient(#2ec2f5, #100e7b);
  border: 1px solid #0077cc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  width: 200px;
  margin-top: 2%;
  direction: ltr;
  display: block;
  margin-left: 70%;
  overflow: visible;
  padding: 10px 15px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15-s:disabled {
  cursor: default;
  opacity: 0.3;
}

.button-15-s:hover {
  background-image: linear-gradient(#51a9ee, #147bcd);
  border-color: #1482d0;
  text-decoration: none;
}

.button-15-s:active {
  background-image: linear-gradient(#3d94d9, #0067b9);
  border-color: #006dbc;
  outline: none;
}

.button-15-s:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}

.btnSetselect {
  /* background-color: #51A9EE; */
}

.backFOrmcart {
  background-color: #E9ECEF;
  width: 100%;
}

.form-reserve {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  padding-top: 10%;
  flex-direction: column;
  padding: 20px;

}

@media (max-width: 650px) {
  .button-15-s {
    margin-left: 20%;
  }
}

@media (min-width: 1025px) {
  .h-custom {
    height: 100vh !important;
  }
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.setBAckgroud {
  background-color: #FAFAFA;
  height: 50vh;
  position: absolute;
  width: 100% !important;
  z-index: -20 !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-registration .select-arrow {
  top: 13px;
}

.fw-normal {
  font-size: 20px !important;
  font-weight: bold;
}

.img-fluid {
  width: 100px;
}

.removecat {
  font-weight: bold;
  color: red;
  cursor: pointer;

}

.cdreserve {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  /* padding-top: 5%; */
  flex-direction: column;
}

.specialText {
  background-color: #32c1f6;
  padding: 3px;
  text-align: center;
  border-radius: 3px;
  font-style: italic;
}

.bttt {
  background-color: #100e7b;
  color: white;
}

.cciConPick {}

.ctAmount {
  /* background-color: #0077CC; */
  text-align: center;
  font-weight: bold;
}

.dsetamount {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ddSEt {
  justify-content: center;
  text-align: center;
}

.ccdelete {
  padding: 5px;
  width: 100% !important;
  /* position: absolute; */
  justify-content: center;
  text-align: center;
}

.blockHead:after {
  color: #32c1f6;
  border-right: 20px solid;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  left: -20px;
  padding-left: 2px;
}

.blockHead {
  background-color: #32c1f6;
  /*width: 150px; */
  height: 40px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.blocktext {
  color: white;
  font-weight: bold;
  padding-left: 15px;
  font-family: Arial;
  /* font-size:15; */
  line-height: 40px;
  padding: 5px;
  /* vertical-align: middle; */
}

.reserVHead {
  font-size: 50px;
  color: #32c1f6;
  font-weight: bold;
  font-weight: bold;
}

.reserVtxt {
  /* font-size: 35px; */
  background-color: rebeccapurple;
  width: 50%;
  font-weight: bold;
  color: white;
  text-indent: 5px;
}

.hereModal {
  font-weight: bold;
  color: #9acd32;
  cursor: pointer;
}

.checkbox-wrapper-24 {
  font-size: 20px;
  padding: 5px;
}

.mshiftb {
  margin-left: 1%;
}

.selectedy {
  padding: 5px;
}

.reserVtxtinteret {
  font-size: 20px;
  font-weight: bold;
  color: #32c1f6;
}

.ktReserveback {
  background-size: cover;
  width: 100%;

  /* height: 40vh; */
}

.backsetorder {
  height: 70vh;
  background-image: url('../../images/changeRR2.jpg');
  background-size: cover;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backsetLearn {
  background-image: url('../../images/p2.jpg');
  background-size: cover;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backset {
  background-color: rgba(0, 0, 0, 0.61);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  /* padding: 5px; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adjustEasy {
  font-size: 25px;
  font-weight: bold;
  display: block !important;
}

.emptyCartDIv {
  justify-content: center;
  text-align: center;
  padding: 20px;
  padding-top: 5%;
}

.rradio {
  width: 100px;
}

.setHeaderCart {
  margin-top: 4%;
}

.radio-item [type='radio'] {
  display: none;
}

.radio-item+.radio-item {
  margin-top: 15px;
}

.radio-item label {
  display: block;
  padding: 20px 60px;
  background: #1d1d42;
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  min-width: 250px;
  /* white-space: nowrap; */
  position: relative;
  transition: 0.4s ease-in-out 0s;
}

.radio-item label:after,
.radio-item label:before {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after {
  height: 19px;
  width: 19px;
  border: 4px solid #32c1f6;
  left: 1.6%;
  top: calc(50% - 20%);
}

.radio-item label:before {
  background: #32c1f6;
  height: 20px;
  width: 20px;
  left: 1.6%;
  top: calc(50%-50%);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out 0s;
}

.radio-item [type='radio']:checked~label {
  border-color: #32c1f6;
  /* border: solid 3px; */
}

.radio-item [type='radio']:checked~label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1.5);
  margin-top: 5px;
}

.checkSuccess {


  width: 100% !important;
}

.datSEt-text {
  color: #0dccea;
}


.quizDown {
  margin-top: 2% !important;
}

.quiz-window {
  /* position: absolute; */
  border-radius: 0.25rem;
  box-shadow: 0 0px 40px -14px rgba(0, 0, 0, 0.25);

  left: 0;
  right: 0;
  top: 50px;
  margin: auto;
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
}

.quiz-window-header {
  padding: 10px 15px;
  text-align: center;
  position: relative;
}

.quiz-window-title {
  font-size: 20px;
  font-weight: bold;
  text-align: start !important;
}

.setDivided {
  display: flex;
  gap: 10%;
}

.provides {
  z-index: 100 !important;
}

.quiz-window-close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background: none;
  border: 0;
  width: 30px;
  height: 30px;
  font-size: 60px;
  font-weight: 100;
  line-height: 24px;
  color: #777;
  cursor: pointer;
}

.quiz-window-body {
  background-color: #f9f9f9;
  /* margin-top: 1%; */
}

.guiz-awards-row {
  margin: 0;
  padding: 10px 40px;
  list-style: none;
}

.guiz-awards-row:after {
  content: '';
  display: table;
  clear: both;
}

.guiz-awards-row-even {
  background-color: #fff;
}

.guiz-awards-row li {
  display: inline-block;
  vertical-align: top;
  float: left;
}

.stycenter {
  text-align: center !important;
  justify-content: center;
  font-size: 20px;
  font-style: italic;
  padding: 5px;
}

.guiz-awards-header {
  text-align: center;
  padding: 20px 40px;
}

.guiz-awards-star,
.guiz-awards-track,
.guiz-awards-time,
.guiz-awards-header-star,
.guiz-awards-header-track,
.guiz-awards-header-time {
  min-width: 54px;
  text-align: center;
  width: 16%;
}

.guiz-awards-header-star,
.guiz-awards-header-title,
.guiz-awards-header-track,
.guiz-awards-header-time {
  font-weight: bold;
}

.guiz-awards-title {
  width: 40%;
  min-width: 160px;
  font-size: 18px;
  font-weight: normal;
  padding-top: 3px;
  font-weight: bold;
}

.guiz-awards-header-title {
  width: 40%;
  min-width: 160px;
}

.ytkLEft {
  margin-left: 50% !important;
  cursor: pointer;

}

.guiz-awards-subtitle {
  color: #858585;
  font-size: 14px;
  font-weight: 300;
}

.guiz-awards-track,
.guiz-awards-time {
  width: 22%;
  min-width: 80px;
  font-size: 18px;
  line-height: 45px;
}

.guiz-awards-header-track,
.guiz-awards-header-time {
  width: 22%;
  min-width: 80px;
}

.guiz-awards-track .null,
.guiz-awards-time .null {
  color: #bababa;
}

.star {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #bdc2c1;
  background: #d6d6d6;
}

/* .goldstar {
        border-color: #4c8193;
        background: #14b0bf;  
      }
      .silverstar {
        border-color: #557e3a;
        background: #66931f;  
      }
      .bronzestar {
        border-color: #998247;
        background: #aa984b;  
      }
      .rhodiumstar {
        border-color: #743a7f;
        background: #a0409d;
      }
      .platinumstar {
        border-color: #10393b;
        background: #2b5770;
      }
       */
.guiz-awards-buttons {
  background: #fff;
  text-align: center;
  padding: 10px 0;
}

.guiz-awards-but-back {
  display: inline-block;
  background: none;
  border: 1px solid #32c1f6;
  border-radius: 21px;
  padding: 7px 40px 7px 20px;
  color: #32c1f6;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.guiz-awards-but-back:hover {
  background: #32c1f6;
  color: #fff;
}

.guiz-awards-but-back i {
  font-size: 26px;
  line-height: 17px;
  margin-right: 20px;
  position: relative;
  top: 2px;
}

.yesRedback {
  background-color: red;
}

.guiz-awards-but-back2 {
  display: inline-block;
  background: none;
  border: 1px solid #0a074e;
  border-radius: 21px;
  padding: 2px;
  color: #32c1f6;
  background-color: #0a074e;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 150px;
  height: 40px;
}

.guiz-awards-but-back2:hover {
  background: #0a074e;
  color: #fff;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 3rem;
}

.wrapper {
  max-width: 75rem;
  width: 100%;
  padding: min(3rem, 5vw);
}

/* form {
        padding: 1.5rem;
        background-color: rgba(245, 245, 245);
        border-radius: 0.5rem;
      } */

legend {
  font-weight: 700;
}

fieldset>*+* {
  margin-top: 0.5rem;
}

form>*+* {
  margin-top: 1.5rem;
}

.form__group {
  display: flex;
  align-items: center;
}

.input[type='checkbox'],
.input[type='radio'] {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.65rem;
}

.input[type='range'],
progress {
  margin-left: auto;
  flex: 0 1 50%;
}

form:nth-child(2n) {
  color-scheme: dark;
}

.shiftForm {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.justifySubmit {
  display: flex;
  justify-content: space-around !important;
  flex-wrap: wrap;

}

.removeARea {
  font-weight: bold;
  color: red;
  cursor: pointer;


}

.justifySubmited {
  display: flex;
  /* background-color: #006dbc; */
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;

}

.textPushCEnter {
  text-align: center;
}

.setTexts {
  font-size: 18px;
}

.setTextsNum {
  font-weight: bold;
}

.secureImage {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.youHavemade {
  text-align: center;
}

.setlogolo {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ogaSecure {
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.ogaSecure p {
  width: 200px;
}

.listhair li {
  cursor: pointer;
  font-weight: bold;
}

.listhair li:hover {
  color: #2ec2f5;
}

.vitelHeaderB {
  font-size: 40px;

  text-align: center;
}

.vtBackIcon {
  background-color: #006dbc;
}

.vtBackIcon-icon {
  position: absolute;
  margin-left: -5px;
}

.didd {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.setDivide {
  display: flex;
  /* width: 100%; */
  /* justify-content: space-evenly; */

}

.sceModal {
  width: 100% !important;
}

.amountSt {
  margin-left: 3%;
  font-weight: bold;
}


table {
  width: 100%;

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    td {
      height: 50px;
      vertical-align: middle;
      padding: 8px;

      span {
        display: block;
      }

      &.td-1 {
        width: 20px;

        span {
          width: 20px;
          height: 20px;
        }
      }

      &.td-2 {
        width: 50px;

        span {
          background-color: rgba(0, 0, 0, .15);
          width: 50px;
          height: 50px;
        }
      }

      &.td-3 {
        width: 400px;

        /* padding-right: 100px; */
        span {
          height: 12px;
          background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
          background-size: 500px 100px;
          animation-name: moving-gradient;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }

      &.td-4 {}

      &.td-5 {
        width: 100px;

        span {
          background-color: rgba(0, 0, 0, .15);
          width: 100%;
          height: 30px
        }
      }
    }
  }
}














@media (max-width: 1700px) {
  .radio-item [type='radio']:checked~label::before {
    opacity: 1;
    visibility: visible;
    transform: scale(1.5);
    margin-top: 10px;
  }
}

@media (max-width: 993px) {
  .reserVHead {
    font-size: 40px;
    color: #32c1f6;
    font-weight: bold;
    font-weight: bold;
  }

  .setHeaderCart {
    margin-top: 9%;
  }

  .ogaSecure {
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin-top: 5px;
  }

  .reserVtxt {
    font-size: 30px;
  }

  .reserVtxtinteret {
    font-size: 20px;
  }

  .adjustEasy {
    font-size: 20px;
  }

  .radioShowLabel {
    font-size: 15px !important;
  }

  .mshiftb {
    font-size: 15px;
  }

  .quiz-window-title {
    font-size: 15px;
    font-weight: bold;
  }
}

@media (max-width: 993px) {
  .radio-item [type='radio']:checked~label::before {
    opacity: 1;
    visibility: visible;
    transform: scale(1.5);
    margin-top: 0%;
  }

  .quiz-window-body {
    /* background-color: #cc0a0a; */

    width: 100% !important;

  }
  .tablus{
    width: 100% !important;

  }

  legend {
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }

  .vitelHeaderB {
    font-size: 25px;
    padding: 5px;
  }

  .table {
    display: block;
   width: 100% !important;
    margin: 0 auto;
    overflow-x: scroll;
    white-space: nowrap;


  }

  .guiz-awards-but-back {
    margin-top: 2%;
  }

  .mshiftb {
    font-size: 12px;
  }

  .hideTypeso {
    display: none !important;
  }

  .reserVtxt {
    font-size: 20px;
  }

  .reserVtxtinteret {
    font-size: 15px;
  }

  .reserVHead {
    font-size: 30px;
  }

  .berriespie {
    padding: 5px !important;
  }

  .setDivided {
    display: grid;

  }

  .ktReserveback {
    overflow: hidden !important;
    /* background-color: #0077cc !important; */

  }

  /* #premiumSection{
    display: none !important;
  } */

  .setDivide {
    display: grid;
    width: 100%;
    gap: 10%;
    padding: 15px;
  }

  .breakColumDiv {
    display: grid !important;
    padding: 15px;
    margin-top: -15px !important;
  }

  .tableCARt {
    width: 100% !important;
  }

  .down-fielDset {
    margin-top: 17%;
  }
  .emptyCartDIv {
   
    padding-top: 10%;
  }
  .hideMM{
    display: none;
  }

  table {
    width: 90%;

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, .1);

      td {
        height: 50px;
        vertical-align: middle;
        padding: 8px;

        span {
          display: block;
        }

        &.td-1 {
          width: 20px;

          span {
            width: 20px;
            height: 20px;
          }
        }

        &.td-2 {
          width: 50px;

          span {
            background-color: rgba(0, 0, 0, .15);
            width: 50px;
            height: 50px;
          }
        }

        &.td-3 {
          width: 400px;

          /* padding-right: 100px; */
          span {
            height: 12px;
            background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
            background-size: 500px 100px;
            animation-name: moving-gradient;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
          }
        }

        &.td-4 {}

        &.td-5 {
          width: 100px;

          span {
            background-color: rgba(0, 0, 0, .15);
            width: 100%;
            height: 30px
          }
        }
      }
    }
  }





}