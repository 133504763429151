@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentDataPanDiv {
    margin-bottom: 5%;
    font-family: 'Montserrat', sans-serif;

}

.dataSectionHero {
    /* margin-top: 5%; */
    background: url("../../images/bannerincreasedBlue.png");
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: cover  ; */
    /* background-attachment: fixed; */
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom-left-radius: 1000px;
    /* box-shadow:
            inset 0px -50px 50px rgba(60, 70, 85, 0.5),
            inset 0px -50px 50px rgba(60, 70, 85, 0.5),
            inset 0px -70px 70px rgba(0, 0, 0, 1); */
}

/* .dataSectionHero::after {
    content: '';
    position: absolute;
    top: 93%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8%;
  
    background: url("../../images/getSecuredBck.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;

} */

.dataPlanText {
    width: 70%;
    height: 60%;

    /* background-color: aliceblue; */
}

.dataPlanTextParagragh h3 {
    font-size: 3em;
    font-weight: 800;
    color: white;
    font-family: 'Montserrat', sans-serif;
    
}


.dataPlanTextParagragh {
    font-family: "Poppins", sans-serif;
    /* background-color: rgba(0, 0, 0, 0.4); */
    background: rgba(255, 255, 255, 0.2);
        border-radius: 15px;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    padding: 25px;
    color: #F7F9FB;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-weight: 300;
    gap: 10px;
    /* font-size: 0.9em; */
}

.mainSectionDataPlan {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../../images/getSecuredBck.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.dataPlanCatDiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    /* background-color: antiquewhite; */
    padding: 30px 30px 30px 30px;
    border-bottom: #0A074E 2px solid;
}

.datatypeDiv {
    /* border: 2px solid #0A074E; */
    color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 400;
    text-transform: capitalize;
    cursor: pointer;
    box-shadow: rgba(30, 43, 106, 0.6) 0px -23px 25px 0px inset,
            rgba(30, 43, 106, 0.6) 0px -36px 30px 0px inset,
            rgba(30, 43, 106, 0.6) 0px -79px 40px 0px inset,
            rgba(30, 43, 106, 0.06) 0px 2px 1px,
            rgba(30, 43, 106, 0.09) 0px 4px 2px,
            rgba(30, 43, 106, 0.09) 0px 8px 4px,
            rgba(30, 43, 106, 0.09) 0px 16px 8px,
            rgba(30, 43, 106, 0.09) 0px 32px 16px;
}

.activeDatatypeDiv {
    background-color: #0A074E;
    color: #F7F9FB;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.mainDataPlanCard {
    padding: 50px 30px 50px 30px;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

}