@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentActivatePlan {
    /* background-color: antiquewhite; */
    /* height: 80vh; */
    width: 100%;
    margin: 10% 0 10% 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

/* .mainPlanContainer{
    
    height: 100%;
    background-color: aquamarine;
} */

.mainPlanContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* grid-template-columns: 1fr 2fr;
    grid-auto-rows: 600px;     */
    gap: 20px;
    width: 75%;
    align-items: center;
    /* background-color: aquamarine; */
    margin-bottom: 5%;
}

.planActionDiv {
    /* height: 100% auto; */
    /* max-height: 595px; */
    min-height: 500px;
    background-color: #F7F9FB;
    border-radius: 15px;
    padding: 25px;
    width: 65%;
}

.planDescriptionDiv {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    background-color: #F7F9FB;
    /* justify-content: space-between; */

}

.planDescriptionImg {
    height: 250px;
    width: 100%;

}

.planDescriptionImg img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.dataPlanNameDiv {
    /* background-color: rebeccapurple; */
    font-size: 1.4em;
    font-weight: 600;
    width: 90%;
    height: 100%;
    padding: 20px 10px 20px 10px;

}

.mainDataPlanName {
    width: 100%;
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    font-size: 1.4em;
    /* margin-left: 10%; */
}

.mainDataPlanDescription {
    /* margin-left: 10%; */
    padding-bottom: 20px;
    width: 100%;
    font-size: 0.65em;
    font-weight: 400;
    color: #634C4C;
}

.priceValidityDiv {
    /* margin-top: 10%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40%;
    font-size: 0.7em;
    font-weight: 800;
    /* background-color: antiquewhite; */
}

.planPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-right: 3px solid #1D1220;
    width: 50%;
}

.priceDiv {
    font-size: 0.9em;
    font-weight: 300;
}

.validityDiv {
    font-size: 0.7em;
    font-weight: 300;
}

.planValididty {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    /* border-right: 3px solid burlywood; */
    width: 50%;
}

.planActionMainDiv {
    /* background-color: antiquewhite; */
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.planTittle {
    font-size: 1.3em;
    font-weight: 600;
}

.clickLogin {
    font-size: 0.9em;
    font-weight: 300;
}

.mainClick {
    font-size: 1.1em;
    font-weight: 500;
    color: #3586FF;
    cursor: pointer;
}

.enterNumberDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* gap: 5px; */
    /* background-color: #3b438a; */
}

.parentMainEnterDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.mainEnterBtnDiv {
    width: 128px;
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid gray;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    border: 1px solid #20182F;
    color: #20182F;
    font-size: 0.9em;
}

.mainEnterBtnDivActive {
    width: 128px;
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid gray;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    background-color: #20182F;
    border: 1px solid #20182F;
    color: white;
    font-size: 0.9em;
}

.enterIcon {
    font-size: 2em;
    font-weight: 500;
    /* color: #3586FF; */

}

.enterInputDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background-color: #6a6d85; */
    width: 50%;
    margin: 3% 0 3% 0;
}

.enterInputDiv label {
    font-size: 1.1em;
    font-weight: 600;
    color: #634C4C;
}

.enterInputDiv input {
    width: 70%;
    border-radius: 10px;
    height: 45px;
    padding: 10px;
    border: 1px solid #20182F;
}

.autoRenewalDiv {
    font-weight: 400;
    font-size: 1em;
    color: #634C4C;
    display: flex;
    align-items: center;
}

.renewalToggle {
    font-size: 2.3em;
    /* background-color: aquamarine; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3586FF;
    margin: 0 1% 0 1%;
}

.paymentSubtotalDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.subTotalDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.subTotalTitle {
    font-size: 1em;
    font-weight: 300;
}

.subTotalPrice {
    font-size: 1.3em;
    font-weight: 800;
}

.planAddTOCart {
    border-radius: 15px;
    width: 20%;
    height: 50px;
    padding: 10px;
    color: white;
    background-color: #20182F;
    border: 1px solid #20182F;
    cursor: pointer;
}

.planBuyNow {
    border-radius: 15px;
    width: 20%;
    height: 50px;
    padding: 10px;
    font-weight: 600;
    color: #634C4C;
    background-color: #DBDBDB;
    border: 1px solid #DBDBDB;
    cursor: pointer;
}

.linkedNumbers {
    /* background-color: #3586FF; */
    height: 120px;
    overflow-y: scroll;
    color: #634C4C;
}

.linkedNumbers ul li {
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding: 10px 5px 10px 0px;
    border-bottom: 1px solid #634C4C;
    width: 50%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.custom-radio {
    width: 17px;
    border: 1px solid #bfcada;
}

.buyItemDiv {
    /* background-color: #1A226F; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .mainPlanContainer {
        width: 100%;
        gap: 10px;
        /* background-color: aquamarine */
    }

    .planActionDiv {
        width: 68%;
    }

    .planDescriptionDiv {
        width: 30%;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .mainPlanContainer {
        width: 100%;
        gap: 10px;
        /* background-color: aquamarine */
    }

    .planActionDiv {
        width: 68%;
    }

    .planDescriptionDiv {
        width: 30%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .mainPlanContainer {
        width: 100%;
        gap: 10px;
        /* background-color: aquamarine */
    }

    .planActionDiv {
        width: 68%;
    }

    .planDescriptionDiv {
        width: 30%;
    }
}

@media only screen and (max-width: 575.98px) {
    .parentActivatePlan {
        /* height: 100vh ; */
        /* background-color: rebeccapurple; */
        margin-top: 6%;
        margin-bottom: 20%;
    }

    .mainPlanContainer {
        width: 90%;
        height: auto;
        gap: 10px;
        /* margin-bottom: 50%; */

        /* background-color: aquamarine */
    }

    .planActionDiv {
        width: 100%;
        min-height: 0;
    }

    .planDescriptionDiv {
        width: 100%;
    }

    .planBuyNow {
        width: 50%;
        padding: 4px;
    }

    .planAddTOCart {
        width: 50%;
        padding: 4px;
    }

    .enterInputDiv {
        width: 100%;
    }

    .enterInputDiv input {
        width: 80%;
    }

    .autoRenewalDiv{
       
        font-size: 0.8em;
    }

    .mainDataPlanName{
        font-size: 1.2em;
    }
}