.first-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
}

.button-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  background: linear-gradient(180deg, #3369B5 0%, #013aa3 100%);
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-10:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}
.every-sim-div{
   background-color: #100E7B; 
   color: white;
   padding: 10px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   
}
.every-sim-divs{
    background-color: #100E7B;
    margin-top: 2px; 
    
 }
 
 .looking-vitel{
  text-align: center;
  padding: 10px;
  }
  .congsm p{
    font-size: 20px;
  }
  .fitCon{
    background-image: url("./images/cane.avif");
    background-size: cover;
  }

  @media(max-width: 993px) {
    .looking-vitel{
      text-align: center;
      padding: 10px;
      font-size: 30px !important;

      }
  }

  @media(max-width:640px){

    .con-Center{
      font-size: 16px !important;
    }
    .sinText{
    font-size: 12px !important;
    padding: 3px;
    }
    .looking-vitel{
      text-align: center;
      padding: 10px;
      font-size: 20px !important;
      font-weight: bold;
      
      }
  }
 
 
