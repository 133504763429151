@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentAppListing {
    background: url("../../images/getSecuredBck.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.getStartedContainer {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.getSecured {
    /* background-color: #005f8f; */
    /* text-decoration: underline; */
    font-size: 2em;
    font-weight: 500;
    width: 50%;
    /* text-align: justify; */
}

.getSecured hr {
    border: 3px solid #005f8f;
    width: 20%;
    margin-left: 0%;

}

.getStartedContainerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rebeccapurple; */
    width: 75%;

}

.filterDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* background-color: #afb0b1; */
    width: 30%;
    gap: 6px;
}

.filterDiv label {
    /* background-color: #fff; */
    font-size: 1.2em;
    font-weight: 600;
    width: 100%;
    text-transform: capitalize;
}

.filterDiv select {
    width: 80%;
    height: 35px;
    border-radius: 5px;
}

.parentAppSampleDiv {
    display: flex;

    align-items: center;
    justify-content: center;
    /* background-color: #005f8f; */
}

.appSampleDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    /* background-color: #666; */
    width: 90%;
}

.appSampleDiv {
    /* width: 90%;
    margin-bottom: 3%;
    padding: 30px 15px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px; */
    /* 5 equal columns */
    /* grid-gap: 10px; */
    /* space between the grid items */
    /* grid-auto-rows: 200px; */
    /* uniform row height */
    /* align-items: center;
    justify-content: center; */
    /* background-color: #0F0C7C; */
}

.secureMe {
    width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    /* text-decoration: none; */
}



.secureMe2 {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.secureMe2 img {
    height: 100%;
    width: 100%;
}

.vasType {
    position: absolute;
    /* background: linear-gradient(135deg, #001f3f, #005f8f); */
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.8em;
    padding: 5px;
    background: rgba(5, 5, 5, 0.4);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

}

.secureMe-header {
    /* background: linear-gradient(135deg, #0E46A8, #005f8f); */
    color: #fff;
    text-align: center;
    /* position: relative; */
    /* padding: 20px; */

}

.secureMe-header img {
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    object-fit: cover;
    /* border: 5px solid #afb0b1; */
    margin-bottom: 8px;
}

.vasParentPriceDiv {
    position: absolute;
    background-color: #ffffff;
    width: 50%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6px 10px 8px 10px;
    border-radius: 5px;
}

.vasPrice {
    background-color: rgba(44, 32, 32, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: inset 0px -5px 15px rgba(255, 255, 255, 0.5);
    border-radius: 7px;
    padding: 1px;
    font-size: 1.1em;
    font-weight: 700;

}

.title {
    font-size: 10px;
    color: whitesmoke;
    margin-top: -15px;
}

.AppName {
    font: 28px;
    margin-top: -8px;
}

.appBody {
    padding: 20px;
    /* text-align: justify; */

}

.description {
    font-size: 15px;
    color: #666;
    line-height: 1.4;
}

.linkSecureme {
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    /* .appSampleDiv {
        grid-template-columns: repeat(3, 1fr);

    } */
}



@media screen and (max-width: 768px) {
    .appSampleDiv {
        /* grid-template-columns: repeat(2, 1fr); */
        /* 2 columns on smaller screens */
    }

    .getStartedContainerItem {
        width: 100%;
        justify-content: center;
        flex-direction: column;

    }

    .getSecured {
        width: 80%;
    }

    .getSecured hr {
        border: 1px solid #8c8e8f;
        width: 100%;
        margin-left: 0%;

    }

    .getStartedContainer {
        /* background-color: #666; */
    }

    .filterDiv {
        width: 80%;
        flex-direction: row;
    }

    .filterDiv label {
        /* background-color: #fff; */
        font-size: 1em;
        font-weight: 500;

    }

    .vasParentPriceDiv {
        top: 50%;
    }
}

@media screen and (max-width: 400px) {
    .appSampleDiv {
        /* grid-template-columns: 1fr;
        grid-auto-rows: 500px; */
        /* 1 column on mobile screens */

    }

    .secureMe {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .vasParentPriceDiv {
        top: 50%;
    }
}