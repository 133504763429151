
@import "rsuite/dist/rsuite.css";


  label, a{
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    display: inline-block;
    color: inherit;
    transition: border 0.2s;
    border-bottom: 5px solid rgba( #030334, 0.2);
    padding: 3px 2px;
    &:hover{
      border-bottom-color: #030334;
    }
  }
 
  .layout{
    display: grid;
  
    height: 100%;
    width: 80%;
    overflow: hidden;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25);
  }
  
  input[type="radio"]{
    display: none;
  }
  
  label.nav{
    display: flex;
    align-items: center;
   
    justify-content: center;
     cursor: pointer;
    border-bottom: 2px solid  #030334;
    background: #ecf0f1;
    user-select: none;
    transition: background 0.4s, padding-left 0.2s;
    padding-left: 0;
    input[type="radio"]:checked + .page + &{
      background:  #030334;
      color: #ffffff;
      padding-left: 20px;
      span{
        padding-left: 20px  
      }
      svg{
        opacity: 1;
      }
    }
    span{
      padding-left: 0px;
      position: relative;
    }
    svg{
      left: 0;
      top: -3px;
      position: absolute;
      width: 15px;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  
  .page{
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: span 3;
    padding: 0px 20px;
    display: flex;
    align-items: center;
  }
  
  .page-contents > *{
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.2s, transform 0.2s;
    @for $c from 1 through 20{
      &:nth-child(#{$c}){
        transition-delay: 0.2s + ($c * 0.2s);
      }
    }
  }
  
  input[type="radio"] + .page{
    transition: transform 0.2s;
    transform: translateX(100%);
  }
  
  input[type="radio"]:checked + .page{
    transform: translateX(0%);
    .page-contents > *{
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .page-contentsDIVS{
    width: 100%;
  }
  .myBreadMove{
    margin-left: -5%;
  }
  
  .page-contents{
    width: 100%;
    height: 150px;
    display: flex;
    gap: 2%;
   
    border: #03033450 1px solid;
    padding: 10px;
    border-radius: 3px;
   }
   
  .add-newAdress{
   font-weight: bold;
   padding: 20px;
   font-size: 17px;
   cursor: pointer;
  }

  .generalShowDelivery{
    /* background-color: aqua; */
    display: flex;
    gap: 1%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 4% !important;
  }
  
  .secondDiv-deliv{
    background-color: white;

    border-radius: 0.25rem;
    box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25);
    /* width: 20%; */
    /* padding: 10px; */
    /* width: 50%; */
    width: 20%;
   height: 300px;

  }
  .yeRadio{
    background-color: aqua;
  }
  .yepDefault{
    padding: 5px;
    background-color: darkgray;
    color: black;
    margin-top: 2%;
    text-align: center;
    border-radius: 3px;
    color: white;
  }
  .page-content-All{
    width: 80%;
    padding: 20px;
  }
  .yebViewMore{
    padding: 10px;
    margin-top: 5px;
    font-style: italic;
    border-radius: 3px;
  }
  .form-checkSS{
    border: #03033450 1px solid;
    padding: 10px;
    border-radius: 3px;
   }
.page-conte{
border: 1px solid #D9D9D9;
display: flex;
padding: 20px;
border-radius: 3px;
gap: 2%;

}
.nowAddPickup{
 margin-top: -8%; 
}
.chDesign{
  font-size: 40px !important;
}
.textONdevl{
  font-size: 18px;
}
.notetextONdevl{
  color: #006DBC;
}
.wtAddedSucess{
  font-size: 18px;
}


  .aDDresBok{
    font-size: 20px;
    padding: 10px;
  }
  .addANewA{
    font-weight: bold;
    font-size: 25px;
  }
  .shAddedAddres{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-weight: bold;
  }
  .addRESBoxffr{
    width: 100%;
  }
  .addRESBox{
    display: flex;
    gap: 3%;
    padding: 20px;
    margin-top: 1%;
    /* background-color: #0067B9; */
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 10px 8px -14px rgba(0, 0, 0, 0.25);
    width: 100% !important;
  }
  /* .aDDre{
    margin-top:-10% ;
  } */
/* CSS */
.button-COnfirm {
  background-image: linear-gradient(#42A1EC, #0070C9);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
 
  font-family: "SF Pro Text","SF Pro Icons","AOS Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -.022em;
  line-height: 1.47059;
  /* min-width: 30px; */
  width: 80%;
  margin: 0 auto;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-COnfirm:disabled {
  cursor: default;
  opacity: .3;
}

.button-COnfirm:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.button-COnfirm:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}

.button-COnfirm:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}
.nameNowSwt{
  font-size: 17px;
}
.warnPlace{
  background-color: rgb(99, 16, 16);
  color: white;
  font-size: 18px;
  width: 80%;
  padding: 5px;
  text-align: center;
}


.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #0F0C7D;
  border-bottom-color: #2BBCF1;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 



.button-COnfirmAdress {
  background-image: linear-gradient(#42A1EC, #0070C9);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
 
  font-family: "SF Pro Text","SF Pro Icons","AOS Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -.022em;
  line-height: 1.47059;
  /* min-width: 30px; */
  width: 30%;
  margin: 0 auto;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  padding: 5px;
}

.button-COnfirmAdress-COnfirm:disabled {
  cursor: default;
  opacity: .3;
}

.button-COnfirmAdress:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.button-COnfirmAdress:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}

.button-COnfirmAdress:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}




.button-COnfirm-btn{
  /* background-color: #147BCD; */
  margin-top: 2%;
}
.orDERsum{
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
}

.select-myPick {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 1rem 4rem 1rem 1rem;
  background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  color: white;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}
.lay{
  background-color: white;

  border-radius: 0.25rem;
  box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25);
  width: 80%; 

}
.setDivided{
  width: 80%;
  padding: 10px;
  border: 1px solid #D9D9D9;
  /* height: 150px; */
  height: 20vh;
  margin-left: 1%;
  border-radius: 3px;
  
}
.leClas{
  font-size: 30px !important;
}
.setDivPayInput{
  margin-top: 2%;
}

input[type="radio"]{
  display: block !important;
}

@media(max-width: 993px){
  .generalShowDelivery{
    /* background-color: aqua; */
    display: grid;
    gap: 1%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 4% !important;
  }
  .lay{
   
    width: 100%; 
  
  }
  .secondDiv-deliv{
    width: 100%;
  }
  .page-content-All{
    width: 100%;
    
  }

  .setDivided{
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
  
     height: 20vh;
    margin-left: 1%;
    border-radius: 3px;
    
  }
}

@media(max-width: 640px){
  .setDivided{
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
  
     height: 45vh;
    margin-left: 1%;
    border-radius: 3px;
    
  }
  .generalShowDelivery{
    /* background-color: aqua; */
    display: grid;
    gap: 1%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 12% !important;
  }
.divPushChange{
  display: grid;
}
.button-COnfirmAdress {
  
  /* min-width: 30px; */
  width: 50%;
  
}


}