.mangeSubAction {
    font-weight: 700;
    font-size: 1em;
    background-color: #1A9AC9;
    color: white;
    width: 50%;
    text-align: center;
    padding: 7px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.transmangeSubActionHeader{
    /* background-color: antiquewhite; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.transmangeSubActionHeader select{
    height: 35px;
    width: 250px;
    border-radius: 5px;
}

.transactionHeader {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
}

.transactionHeader h2 {
    color: #526d82;
    font-size: 1.5rem;
}

.transactionHeader select {
    border-radius: 10px;
    border: none;
    padding: 8px 12px;
    border: 1px solid #dde6ed;
    color: #526d82;
}

.transactionListDiv {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.transMainList {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
}

.transactionListDet {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #526d82;

}

.productPurchaseName {
    font-size: 1rem;
}

.productImg {
    width: 50px;
    background: #dde6ed;
    border-radius: 50px;
}

.transMainList:hover {
    background: #dde6ed;
    border-radius: 10px;
    cursor: pointer;
}

.optTd{
    position: relative;
}

.smallpopDiv{
    position: absolute;
}