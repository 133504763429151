.historyDatepick {
    height: 35px;
    width: 250px;
    border-radius: 5px;
}

.usageOptionDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.usageLabel{
    font-size: 1.1em;
    font-weight: 700;
    text-transform: capitalize;
}