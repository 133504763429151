@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.parentTopupreceipt {
  height: 100vh;
  width: 100%;
  display: flex;

  margin-bottom: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: Arial, Arial Nova, and Gill Sans;
}

.topupreceiptdiv {
  width: 50%;
  margin-top: 5%;
  background-color: #f8f8fa;
  padding: 20px;
}

.recieptFirstItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  gap: 15px;
  border-radius: 15px;
  padding: 15px 10px 15px 10px;
}
.network {
  height: 20%;
  width: 20%;
}

.network img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.topupstatus {
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.topupIcon {
  font-size: 1.5em;
}

.transBonusdiv {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.topuptransacdetial {
  background-color: white;
  /* gap: 15px; */
  border-radius: 15px;
  padding: 15px 10px 15px 10px;
  margin-top: 1.5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.parenttransacDiv {
  width: 70%;
}

.topuptransacdetial h4 {
  /* background-color: aquamarine; */
  width: 70%;
  padding: 10px;
}
.getBackBtn-bt{
  background-color: #174AAF;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.transdiv {
  /* background-color: antiquewhite; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .topupreceiptdiv {
    width: 100%;
    margin-top: 15%;
 
    padding: 10px !important;
  }

  .transBonusdiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .parenttransacDiv {
    width: 100%;
  }
  .topuptransacdetial h4 {
    /* background-color: aquamarine; */
    width: 100%;
    padding: 10px;
  }
 
  
}
