
.pgContentPush{
    margin-top: 0% !important;
}
.moreADDed{
    margin-left: 5%;
    margin-top: 5%;
    padding: 10px;
}
.saveLIne{
  border: 2px solid;
  width: 70px;
  height: 1px;
  border-radius: 5px;
  color: #2BBDF1;
}
.fetchValueText{
  font-size: 30px;
  font-weight: 800;  
}

@media(max-width: 993px){
   .pgContentPush{
        margin-top: 0% !important;
    }
    .moreADDed{
        margin-left: auto;
        margin-top: 15%;
        padding: 10px;
    }
    .fetchValueText{
        font-size: 18px;
        font-weight: 900;  
      }
}