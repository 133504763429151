.p-and-p-backdrop{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color:#E8F8FD;
    display:grid;
    justify-items: center;
}
.p-and-p-title{
    color: black;
    font-weight: 900;
    font-size: 35px;
}
.p-and-p-backdrop .after-50px:after{
    left: 102px
}
.p-and-p-grid{
    padding: 20px 12%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    column-gap: 20px;
}
.policy{
    background-color: white;
    padding: 30px 30px;
    border-radius: 15px;
    height: fit-content;
}
.paragraphTitle {
    color: black;
    font-size: 34px;
}

.subHeader-text {
    font-size: 20px;
    color: #0169ef;
}
.policy:hover{
    margin-top: -10px;
    transition: all .2s ease-out;
}

@media (max-width: 905px) {
    .p-and-p-grid{
        grid-template-columns: 1fr;
    }
}

.pText{
    color: white;
}
