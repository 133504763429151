:root {
  --text-primary-color: black;
  --background-primary-color: #18649e;
  --background-secondary-color: #1A5FC2;
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  position: relative;
  z-index: -1;
}

.banner {
  /* background: var(--background-primary-color); */
  /* background: linear-gradient(320deg, #1A5FC2 55%, #2196F3 20%, white 10% ); */
  background-image: url("../../images/family_banner2[1].png");
  min-height: 70vh;
  /* background-size: cover; */


  font-family: Arial, Helvetica, sans-serif;
}

/* .saint-center{
    text-align: center !important;
    margin-top: 5% !important;
    z-index:100 !important ;
    
  } */
.banner img {
  display: none;
}

.howITworks_HEad h2 {
  text-align: center;
  font-size: 20px;

}

.sendDown {
  display: none;
}

.sendUp {}

.mobDown {
  display: none;
}





.banner .content {

  height: 100%;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.imgSet-Family {
  width: 500px;
  z-index: -10 !important;
  position: absolute;
}

.hwStep {}

.hwStep p {
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  /* background-color: #18649e; */
  /* justify-content: center !important;
    text-align: center !important; */
  /* background-color: #18649e; */
  margin-left: -10% !important;
}

.hwStepBody {}

.allCoverhwstep {
  padding-left: 5%;
  padding-right: 5%;
}

.iiIMgwork {
  width: 100%;
  height: 100%;
}

.iiIMgwork img {
  width: 150px;
}

.hwStepBody img {
  width: 400px;
}

.hwStepBody3 img {
  width: 360px;
}

.iiIMgworkDiv {
  text-align: start;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.allCoverhwstep {
  /* display: flex; */
  /* background-color: antiquewhite; */
  justify-content: center;
  text-align: center;
}

.setAllhwStep {
  /* background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.25); */
  padding: 20px;
  height: 350px;
}

.howITworks_HEad .proVide-line {
  border: #1A5FC2 2px solid;
  height: 2px;
  width: 250px;
  margin: 0 auto;
  border-radius: 5px;

}

.setSalediv-card {
  display: flex !important;
  padding-left: 5%;
  padding-right: 5%;
  /* background-color: #05B0E3; */
  justify-content: space-around !important;
  gap: 50px;
   /* background-image: url("../../images/paper.jpg"); */
   background-size: cover;

}

.setSalediv {

  width: 50%;
  padding: 10px;
}

.setSalediv-image-div {
  width: 50%;

}

.setSalediv-image {
  width: 100%;
  /* height: 70vh; */
  text-align: center;
  justify-content: center;
  margin-top: 3%;

}

.setSalediv h2 {
  font-size: 25px !important;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
}

.setSalediv p {
  font-size: 20px !important;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
}

.setSalediv ul {
  font-size: 20px !important;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
}


.containerPall {
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.background-container {
  background-image: url(https://www.se-coastalgroup.org.uk/wp-content/gallery/images/diggers-sunk-seasalter.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 100px !important;
  padding-top: 100px !important;
  text-align: center !important;
}

.background-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(23, 27, 38, .92)), 
  to(rgba(39, 45, 62, .92)));
  background-image: linear-gradient(to top, #1E83E2 0%, #00248E 100%);
}

.background-cover {
  z-index: 2;
  position: relative;
  color: #fff;
}

.line-heading {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.line-heading-left {
  text-align: right !important;
  margin: 0;
  padding: 0;
}

.line-heading-left h2 {
  color: #fff;
  font-size: 2.625rem;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
  line-height: 1.1;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

.line-heading-left h4 {
  font-size: 1.75rem;
  font-weight: 300;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
  color: #05B0E3;
  line-height: 1.1;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

.line-heading p {
  margin-left: 38px;
  margin-top: 0;
  border-left: 3px solid #05B0E3;
  padding: 12px 0 12px 30px;
  line-height: 24px;
  -webkit-box-flex: 1;
  flex: 1;
  font-size: 1rem;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
  color: #bfbfbf;
  text-align: left;
  margin-bottom: 0;
}

.bans-text {
  color: white;
  padding: 10px;

}
.everyTinP{
  color: white;
  font-size: 30px;
  white-space: normal;
  line-height: 0.8 !important; 
}
br {
  line-height: 0;
  margin: 0;
  
  }
.vtWillessM{
  color: white;
  font-size: 50px !important;
}
.simCARDD{
  /* font-weight: 900; */
  color: white;
  font-size: 80px;
  white-space: normal;
  line-height: 0.6 !important; 
}
.siComes{
  background-color: white;
  color: #0F107C;
  padding: 5px;
  font-size: 14.5px !important;
}

.bans-textMain {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  color:#0F107C;
  margin-left: 5% !important;
  /* font-size: 40px; */
  /* text-align: center; */
}

.banner-Cover {
  /* background-color: rgba(0, 0, 0, 0.578) !important; */
  margin-right: 50%;
  margin-top: 1% !important;
  margin-left: 2%;

}

.most-powerfulText {
  /* text-align: center; */
  /* font-family: "Anton", sans-serif; */
  font-size: 25px;
  font-weight: 700;
  color:white;
  margin-left: 5%;
}

.another_text {
  color:  rgb(5, 5, 144);
  /* text-align: center; */
  font-size: 17px;
}

.lets_start {
  /* text-align: center; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.danger_text {
  /* font-family: "Anton", sans-serif; */
  font-size: 22px;
  /* text-align: center; */
  font-style: italic;
  color:white;
  /* margin-top: 5%; */
  /* font-weight: lighter !important; */
}

.setCenter_line {
  border: black 2px solid;
  height: 2px;
  width: 80%;
  /* margin: 0 auto; */
  border-radius: 5px;
}

.banner .content .principal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 600px;
}

.banner .content .general-info {
  position: absolute;
  bottom: 0;
  background: var(--background-secondary-color);
  width: 100%;
  text-align: center;
  padding: 5px;
}

.banner .content .general-info p {
  max-width: 1400px;
  color: white !important;
  margin: 0 auto;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;

}


.banner .content .principal h1 {
  color: var(--text-primary-color);
  font-weight: bold;
  font-size: 40px;
  /* font-size: 2em; */
}

.banner .content .principal a {
  text-decoration: none;
}

.banner .content .principal .link-1 {
  color: var(--text-primary-color);
  padding: 5px 20px;
  font-size: 2em;
  width: max-content;
  background: transparent;
  border-radius: 24px 0px;
  position: relative;
  color: #d3eaf2;
  background: var(--background-primary-color);
  transition: border-width 0.05s ease-in-out;
}

.banner .content .principal .link-1:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 24px 0px;
  transform: scale(1.02, 1.08);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: radial-gradient(#10137E,
      white,
      #10137E,
      #10137E,
      transparent,
      transparent) no-repeat;
  background-size: 100% 75%;
  animation: electronic 5s infinite linear;
}

.banner .content .principal .link-2 {
  color: var(--text-primary-color);
  padding: 0.2em 1em;
  border-radius: 0.3em;
  background: var(--background-secondary-color);
  font-size: 1.3em;
  width: max-content;
}

.banner .content .principal a span,
.banner .content .principal p span {
  font-weight: bold;
}

.banner .content .principal p {
  color: var(--text-primary-color);
  font-size: 1.4em;
  font-weight: 600;
}

@keyframes electronic {

  0%,
  100% {
    background-position: 20px -30px;
  }

  25% {
    background-position: 150px 5px;
  }

  50% {
    background-position: 20px 40px;
  }

  75% {
    background-position: -100px 5px;
  }
}

@media(max-width: 1700px) {
  .banner .content {
    margin-top: 2%;
  }

  .setAllhwStep {

    height: 400px;
  }
  .banner-Cover{
    /* background-color: rgba(0, 0, 0, 0.578) !important; */
    margin-right: 40%;
   
  }
  .banner {
    background-image: url("../../images/FAM-LAP.png");
}
}

@media(max-width: 1400px) {
  .banner .content {
    margin-top: 2%;
  }

  .banner {
    background-image: url("../../images/FAM-SMALL-LAP.png");
    background-size: cover;
}
}

@media(max-width:993px) {
  .banner .content {
    flex-direction: column-reverse;
  }

  .banner .content .general-info {
    margin: 20px 0px 0px 0px;
    position: relative;
    width: 100%;
    background: transparent;
  }
  .banner-Cover{
    /* background-color: rgba(0, 0, 0, 0.578) !important; */
    margin-right: 2% ;
   
  }

  .banner {
    /* background: var(--background-primary-color); */
    /* background: linear-gradient(320deg, #1A5FC2 55%, #2196F3 20%, white 10% ); */
    background-image: url("../../images/FAM-IPAD.png");
    height: 50vh;


  }
  .setSalediv-card {
    display: grid !important;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: #05B0E3; */
    justify-content: space-around !important;
    
  
  
  }
 
  .setSalediv {

    width: 100%;
    padding: 10px;
  }
  
  .setSalediv-image-div {
    width: 100%;
  
  }
  
  .setSalediv-image {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 0%;
  
  }
  
  .setSalediv h2 {
    font-size: 20px !important;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
  }
  
  .setSalediv p {
    font-size: 14px !important;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
  }
  
  .setSalediv ul {
    font-size: 14px !important;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
  }
  

}

@media screen and (max-width: 700px) {
  .banner .content {
    flex-direction: column-reverse;
  }

  .banner {
    /* background: var(--background-primary-color); */
    /* background: linear-gradient(320deg, #1A5FC2 55%, 
    #2196F3 20%, white 10% ); */
    background-image: none;
    background-color: #FAFAFA;
    background-image: url("../../images/FAM-MOBILE.png");
    height: auto;
   }
  .banner img {
    display: block;
    width: 100%;
  }

  .banner .content .general-info {
    margin: 20px 0px 0px 0px;
    position: relative;
    width: 100%;
    background: transparent;
  }

  .banner .content .general-info p {
    font-size: 0.8rem;
  }

  .imagFirst {
    width: 300px;
  }

  .banner .content .principal {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 400px;
  }

  .banner .content .principal .link-1 {
    width: 100%;
   }
  .banner .content .principal h1 {
    color: var(--text-primary-color);
    font-weight: bold;
    font-size: 30px;
    /* font-size: 2em; */
  }

  .banner .content .principal {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  .banner .content .principal p {
    color: white;
    font-size: 1.4em;
    font-weight: 600;
  }

  .banner .content .general-info p {
    color: #2BBCF1;
  }

  .banner .content .general-info p {

    color: #d5e9f0 !important;

  }

  .sendUp {
    display: none;
  }

  .sendDown {
    display: block;
  }

  .contro-center {
    justify-content: center !important;
    text-align: center !important;

  }

  .hwStepBody img {
    display: none;
  }

  .mobDown {
    display: block !important;
    margin-left: -24% !important;
  }

  .hwStepBody3 img {
    display: none;
  }

  .hwStep p {
    display: none;
  }

  #modown-last {
    margin-left: -17% !important;
  }


  .bans-textMain {
    /* font-family: "Anton", sans-serif; */
    font-weight: 100;
    /* color: white; */
    text-align: center;
    font-size: 20px;
   
  }
  
 
  
  .most-powerfulText {
    text-align: center;
    /* font-family: "Anton", sans-serif; */
    font-size: 15px;
    /* color: #7fd5f4; */
  }
  
  .another_text {
    text-align: center;
    font-size: 12px;
  }
  
  .lets_start {
    text-align: center;
    font-family: "Anton", sans-serif;
    font-size: 13px;
  }
  
  .danger_text {
    /* font-family: "Anton", sans-serif; */
    font-size: 12px;
    text-align: center;
    font-style: italic;
    /* margin-top: 5%; */
    /* font-weight: lighter !important; */
  }
  
  .setCenter_line {
    /* border: white 2px solid; */
    height: 2px;
    width: 50px;
    margin: 0 auto;
    border-radius: 5px;
  }
  .line-heading {
    display: -webkit-box;
    display: grid;
    -webkit-box-align: center;
    align-items: center;
  }
  .line-heading-left h2 {
    color: #fff;
    font-size: 2.625rem;
    font-weight: bold;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
    line-height: 1.1;
    vertical-align: auto;
    margin: auto;
    padding: 0;
    margin-right: 15% !important;
  }
  .simCARDD{
    /* font-weight: 900; */
    color: white;
    font-size: 50px !important;
    white-space: normal;
    line-height: 0.6 !important; 
  }
  .siComes{
    background-color: white;
    color: #0F107C;
    padding: 5px;
    font-size: 12px !important;
  }
  .danger_text {
   
   
    background-color: rgba(0, 0, 0, 0.555);
    font-style: italic;
    color:white;
    padding: 10px;
   
  }
  
  
  
 
  
  
  
}